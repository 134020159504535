import { Button, IconButton } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deletegroup } from '../actions/groupsAction';
import DeleteIcon from '@mui/icons-material/Delete';


export default function PhoneBookCard(props) {
    const showEdit = props.showEdit;
    const { phonebook } = props;

    const color=["aquamarine", "aliceblue","beige", "antiquewhite", "azure", "bisque","bisque","blanchedalmond","burlywood"] 
    const randomSelector = () => {
        return Math.floor(Math.random()*color.length);   
    }

    const dispatch = useDispatch();

    const deleteHandler = (e) => {
        e.preventDefault();
        dispatch(deletegroup([phonebook.id]));
    }

    return (
       <div style={{background:`${color[randomSelector()]}`}}className="phone-book-card">
            <div className="book-icon-containers">
                {showEdit &&
                    <div className="delete-icon" title="Delete phone book">
                        <IconButton onClick={deleteHandler}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                }
            </div>
            <div className="phone-book-card__name_container">
                <div className="phone-book-card__name">
                    {phonebook.groupname.toUpperCase()}
                </div>
                <div className="phone-book-card__author">
                    Created by: <span>{phonebook.username}</span>
                </div>
                <div className="phone-book-card__setup-time">
                    Created at: <span>{new Date(phonebook.setuptime).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</span>
                </div>
                <div className="phone-book-card__setup-time">
                    Number of contacts: <span>{phonebook.contacts.length}</span>
                </div>
            </div>
            
            <div className="phone-book-card__open_book">
                <Link to={`${window.location.pathname}/${phonebook.id}`}><Button variant="contained">Open phone book</Button></Link>
            </div>
        </div>
    )
}
