import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { recurringrequest } from '../../actions/bulksmsAction';
import LoadingBox from '../../components/LoadingBox';
import RecurringSMS from '../../components/RecurringSMS';
//import 'animate.css/animate.min.css';
import SearchField from "react-search-field";
import InfiniteScroll from 'react-infinite-scroll-component';
import { CLEAN_RECURRING_SUCCESS, DELETE_SMS_ERROR, RECURRING_PAGENUM, RECURRING_SEARCH, RECURRING_VISITED } from '../../constants/bulksmsConstants';
import { v4 as uuidv4 } from 'uuid';

import Alerts from '../../components/mainapp/Alerts';


export default function LocalRecurringScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const { recurring, error, recurringsearch, loading } = useSelector(state => state.bulksms);
  const { pageNum, search, visited } = recurringsearch;
  //remove duplicates
  const key = 'message_id';
  const uniquerecurring = [...new Map(recurring.map(item => [item[key], item])).values()];

  uniquerecurring.sort((a, b) => b.message_id - a.message_id);

  const dispatch = useDispatch();
  const mountedRef = useRef(true)
  

  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
          });
              

      }, 5000)
      
    }
  }, [error]);


  useEffect(() => {
    if (!visited) {
      dispatch(recurringrequest(search, pageNum));
      dispatch({ type: RECURRING_VISITED, payload: true });
    }
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type !== performance.navigation.TYPE_RELOAD) {
        if (error) {
          if (uniquerecurring.length < 1) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You do not have any recurring sms", status: "info" }];
            });
             setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
                  

            }, 5000)
           
          }
        }
      }
      
    


    }

    return () => {
      //dispatch({type: CLEAN_RECURRING_SUCCESS});  
      dispatch({ type: DELETE_SMS_ERROR });
      mountedRef.current = false
    };

  }, []);

  const fetchData = () => {
    dispatch({ type: RECURRING_PAGENUM, payload: pageNum + 1 });
    let increaesdPageNum = pageNum + 1;
    dispatch(recurringrequest(search, increaesdPageNum));
  };


  const onChangeHandler = (val) => {
    dispatch({ type: CLEAN_RECURRING_SUCCESS });
    dispatch({ type: RECURRING_SEARCH, payload: val });
    dispatch({ type: RECURRING_PAGENUM, payload: 1 });
    dispatch(recurringrequest(search, pageNum));
  };

  let val = search ? search : "";
  return (
    <>
      {loading && <LoadingBox></LoadingBox>}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_SMS_ERROR }]} />

      <div className="search">
        <SearchField
          placeholder="Search..."
          onEnter={onChangeHandler}
          searchText={val}
          classNames="test-class"
        />
      </div>


      <div
        id="scrollableDiv"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',

        }}
      >

        {uniquerecurring.length > 0 ? (


          <InfiniteScroll
            dataLength={uniquerecurring.length}
            next={fetchData}
            loader={loading && <LoadingBox></LoadingBox>}
            hasMore={true}
            scrollableTarget="scrollableDiv"

            endMessage={
              <p style={{ textAlign: 'center' }} >
                <b>Data fetch completed</b>
              </p>
            }
          >
            {uniquerecurring.map((rs) => {

              return (
                <RecurringSMS key={rs.message_id} rs={rs} setErrorArray={setErrorArray} />
              )
            })}

          </InfiniteScroll>

        ) : (<></>)

        }
      </div>

    </>
  )
}
