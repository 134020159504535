import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingBox from './LoadingBox';
//import { Turnstile } from '@marsidev/react-turnstile';
//import MessageBox from './MessageBox';

import { Alert } from '@mui/material';



export default function SignupForm(props) {
    const { domainDetails } = useSelector(state => state.domainDetails);
    const [passwordMessage, setPasswordMessage] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [usernameMessage, setUsernameMessage] = useState("");
    const [phoneMessage, setPhoneMessage] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        //focus on username field
        inputRef.current.focus();
    }, []);

    const user = useSelector(state => state.user);
    const { error } = user;

    const {
        username,
        cname,
        phone,
        email,
        password,
        cpassword,
        referralcode,
        submitHandler,
        setUsername,
        setCname,
        setPhone,
        setEmail,
        setPassword,
        setCpassword,
        setReferralCode,
        loading,
        setLoading,
        removeErrorMessage,
        errorArray,
        setErrorArray,
        setHumanKey
    } = props;
    /*
        useEffect(() => {
            setTimeout(() => {
                setErrorArray(prev => {
                    let obj = errorArray[errorArray.length - 1]
                    return prev.filter(x => x.id !== obj.id);
                });
            }, 5000)
            
    
        }, [errorArray]);
    
    */
    useEffect(() => {

        if (error) {
            let id = uuidv4()
            error.id = id;
            setErrorArray((prev) => {
                return [...prev, error];
            });
            
            setTimeout(() => {
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });
            }, 5000)
            
        }

    }, [error]);

    const submitHandlerFucntion = (e) => {

        e.preventDefault();
        setLoading(true);
        return submitHandler();
    };

    const buttonSwitchFunction = () => {
        if (username.length > 0 && email.length > 0 && (password.length > 0 && cpassword.length > 0)) {
            if (usernameMessage.length > 1 || emailMessage.length > 1 || passwordMessage.length > 1 || phoneMessage.length > 1) {
                return true;
            } 
            return false;
        } else {
            return true;
        }
    };

    let isDisabled = buttonSwitchFunction();
    // console.log(buttonSwitchFunction());
    // check user name container only letters and numbers
    //add to back end
    const onMouseLeaveUsernameHandler = async () => {
        if (username.length > 0) {
            if(username.length < 5){
                setUsernameMessage("Username must be 5 or more characters");
                return; 
            }
            const { data } = await axios.post('/smsgateway/api/frontend/checkusernameexist', { username });

            if (data.error && data.error.status === "failed") {
                setUsernameMessage(data.error.message);
            } else {
                setUsernameMessage("");
            }
        }
    };
    // phone must contain numbers and must be 9 letter or more 
    //add to back end
    const onMouseLeavePasswordCheckHandler = () => {
        if (password.length > 0 && password.length < 6) {
            setPasswordMessage("Password must be more six characters");
        } else if (password.length > 0 && cpassword.length > 0) {
            if (password !== cpassword) {
                setPasswordMessage("Passwords do not match");
            } else {
                setPasswordMessage("");
            }

        } else {
            setPasswordMessage("");
        }
    };

    const onChangeRecaptch = (value) => {
        setHumanKey(value)
    }

    const onMouseLeaveMobileCheckHandler = () => {
        if (phone.length > 0) {
            const re = /^\+?\d+$/;
            if (!re.test(phone)) {
                setPhoneMessage("Mobile numbers must be only numbers");
            } else if (phone.length < 9) {
                setPhoneMessage("Mobile numbers not complete");
            } else {
                setPhoneMessage("");
            }
        }
    };



    const onMouseLeaveEmailHandler = async () => {
        if (email.length > 0) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(email).toLowerCase())) {
                setEmailMessage("Invalid Email");
            } else {
                const { data } = await axios.post('/smsgateway/api/frontend/checkemailexist', { email });
                if (data.error && data.error.status === "failed") {
                    setEmailMessage(data.error.message);
                } else {
                    setEmailMessage("");
                }
            }
        }
    };
    return (
        <>
            <div className="signin">
                <div className="message-form">
                    {loading && <LoadingBox></LoadingBox>}
                    {errorArray.length ? errorArray.map((error, index) =>
                        <Alert key={uuidv4()} onClose={() => { removeErrorMessage(error, index) }} variant="filled" severity={error.status === "failed" ? "warning" : error.status === "successful" ? "success" : error.status === "error" ? "error" : "info"}>{error.message}</Alert>
                    ) : (<></>)}
                    {phoneMessage && <Alert
                        variant="filled"
                        severity="warning">
                        {phoneMessage}
                    </Alert>
                    }
                    {passwordMessage && <Alert
                        variant="filled"
                        severity="warning">
                        {passwordMessage}
                    </Alert>
                    }
                    {emailMessage && <Alert variant="filled" severity="warning">{emailMessage}</Alert>}
                    {usernameMessage && <Alert variant="filled" severity="warning">{usernameMessage}</Alert>}
                    {/*
                    {usernameMessage && <MessageBox variant="error"> {usernameMessage}</MessageBox>}
                    */
                    }
                </div>
                <div className="signin_portal">
                    <img src={domainDetails.logo} alt="logo" ></img>
                    <p>{`${domainDetails.companyname} SMS platform`}</p>
                </div>
                <form onSubmit={submitHandlerFucntion}>
                    <h2>Sign up</h2>
                    <input className="form-control" ref={inputRef} autoComplete="off" type="text" id="username" value={username} placeholder="Enter username" required onMouseLeave={onMouseLeaveUsernameHandler} onChange={e => { setUsername(e.target.value); setUsernameMessage("") }} />

                    <input type="text" id="cname" className="form-control" autoComplete="off" value={cname} placeholder="Enter Company Name" required onChange={e => setCname(e.target.value)} />

                    <input className="form-control" autoComplete="off" type="email" id="email" value={email} placeholder="Enter Email Address" required onMouseLeave={onMouseLeaveEmailHandler} onChange={e => { setEmail(e.target.value); setEmailMessage("") }} />

                    <input className="form-control" autoComplete="off" type="text" id="phone" value={phone} placeholder="Enter Mobile Number" required onMouseLeave={onMouseLeaveMobileCheckHandler} onChange={e => { setPhone(e.target.value); setPhoneMessage("") }} />

                    <input className="form-control" autoComplete="off" type="password" id="password" value={password} placeholder="Enter password" required onChange={e => { setPassword(e.target.value); setPasswordMessage("") }} />

                    <input className="form-control" autoComplete="off" type="password" id="cpassword" value={cpassword} placeholder="Confirm password" required onMouseLeave={onMouseLeavePasswordCheckHandler} onChange={e => { setCpassword(e.target.value); setPasswordMessage("") }} />
                    {domainDetails.enablereferral === 1 &&
                    <input className="form-control" autoComplete="off" type="text" id="referralcode" value={referralcode} placeholder="Enter referral code (optional)" onChange={e => setReferralCode(e.target.value)} />
                    }
                    <div className="form-group">
                        {/*
                        <ReCAPTCHA sitekey={domainDetails.recaptcha_site_key} onChange={onChangeRecaptch} />
                        <Turnstile siteKey={domainDetails.recaptcha_site_key} onSuccess={setHumanKey} />
                        */}
                    </div>

                    <button disabled={isDisabled ? isDisabled : loading} type="submit">Register</button>

                    <div className="signin_link">
                        Already have an account? {' '} <Link to={'/login'}>Login</Link>
                    </div>

                </form>
            </div>
        </>
    )
}
