import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updatecontact } from '../actions/groupsAction';
import { v4 as uuidv4 } from 'uuid';
import { Edit } from '@mui/icons-material';
import { Button, Modal} from '@mui/material';

import Alerts from '../components/mainapp/Alerts';
import { DELETE_GROUP_ERROR } from '../constants/groupsConstants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export const EditModal = ({ groupid, contact }) => {

  const [firstname, setFirstname] = React.useState(contact.firstname ? contact.firstname : "");
  const [middlename, setMiddlename] = React.useState(contact.middlename ? contact.middlename : "");
  const [surname, setSurname] = React.useState(contact.surname ? contact.surname : "");
  const [email, setEmail] = React.useState(contact.email ? contact.email : "");
  const [phonenumber, setPhonenumber] = React.useState(contact.phonenumber ? contact.phonenumber : "");
  const [birthday, setBirthday] = React.useState(contact.birthday ? new Date(contact.birthday) : "");
 
  const [errorArray, setErrorArray] = React.useState("");


  const [open, setOpen] = React.useState(false);
  const mountedRef = useRef(true)

  const id = contact.id;
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  
   

  const dispatch = useDispatch();

  useEffect(()=>{
    return () => { 
      mountedRef.current = false
    }
  }, [])

  const handleSubmit = () => {
    if (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        dispatch(updatecontact(groupid, id, firstname, surname, phonenumber, birthday, middlename, email));
      } else {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: "Please enter a valid email address", status: "warning" }];
        });

        setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });
        }, 5000)
      
        return;
      }
    } else {
  
      dispatch(updatecontact(groupid, id, firstname, surname, phonenumber, birthday, middlename, email));
    }

    handleClose();

  };

  return (
    < >
      <Edit onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />

          <form >
            <h2> Edit contact</h2>
            <input type="text" autoComplete="off" value={firstname} placeholder="Enter first name (leave empty of no data)" onChange={e => setFirstname(e.target.value)} />
            <input type="text" autoComplete="off" value={middlename} placeholder="Enter middle name (leave empty of no data)" onChange={e => setMiddlename(e.target.value)} />
            <input type="text" autoComplete="off" value={surname} placeholder="Enter surname (leave empty of no data)" onChange={e => setSurname(e.target.value)} />
            <input type="text" autoComplete="off" value={email} placeholder="Enter email address (leave empty of no data)" onChange={e => setEmail(e.target.value)} />
            <input type="text" autoComplete="off" value={phonenumber} placeholder="Enter mobile number (leave empty of no data)" onChange={e => setPhonenumber(e.target.value)} />
            <div style={{ color: '#003366', marginTop: '5px' }}>Enter birth date (leave empty of no data) </div>
            <div className="datetimepicker">
               <DatePicker showIcon selected={birthday} onChange={(val) => setBirthday(val)} />
            </div>
            <Button variant="contained" onClick={handleSubmit}>Save Changes</Button>
          </form>
        </div>


      </Modal>
    </>
  );
}