//fetch ALL qucik sms - data tables
export const FETCH_ALL_QUICK_REQUEST = 'FETCH_ALL_QUICK_REQUEST';
export const FETCH_ALL_QUICK_SUCCESS = 'FETCH_ALL_QUICK_SUCCESS';
export const FETCH_ALL_QUICK_FAIL = 'FETCH_ALL_QUICK_FAIL';


//fetch quicksms - Lazy loads
export const FETCH_QUICK_REQUEST = 'FETCH_QUICK_REQUEST';
export const FETCH_QUICK_SUCCESS = 'FETCH_QUICK_SUCCESS';
export const FETCH_QUICK_FAIL = 'FETCH_QUICK_FAIL';

//create/send quicksms 
export const CREATE_QUICK_REQUEST = 'CREATE_QUICK_REQUEST';
export const CREATE_QUICK_SUCCESS = 'CREATE_QUICK_SUCCESS';
export const CREATE_QUICK_FAIL = 'CREATE_QUICK_FAIL';


//update message status
export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS';
export const UPDATE_MESSAGE_STATUS_ALL_BULKSMS = 'UPDATE_MESSAGE_STATUS_ALL_BULKSMS';

//update quicksms 
export const UPDATE_QUICK_REQUEST = 'UPDATE_QUICK_REQUEST';
export const UPDATE_QUICK_SUCCESS = 'UPDATE_QUICK_SUCCESS';
export const UPDATE_QUICK_FAIL = 'UPDATE_QUICK_FAIL';

//fetch  scheduled sms - lazy loads
export const FETCH_SCHEDULED_REQUEST = 'FETCH_SCHEDULED_REQUEST';
export const FETCH_SCHEDULED_SUCCESS = 'FETCH_SCHEDULED_SUCCESS';
export const FETCH_SCHEDULED_FAIL = 'FETCH_SCHEDULED_FAIL';

//fetch  ALL scheduled sms - data tables
export const FETCH_ALL_SCHEDULED_REQUEST = 'FETCH_ALL_SCHEDULED_REQUEST';
export const FETCH_ALL_SCHEDULED_SUCCESS = 'FETCH_ALL_SCHEDULED_SUCCESS';
export const FETCH_ALL_SCHEDULDED_FAIL = 'FETCH_ALL_SCHEDULED_FAIL';

//create scheduled sms - lazy loads
export const CREATE_SCHEDULED_REQUEST = 'CREATE_SCHEDULED_REQUEST';
export const CREATE_SCHEDULED_SUCCESS = 'CREATE_SCHEDULED_SUCCESS';
export const CREATE_SCHEDULED_FAIL = 'CREATE_SCHEDULED_FAIL';

//update scheduled sms - lazy loads
export const UPDATE_SCHEDULED_REQUEST = 'UPDATE_SCHEDULED_REQUEST';
export const UPDATE_SCHEDULED_SUCCESS = 'UPDATE_SCHEDULED_SUCCESS';
export const UPDATE_SCHEDULED_FAIL = 'UPDATE_SCHEDULED_FAIL';

//delete  scheduled sms - lazy loads
export const DELETE_SCHEDULED_REQUEST = 'DELETE_SCHEDULED_REQUEST';
export const DELETE_SCHEDULED_SUCCESS = 'DELETE_SCHEDULED_SUCCESS';
export const DELETE_SCHEDULED_FAIL = 'DELETE_SCHEDULED_FAIL';

//fetch  ALL recurring sms - data tables
export const FETCH_ALL_RECURRING_REQUEST = 'FETCH_ALL_RECURRING_REQUEST';
export const FETCH_ALL_RECURRING_SUCCESS = 'FETCH_ALL_RECURRING_SUCCESS';
export const FETCH_ALL_RECURRING_FAIL = 'FETCH_ALL_RECURRING_FAIL';


//fetch  recurring sms - lazy loads
export const FETCH_RECURRING_REQUEST = 'FETCH_RECURRING_REQUEST';
export const FETCH_RECURRING_SUCCESS = 'FETCH_RECURRING_SUCCESS';
export const FETCH_RECURRING_FAIL = 'FETCH_RECURRING_FAIL';

//create  recurring sms - lazy loads
export const CREATE_RECURRING_REQUEST = 'CREATE_RECURRING_REQUEST';
export const CREATE_RECURRING_SUCCESS = 'CREATE_RECURRING_SUCCESS';
export const CREATE_RECURRING_FAIL = 'CREATE_RECURRING_FAIL';


//update  recurring sms - lazy loads
export const UPDATE_RECURRING_REQUEST = 'UPDATE_RECURRING_REQUEST';
export const UPDATE_RECURRING_SUCCESS = 'UPDATE_RECURRING_SUCCESS';
export const UPDATE_RECURRING_FAIL = 'UPDATE_RECURRING_FAIL';

// cLean quick sms
export const CLEAN_ALL_QUICK_SUCCESS = 'CLEAN_ALL_QUICK_SUCCESS';
export const CLEAN_QUICK_SUCCESS = 'CLEAN_QUICK_SUCCESS';

export const CLEAN_ALL_SCHEDULED_SUCCESS = 'CLEAN_ALL_SCHEDULED_SUCCESS';
export const CLEAN_SCHEDULED_SUCCESS = 'CLEAN_SCHEDULED_SUCCES';
export const CLEAN_ALL_RECURRING_SUCCESS = 'CLEAN_ALL_RECURRING_SUCCESS';
export const CLEAN_RECURRING_SUCCESS = 'CLEAN_RECURRING_SUCCESS';


//delete  recurring sms - lazy loads
export const DELETE_RECURRING_REQUEST = 'DELETE_RECURRING_REQUEST';
export const DELETE_RECURRING_SUCCESS = 'DELETE_RECURRING_SUCCESS';
export const DELETE_RECURRING_FAIL = 'DELETE_RECURRING_FAIL';

export const DELETE_SMS_ERROR = 'DELETE_SMS_ERROR';

//increase pagenum and update search

export const QUICK_PAGENUM = 'QUICK_PAGENUM';
export const QUICK_SEARCH = 'QUICK_SEARCH';
export const QUICK_VISITED = 'QUICK_VISITED';

export const ALL_QUICK_PAGENUM = 'ALL_QUICK_PAGENUM';
export const ALL_QUICK_SEARCH = 'ALL_QUICK_SEARCH';
export const ALL_QUICK_VISITED = 'ALL_QUICK_VISITED';

export const SCHEDULED_PAGENUM = 'SCHEDULED_PAGENUM';
export const SCHEDULED_SEARCH = 'SCHEDULED_SEARCH';
export const SCHEDULED_VISITED = 'SCHEDULED_VISITED';

export const ALL_SCHEDULED_PAGENUM = 'ALL_SCHEDULED_PAGENUM';
export const ALL_SCHEDULED_SEARCH = 'ALL_SCHEDULED_SEARCH';
export const ALL_SCHEDULED_VISITED= 'ALL_SCHEDULED_VISITED';

export const RECURRING_PAGENUM = 'RECURRING_PAGENUM';
export const RECURRING_SEARCH = 'RECURRING_SEARCH';
export const RECURRING_VISITED= 'RECURRING_VISITED';

export const ALL_RECURRING_PAGENUM = 'ALL_RECURRING_PAGENUM';
export const ALL_RECURRING_SEARCH = 'ALL_RECURRING_SEARCH';
export const ALL_RECURRING_VISITED= 'ALL_RECURRING_VISITED';
