import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import SignupForm from './SignupForm';
import NumberValidationForm from './/NumberValidationForm';
import { signup } from '../actions/userAction';
import { DELETE_USER_ERROR } from '../constants/userConstants';
import { v4 as uuidv4 } from 'uuid';




export default function ToggleVandSForms() {
    const [toggleFromSignUpToValidation, setToggleFromSignUpToValidation] = useState(true);
    const [attempts, setAttempts] = useState(7);
    const [username, setUsername] = useState("");
    const [cname, setCname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const [referralcode, setReferralCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [temporalid, setTemporalid] = useState("");
    const [codeId, setCodeId] = useState("");
    const [country, setCountry] = useState("Ghana");
    const [errorArray, setErrorArray] = useState([]);
    const [smsAttempts, setSmsAttempts] = useState(4);
    const [humanKey, setHumanKey] = useState("");

    const mountedRef = useRef(true)

    const dispatch = useDispatch();
    const { domainDetails } = useSelector(state => state.domainDetails);

    const getUserLocation = async () => {
        return "Ghana"
        try {
            const response= await fetch("https://cloudflare.com/cdn-cgi/trace");
            const data = await response.text();
            const locObj = {}
            data.split("\n").forEach(line => {
            if (line.includes("=")) {
                    const key = line.split("=")[0];
                    const value = line.split("=")[1];
                    locObj[key] = value;
                    console.log("loc",locObj)

                }
            });
            if (locObj["loc"] === "GH")
                return "Ghana";
            return locObj["loc"] 
        } catch (err) {
            return "Ghana";
        }
    };

    const submitToSignUp = (temporalid) => {
        setErrorArray([]);        
        setUsername("");
        setCname("");
        setPhone("");
        setEmail("");
        setPassword("");
        setReferralCode("");
        setCpassword("");
        setHumanKey("")
        dispatch(signup(temporalid));
    };

    useEffect(() => {
        if (attempts === 0) {
            setUsername("");
            setCname("");
            setPhone("");
            setEmail("");
            setPassword("");
            setReferralCode("");
            setCpassword("");
            setToggleFromSignUpToValidation(true);
            setLoading(false)
        }
        if (smsAttempts === 0) {
            setUsername("");
            setCname("");
            setPhone("");
            setEmail("");
            setPassword("");
            setReferralCode("");
            setCpassword("");
            setToggleFromSignUpToValidation(true);
            setLoading(false)
        }

    }, [attempts, smsAttempts])
    /*
        useEffect(() => {
            setTimeout(() => {
                setErrorArray(prev => {
                    let obj = errorArray[errorArray.length - 1]
                    return prev.filter(x => x.id !== obj.id);
                });
            }, 20000)
            
    
        }, [errorArray]);
    */

    const sendSMSFunction = async () => {
        // generate code, set code 
        setLoading(true);
        setSmsAttempts(smsAttempts - 1);

        try {
            const { data } = await axios.post('/smsgateway/api/frontend/sendsmscode', { phone, subscriber: username, supid: domainDetails.profile_id });
          
            if (data.status === "failed") {
                setLoading(false);
                let id = uuidv4()
                data.id = id;
                setErrorArray((prev) => {
                    return [...prev, data];
                });
                
                setTimeout(() => {
                      if (!mountedRef?.current) return null
                    setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });
                     
                }, 5000)
                
            } else {
                setLoading(false);
                let id = uuidv4()
                setErrorArray((prev) => {
                    return [...prev, { id: id, message: "Please enter code sent to your phone", status: "successful" }];
                });
                
                setTimeout(() => {
                      if (!mountedRef?.current) return null
                    setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });
                     
                }, 5000)
                
                setCodeId(data.codeId);

            }
        } catch (error) {
            let id = uuidv4()
            error.id = id;
            setErrorArray((prev) => {
                return [...prev, error];
            });
            
            setTimeout(() => {
                  if (!mountedRef?.current) return null
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });
                 
            }, 5000)
            
        }
    };


    const submitHandler = async () => {
        let country1 = await getUserLocation();

        setCountry(country1);
        let domain = window.location.hostname;
        //send temporal sign up request        
        try {
            if(username < 5){
                let id = uuidv4()
                data.error.id = id;
               
                setErrorArray((prev) => {
                    return [...prev, { id: id, message: "Username must be 5 or more characters", status: "failed" } ];
                });
                
                setTimeout(() => {
                      if (!mountedRef?.current) return null
                    setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });
                    
                }, 5000)
                return; 
            }
            const { data } = await axios.post('/smsgateway/api/frontend/temporalsignup', { username, cname, email, phone, country: country1, password, cpassword, referralcode, humanKey, supid: domainDetails.profile_id, domain });
            if (data.error && data.error.status === "failed") {
                // <Alert variant="filled" severity="error" onClose={() => {}}>{data.error.message}</Alert>
                let id = uuidv4()
                data.error.id = id;
                setErrorArray((prev) => {
                    return [...prev, data.error];
                });
                
                setTimeout(() => {
                      if (!mountedRef?.current) return null
                    setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });
                    
                }, 5000)
                
                setLoading(false);
            } else {

                setTemporalid(data.error.id);
                if (country1 !== "Ghana") {
                    submitToSignUp(data.error.id)
                    return;
                }

                if (data.error.signupdUser) {
                    submitToSignUp(data.error.id)
                    return;
                }


                // toggel form from Signup to Validate Form
                if (toggleFromSignUpToValidation === true) {
                    setErrorArray([]);
                    setToggleFromSignUpToValidation(false);
                }

                //send sms to validte number
                sendSMSFunction();
            }
        } catch (error) {
            let id = uuidv4()
            error.id = id;
            setErrorArray((prev) => {
                return [...prev, error];
            });
            
            setTimeout(() => {
                  if (!mountedRef?.current) return null
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });
                
            }, 5000)
            
        }

    };

    const removeErrorMessage = (el, index) => {
        setErrorArray(prev => {
            return prev.filter((e, i) => i !== index);
        });
        dispatch({ type: DELETE_USER_ERROR });
    };

    useEffect(() => {
        return () => { 
      mountedRef.current = false
    }
    }, []);

    return (
        <>
            {toggleFromSignUpToValidation ? (
                <SignupForm
                    username={username}
                    cname={cname}
                    phone={phone}
                    email={email}
                    password={password}
                    cpassword={cpassword}
                    referralcode={referralcode}
                    submitHandler={submitHandler}
                    setUsername={setUsername}
                    setCname={setCname}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setCpassword={setCpassword}
                    setReferralCode={setReferralCode}
                    loading={loading}
                    setLoading={setLoading}
                    errorArray={errorArray}
                    setErrorArray={setErrorArray}
                    removeErrorMessage={removeErrorMessage}
                    setHumanKey={setHumanKey}
                    humanKey={humanKey}
                />

            ) : (
                <NumberValidationForm
                    submitToSignUp={submitToSignUp}
                    setAttempts={setAttempts}
                    attempts={attempts}
                    phone={phone}
                    sendSMSFunction={sendSMSFunction}
                    username={username}
                    cname={cname}
                    email={email}
                    password={password}
                    referralcode={referralcode}
                    setToggleFromSignUpToValidation={setToggleFromSignUpToValidation}
                    temporalid={temporalid}
                    codeId={codeId}
                    setUsername={setUsername}
                    setCname={setCname}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setCpassword={setCpassword}
                    setReferralCode={setReferralCode}
                    loading={loading}
                    setLoading={setLoading}
                    errorArray={errorArray}
                    setErrorArray={setErrorArray}
                    setHumanKey={setHumanKey}
                    country={country}
                    removeErrorMessage={removeErrorMessage}
                />
            )}
        </>
    )
}