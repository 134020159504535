import {UPDATE_GROUP_REQUEST,DELETE_GROUP_REQUEST,DELETE_GROUP_ERROR,CLEAN_ALL_GROUP_SUCCESS,CREATE_CONTACT_REQUEST,UPDATE_CONTACT_REQUEST,DELETE_CONTACT_REQUEST, FETCH_GROUPS_REQUEST, CREATE_GROUP_REQUEST, FETCH_GROUPS_SUCCESS, FETCH_GROUPS_FAIL, FETCH_ALL_GROUPS_REQUEST ,FETCH_ALL_GROUPS_SUCCESS , FETCH_ALL_GROUPS_FAIL, CREATE_CONTACT_SUCCESS, UPDATE_CONTACT_SUCCESS, DELETE_CONTACT_SUCCESS, CREATE_GROUP_SUCCESS, UPDATE_GROUP_SUCCESS, DELETE_GROUP_SUCCESS, CREATE_CONTACT_FAIL, UPDATE_CONTACT_FAIL, DELETE_CONTACT_FAIL, CREATE_GROUP_FAIL, UPDATE_GROUP_FAIL, DELETE_GROUP_FAIL } from "../constants/groupsConstants";
import { USER_SIGNOUT } from "../constants/userConstants";

export const groupsReducer = (state = {}, action) => {
    switch (action.type) {
             
        case FETCH_GROUPS_REQUEST:
        case CREATE_GROUP_REQUEST:
        case UPDATE_GROUP_REQUEST:
        case DELETE_GROUP_REQUEST:
        case CREATE_CONTACT_REQUEST:
        case UPDATE_CONTACT_REQUEST:
        case DELETE_CONTACT_REQUEST:
        case FETCH_ALL_GROUPS_REQUEST:
            return {...state, loading: true };
        case FETCH_GROUPS_SUCCESS:
        case FETCH_ALL_GROUPS_SUCCESS:
             return { ...state, loading: false, error:  action.payload.error,  groups: action.payload.groups ? action.payload.groups:[],  allgroups: action.payload.allgroups? action.payload.allgroups:[]};
     
            //return { ...state, loading: false, error:  action.payload.error,  groups: action.payload.group ? [...state.groups,action.payload.group] : [...state.groups]};
        case CREATE_GROUP_SUCCESS:
        case CREATE_CONTACT_SUCCESS:
        case UPDATE_GROUP_SUCCESS:
        case UPDATE_CONTACT_SUCCESS:
            return { ...state, loading: false, error: action.payload.error, groups: action.payload.group? [...state.groups.filter(x => x.id !== action.payload.group.id), action.payload.group]:[...state.groups] };
        case DELETE_CONTACT_SUCCESS:
          
   
            const group_c = state.groups.filter(x => x.id === action.payload.groupid)
         
             return { ...state, loading: false, error: action.payload.error, groups: [...state.groups.filter(x => x.id !== action.payload.groupid), {...group_c[0], contacts: group_c[0].contacts.filter(x=> !action.payload.arrayofContacts.includes(x.id))}]};
        case DELETE_GROUP_SUCCESS:
            return { ...state, loading: false, error: action.payload.error, groups: state.groups.filter(x => !action.payload.includes(x.id))};
        case FETCH_GROUPS_FAIL:
        case FETCH_ALL_GROUPS_FAIL:
        case CREATE_CONTACT_FAIL:
        case UPDATE_CONTACT_FAIL:
        case DELETE_CONTACT_FAIL:
        case CREATE_GROUP_FAIL:
        case UPDATE_GROUP_FAIL:
        case DELETE_GROUP_FAIL:
            return {...state, loading: false, error: action.payload  };
        case DELETE_GROUP_ERROR:
            return { ...state, loading: false, error: null };
        case CLEAN_ALL_GROUP_SUCCESS:
            return { ...state, allgroups: [] };
          case USER_SIGNOUT:
            return {     groups: [], allgroups:[]};
        
        default:
            return state;
    }   

};

