import { USER_FETCH_FAIL, USER_FETCH_REQUEST, DELETE_USER_ERROR,USER_FETCH_SUCCESS, USER_SIGNUP_FAIL, USER_SIGNUP_REQUEST, USER_SIGNUP_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_SIGNOUT,  USER_CREDIT_UPDATE_REQUEST, USER_CREDIT_UPDATE_SUCCESS, USER_CREDIT_UPDATE_FAIL,   USER_REQUESTING_SENDERID_SUCCESS, USER_FETCH_ALL_SUCCESS, USER_REQUESTING_SENDERID_FAIL, USER_FETCH_ALL_FAIL, USER_REQUESTING_SENDERID_REQUEST,  USER_FETCH_ALL_REQUEST, USER_REJECTING_SENDERID_FAIL, USER_APPROVING_SENDERID_FAIL, USER_APPROVING_SENDERID_SUCCESS, USER_REJECTING_SENDERID_SUCCESS, USER_REJECTING_SENDERID_REQUEST, USER_APPROVING_SENDERID_REQUEST, USER_ACCOUNTTYPE_SUCCESS, USER_ACCOUNTTYPE_REQUEST, USER_ACCOUNTTYPE_FAIL, CLEAN_ALLUSER_SUCCESS, ALLUSER_CREDIT_UPDATE_REQUEST, ALLUSER_CREDIT_UPDATE_FAIL, ALLUSER_CREDIT_UPDATE_SUCCESS, ALL_STATUS, RESELLER_PRICE_REQUEST } from "../constants/userConstants";

export const userReducer = (state = { }, action) => {
    
    switch (action.type) {
        case USER_LOGIN_REQUEST:
        case USER_SIGNUP_REQUEST:
        case USER_FETCH_REQUEST:
        case USER_CREDIT_UPDATE_REQUEST:
        case USER_REQUESTING_SENDERID_REQUEST:
        case USER_FETCH_ALL_REQUEST:
        case USER_REJECTING_SENDERID_REQUEST:
        case USER_APPROVING_SENDERID_REQUEST: 
        case ALLUSER_CREDIT_UPDATE_REQUEST: 
        case USER_ACCOUNTTYPE_REQUEST:        
            return { ...state, loading: true };
        case USER_LOGIN_SUCCESS:
        case USER_SIGNUP_SUCCESS:
    
            return { ...state, loading: false, error: action.payload.error ? action.payload.error : null, userInfo: action.payload.userInfo ? action.payload.userInfo:null, allusers: []  };
        case USER_FETCH_ALL_SUCCESS:
        case USER_CREDIT_UPDATE_SUCCESS:
        case USER_REQUESTING_SENDERID_SUCCESS:
        case USER_FETCH_SUCCESS:
        case USER_ACCOUNTTYPE_SUCCESS:
        case ALLUSER_CREDIT_UPDATE_SUCCESS:
            return { ...state, loading: false, error: action.payload.error ? action.payload.error : null, userInfo: action.payload.userInfo ? { ...state.userInfo,...action.payload.userInfo  }:{...state.userInfo}, allusers: action.payload.allusers ? action.payload.allusers:[...state.allusers]  };
        case USER_LOGIN_FAIL:
        case USER_SIGNUP_FAIL:
        case USER_FETCH_FAIL:
        case USER_CREDIT_UPDATE_FAIL:
        case USER_REJECTING_SENDERID_FAIL:
        case USER_REQUESTING_SENDERID_FAIL:
        case USER_APPROVING_SENDERID_FAIL:
        case ALLUSER_CREDIT_UPDATE_FAIL:
        case USER_FETCH_ALL_FAIL:
        case USER_ACCOUNTTYPE_FAIL:
           
            return { ...state, loading: false, error: action.payload };
            // updating allusers
        case USER_APPROVING_SENDERID_SUCCESS: 
        case USER_REJECTING_SENDERID_SUCCESS:
            return { ...state, loading: false, error: action.payload.error ? action.payload.error : null, userInfo: action.payload.userInfo ? { ...state.userInfo, ...action.payload.userInfo } : { ...state.userInfo } ,allusers: [...state.allusers.filter(x => x.profile_id !== action.payload.alluser.profile_id), action.payload.alluser].sort((a, b) => b.profile_id - a.profile_id) };
        case DELETE_USER_ERROR:
            return { ...state, loading: false, error: null };
        case USER_SIGNOUT:
            return {  userInfo: null, allusers:[] };
        case CLEAN_ALLUSER_SUCCESS:
                return { ...state, allusers: [], allStatus:[] };
        case ALL_STATUS:
              return { ...state, allStatus: action.payload.allStatus ? [...state.allStatus, ...action.payload.allStatus]:[...state.allStatus], clientPage: action.payload.clientPage ? action.payload.clientPage: state.clientPage }; 
        case RESELLER_PRICE_REQUEST:
            return { ...state, userInfo: action.payload.updatePriceData ? { ...state.userInfo, localrate : action.payload.updatePriceData } : { ...state.userInfo }};
        default:
            return state;
    }   

};







