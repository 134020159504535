import "./App.css";

import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import AppScreen from "./screens/app/AppScreen";
import LoginScreen from "./screens/login/LoginScreen";
import SignUpScreen from "./screens/signup/SignUpScreen";

import PrivateRoute from "./components/PrivateRoute";
import { fetchDomainDetails } from "./actions/domainAction";
import LoadingBox from "./components/LoadingBox";

function App() {
  const dispatch = useDispatch();
  const domain = window.location.hostname;
  const { domainDetails } = useSelector(state => state.domainDetails);

  useEffect(() => {
    dispatch(fetchDomainDetails(domain));
  }, []);
  return (
    <BrowserRouter>
      {Object.keys(domainDetails).length  ? (
      <div className="App">
        <Route path="/signup" component={SignUpScreen} exact></Route>
        <Route path="/login" component={LoginScreen} exact></Route>
        <PrivateRoute path="/" component={AppScreen} ></PrivateRoute>
        
      </div>
       ) : <LoadingBox></LoadingBox>}
    </BrowserRouter>
  );
}

export default App;
