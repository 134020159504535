import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signout } from '../../actions/userAction';

import Button from '@mui/material/Button';
import { Phone, WhatsApp } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';



export default function Header({ windowDimensions, handleChange }) {

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const user = useSelector(state => state.user);
  const { userInfo, allusers } = user;
  const [showForeignPrice, setShowForeignPrice]= useState(false)

  const dispatch = useDispatch();
  const { domainDetails } = useSelector(state => state.domainDetails);


  const logOutHandler = (e) => {
    e.preventDefault();
    dispatch(signout());
  };

  const listOfUserWithPendingSenderID = [];
  for (let i = 0; i < allusers.length; i++) {
    if (allusers[i].pendingsenderid === null) {
      continue
    }

    if (allusers[i].pendingsenderid.trim() !== "") {
      let x = allusers[i].pendingsenderid.split(",");
      for (let j = 0; j < x.length; j++) {
        if (x[j].trim() !== "") {
          listOfUserWithPendingSenderID.push(allusers[i].username)
        }
      }
    }
  }

  let style;
  listOfUserWithPendingSenderID.length > 0 ? style = { 'background': 'red' } : style = { 'background': 'green' }



  return (
    <>
      <header>
        <div className="companyfield">
          {domainDetails.profile_id === 6 ?
          (<>
           <div className='companytitle'>   
           <img src="/smsgateway/api/frontend/images/logo-6.jpeg" alt=" icsmslogo" loading="lazy" title="ic logo"/>
      
          <div>ICSMS bulksms platform</div> 
          </div>
          <span>powered by Integrity Comfort Ltd</span>
          </>
          ):(
          <div>{`${domainDetails.companyname} sms platform`}</div>
          )}
        </div>
        
        <div className="toggle_bar" onClick={handleChange}>
          <img src="/assets/images/toggle.png" alt="burger icon" />
        </div>
        
        {windowDimensions.width >= 1000 ?
          (<div className="contact">
            <Phone color="action" sx={{ fontSize: 10 }} />
            <WhatsApp color="success" sx={{ fontSize: 10 }} />

            <span className="text">{' '} {domainDetails.telnumber}</span>
          </div>) : (
            userInfo.content === "local" ? 
            (showForeignPrice ? (
              <div className="credit-container">
                <div className="creditbalance" style={{ 'display': 'flex', 'flexDirection': 'column', 'paddingLeft': '10px', 'paddingRight': '10px' }}>
                  <span className="text">SMS Credit balance</span>
                  <span className="text"> {userInfo.internationalcreditbalance} USD</span>
                </div>
                <img title="Show local creditbalance" src="/assets/images/localicon.jpg" alt="local icon" onClick={() => setShowForeignPrice(false)}/>
              </div>
            ) : (
              <div className="credit-container">
                <div className="creditbalance" style={{ 'display': 'flex', 'flexDirection': 'column', 'paddingLeft': '10px', 'paddingRight': '10px' }}>
                  <span className="text">SMS Credit balance</span>
                  <span className="text"> {userInfo.creditbalance}</span>
                </div>
                {(domainDetails.supInternationalBalance && false) &&
                <img title="Show international creditbalance"  src="/assets/images/internationalicon.jpg" alt="international icon" onClick={() => setShowForeignPrice(true)}/>
                }
                </div>
            )
          ) : (<div className="creditbalance">

              <span className="text"> SMS Credit balance</span>
              <span className="text"> {userInfo.internationalcreditbalance} USD</span>
            </div>))}
       
        

        {windowDimensions.width >= 1000 && <>
          {userInfo.isAdmin ? listOfUserWithPendingSenderID.length > 0 ? (
            <div className="senderid" style={style} title={`${listOfUserWithPendingSenderID.length} senderid(s) pending for approval or rejection`}>
            </div>
          ) : (<div className="senderid" style={style} title="No senderid(s) pending for approval or rejection">
          </div>) : (<></>)}
          {userInfo.content === "local" ? 
           (showForeignPrice ? (
               <div className="credit-container">
                <div className="creditbalance">
                  <span className="text"> SMS Credit balance: {userInfo.internationalcreditbalance} USD</span>
                </div>
                <img title="Show local creditbalance" src="/assets/images/localicon.jpg" alt="local icon" onClick={() => setShowForeignPrice(false)}/>
              </div>
              ):(
                <div className="credit-container">
                  <div className="creditbalance">
                    <span className= "text">SMS Credit balance: {userInfo.creditbalance}</span>
                  </div>
                  {(domainDetails.supInternationalBalance && false) &&
                  <img title="Show international creditbalance"  src="/assets/images/internationalicon.jpg" alt="international icon" onClick={() => setShowForeignPrice(true)}/>
                  }
                </div>
            )
          ) : (
          <div className="creditbalance">
            <span className="text"> SMS Credit balance: {userInfo.internationalcreditbalance} USD</span>
          </div>
          )}

         
            <div className="creditexpires">
              <span className="text"> Credit expires: {new Date(userInfo.creditexpires * 1000).toLocaleString("en-US", { timeZone: "Africa/Accra" })} </span>
            </div>
          
          <div className="profile">
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button    {...bindTrigger(popupState)}>
                    {/* <Avatar sx={{ width: 15, height: 15 }}> { userInfo.username[0]}</Avatar>*/}

                    {userInfo.username}
                  </Button>
                  <Menu {...bindMenu(popupState)}>

                    <MenuItem onClick={logOutHandler}>Logout</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

          </div>
        </>}

      </header>

    </>
  )
}
