import React from 'react';
import { useSelector} from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import LandingScreen from '../screens/landing/landingScreen';

export default function PrivateRoute({ component: Component, ...rest }) {
  

    const user = useSelector(state => state.user);
    const { userInfo } = user;
  
    return (
        <Route {...rest} render={(props) => userInfo ? (<Component {...props}></Component>)
            : (
              (window.location.hostname==="icsms.net" && window.location.pathname !== "/login" && window.location.pathname !== "/signup") ?
                <LandingScreen />
              :
                <Redirect to= "/login" />
        )
      }></Route >

    );

     }


     export function PublicRoute({ component: Component, ...rest }) {
  
    return (
      <Route {...rest} render={(props) => <Component {...props}></Component>}
      ></Route >

    );

     }