import React from 'react';


export default function PaymentHistory(props) {
    const payment = props.payment;
   
    return (
       
        <div className="payment_wrap">
            <div className="payment-amount">
                Amount: <span>{payment.amount}</span>
            </div>
            <div className="payment-currency">
                Currency: <span>{payment.currency}</span>
            </div>
            <div className="payment-currency">
                Time of payment: <span>{new Date(payment.date).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</span>
            </div>
            <div className="payment-before">
                Balance before: <span>{payment.previousbalance}</span>
            </div>
                <div className="payment-currency">
                Credit added: <span>{payment.balanceadded}</span>
            </div>
                <div className="payment-currency">
                Balance after: <span>{payment.newbalance}</span>
            </div>
        </div>
    )
}
