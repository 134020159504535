import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingBox from '../../components/LoadingBox';
import ScheduledSMS from '../../components/ScheduledSMS';
//import 'animate.css/animate.min.css';
import SearchField from "react-search-field";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ALL_SCHEDULED_PAGENUM, ALL_SCHEDULED_SEARCH, ALL_SCHEDULED_VISITED, CLEAN_ALL_SCHEDULED_SUCCESS, DELETE_SMS_ERROR } from '../../constants/bulksmsConstants';
import { v4 as uuidv4 } from 'uuid';

import { allscheduledx } from '../../actions/bulksmsAction';
import Alerts from '../../components/mainapp/Alerts';

export default function ClientScheduledScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const { allscheduled, allscheduleddearch, error, loading } = useSelector(state => state.bulksms);
  const { pageNum, search, visited } = allscheduleddearch;
  //remove duplicates
  const key = 'message_id';
  const uniqueallscheduled = [...new Map(allscheduled.map(item => [item[key], item])).values()];
  uniqueallscheduled.sort((a, b) => b.message_id - a.message_id);
   const mountedRef = useRef(true)


  const dispatch = useDispatch();
  
  

  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
         if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
            

      }, 5000)

    }
  }, [error]);


  useEffect(() => {
    if (!visited) {
      dispatch(allscheduledx(search, pageNum));
      dispatch({ type: ALL_SCHEDULED_VISITED, payload: true });
    }
  }, []);

  useEffect(() => {
    setErrorArray([]);
    return () => {
      // dispatch({ type: CLEAN_ALL_SCHEDULED_SUCCESS });
       mountedRef.current = false
      dispatch({ type: DELETE_SMS_ERROR });
    };

  }, []);

  const fetchData = () => {
    dispatch({ type: ALL_SCHEDULED_PAGENUM, payload: pageNum + 1 });
    let increaesdPageNum = pageNum + 1;
    dispatch(allscheduledx(search, increaesdPageNum));
  };

  const onChangeHandler = (val) => {
    dispatch({ type: CLEAN_ALL_SCHEDULED_SUCCESS });
    dispatch({ type: ALL_SCHEDULED_SEARCH, payload: val });
    dispatch({ type: ALL_SCHEDULED_PAGENUM, payload: 1 });
    dispatch(allscheduledx(search, pageNum));
  };

  let val = search ? search : "";

  return (
    <>
      {/*loading && <LoadingBox></LoadingBox>*/}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_SMS_ERROR }]} />

      <div className="search">
        <SearchField
          placeholder="Search..."
          onEnter={onChangeHandler}
          searchText={val}
          classNames="test-class"
        />
      </div>


      <div
        id="scrollableDiv"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          width:'100%',

        }}
      >

        {uniqueallscheduled ? (


          <InfiniteScroll
            dataLength={uniqueallscheduled.length}
            next={fetchData}
            loader={loading && <LoadingBox></LoadingBox>}
            hasMore={true}
            scrollableTarget="scrollableDiv"

            endMessage={
              <p style={{ textAlign: 'center' }} >
                <b>Data fetch completed</b>
              </p>
            }
          >
            {uniqueallscheduled.map((sc) => {
              return (
                <ScheduledSMS key={sc.message_id} sc={sc} setErrorArray={setErrorArray} personal={false} />
              )
            })}

          </InfiniteScroll>

        ) : (<></>)

        }

      </div>
    </>
  )
}


