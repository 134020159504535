import React, { useEffect, useState } from 'react';
import { Button, IconButton, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { changeaccountype, fetchUser, senderidrequest } from '../../actions/userAction';
import Alerts from '../../components/mainapp/Alerts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useRef } from 'react';
import { DELETE_USER_ERROR, RESELLER_PRICE_REQUEST } from '../../constants/userConstants';
import { v4 as uuidv4 } from 'uuid';
import NormalPrice from '../../components/NormalPrice';

export default function ProfileScreen() {
  const [apikey, setApikey] = useState("");
  const [errorArray, setErrorArray] = useState([]);
  const [copySuccess, setCopySuccess] = useState('');
  const [enablereferral, setEnablereferral] = useState(false);
  const [enablereseller, setEnablereseller] = useState(false);
  const inputRef = useRef(null);
  const { domainDetails } = useSelector(state => state.domainDetails);
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  const dispatch = useDispatch();
  const mountedRef = useRef(true)

  useEffect(() => {
    if (Object.keys(domainDetails).length) {
      if (userInfo.profile_id === domainDetails.profile_id) {
        setEnablereferral(domainDetails.supenablereferral);
        setEnablereseller(domainDetails.supenablereseller);
      } else {
        setEnablereferral(domainDetails.enablereferral);
        setEnablereseller(domainDetails.enablereseller);
      }
    }
  }, [domainDetails]);

  useEffect(() => {
    if (userInfo) {
      setApikey(userInfo.apikey);
    }
  }, [userInfo]);

  const apikeyHandler = async (e) => {
    e.preventDefault();
    setCopySuccess("");
    await axios.post('/smsgateway/api/frontend/changeapikey', { profile_id: userInfo.profile_id, username: userInfo.username }, {
      headers: { Authorization: `Bearer ${userInfo.token}` }
    });
    dispatch(fetchUser());

  };

  const copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!')
  };
  let accountType = userInfo.accountType 

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <>
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[]} />
      <div className="profile-screen">
        <hr />
       
        <div><SenderIdRequest /></div>
        {(userInfo.supid === 6 || userInfo.supid === 1) &&
         <div style={{ marginTop: '20px' }}><ChangeAccountType setErrorArray={setErrorArray} /></div>
        }

        {(enablereseller > 0 && (userInfo.supid === 6 || userInfo.supid === 1 || userInfo.supid === 308 )) &&
          <div className="reseller-container">
            <div>Want to start a side hustle?</div>
            <div>Do you want to help companies increase their sales by providing them with a BULKSMS service?</div>
            <div>If you answered yes to above questions and want to start your own BULKSMS business without writing a single line of code, click on below button to start your entrepreneurial journey</div>
            <div className="reseller-button"><BecomeReseller userInfo={userInfo} setErrorArray={setErrorArray} /></div>
          </div>
        }

        <div className="accountdetails">
          <div>Username : {userInfo.username} </div>
          <div>Email : {userInfo.email} </div>
          <div>Mobile Number : {userInfo.telnumber}</div>
          <div>Company : {userInfo.companyname}</div>
          <div>Country : {userInfo.country}</div>
          <div>Account Type : {accountType}</div>
          {userInfo.senderidapproved &&
            <div>Approved sender ID's : {userInfo.senderidapproved}</div>
          }
          {userInfo.pendingsenderid &&
            <div>Sender ids awaiting approval : {userInfo.pendingsenderid}</div>
          }
          {enablereferral === 1 &&
            <div>Referal Code : {userInfo.referralcode}</div>
          }
        </div>
         <div className="button">
          <Button variant="contained" onClick={apikeyHandler} >Generate new API key</Button>

        </div>

        <div className="profile-copy">

          <input type="text" ref={inputRef} readOnly autoComplete="off" value={apikey} />
          <div>
            <ContentCopyIcon onClick={copyToClipboard} />
            <div>{copySuccess}</div>
          </div>
        </div>
      </div>
    </>
  )
}


export const SenderIdRequest = () => {
  const [open, setOpen] = useState(false);

  const [senderIds, setSenderIds] = useState("")

  const [errorArray, setErrorArray] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleSubmit = () => {
    if (senderIds === "") {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "No senderId provided", status: "failed" }];
      });

      setTimeout(() => {
        if (!mountedRef?.current) {
          return null
        }
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });


      }, 5000)

      return
    }
    let x = senderIds.split(",")
    let array = []
    for (let i = 0; i < x.length; i++) {
      if (x[i] !== "") {
        array.push(x[i].trim())
      }

      if (x[i].trim().length > 11) {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: `Each senderId must have a maximum character length of eleven(11), please check ${x[i]}`, status: "failed" }];
        });

        setTimeout(() => {
          if (!mountedRef?.current) {
            return null
          }
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)

        return
      }
      const regex = /^\d+$/;
      if (regex.test(String(x[i].trim()))) {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: `Sorry we dont accept numbers only as sender ids`, status: "failed" }];
        });

        setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)

        return
      }
      
    
    }
    let uniqueArray = [...new Set(array)];

    dispatch(senderidrequest(uniqueArray.join(",")))
    handleClose();
    dispatch(fetchUser());
    setSenderIds("");

  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>Request for approval of your sender ID(s)</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="modal" onClick={(e) => e.stopPropagation()}>

          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_USER_ERROR }]} />
          <form>
            <h2>SenderId(s) request</h2>
            <textarea onChange={e => setSenderIds(e.target.value)} rows="5" required placeholder="Enter one or more sender IDs. Sender IDs must be comma separated. Maximum character length of each sender ID is eleven(11)" value={senderIds} />
            <Button variant="contained" onClick={handleSubmit}>Request for Sender ID(s) approval</Button>
          </form>
        </div>
      </Modal>
    </>
  );
}


export const ChangeAccountType = (props) => {
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  const [open, setOpen] = useState(false);
  const [accountType, setAccountType] = useState(userInfo.accountType)
  const [errorArray, setErrorArray] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const mountedRef = useRef(true)


  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleSubmit = () => {
    if (accountType === userInfo.accountType) {
      handleClose();
      return

    } 
    /*
    else if (accountType === "PREMIUM" && userInfo.creditbalance > 2000) {

      if (props.setErrorArray) {
        let id = uuidv4();
        props.setErrorArray((prev) => {
          return [...prev, { id: id, message: "To change to PREMIUM account type, you need to have less than 2000 credits", status: "failed" }];
        });
        setTimeout(() => {
          if (!mountedRef?.current) {
            return null
          }
          props.setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)
      } else {
        let id = uuidv4();
        setErrorArray((prev) => {
          return [...prev, { id: id, message: "To change to PREMIUM account type, you need to have less than 2000 credits", status: "failed" }];
        });
        setTimeout(() => {
          if (!mountedRef?.current) {
            return null
          }
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)

      }


      setAccountType(userInfo.accountType)
      handleClose();
      return

    } else if (accountType === "RESELLER" && userInfo.creditbalance < 10000) {

      if (props.setErrorArray) {
        let id = uuidv4()
        props.setErrorArray((prev) => {
          return [...prev, { id: id, message: "To change to RESELLER account type, you need to have a minimum credit balance of 10,000", status: "failed" }];
        });
        setTimeout(() => {
          if (!props.mountedRef?.current) {
            return null
          }
          props.setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)
      } else {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: "To change to RESELLER account type, you need to have a minimum credit balance of 10,000", status: "failed" }];
        });
        setTimeout(() => {
          if (!mountedRef?.current) {
            return null
          }
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)
      }



      setAccountType(userInfo.accountType)
      handleClose();
      return

    }
    */
    dispatch(changeaccountype(accountType))

    //dispatch(fetchUser());

    handleClose();

  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>Change Account Type</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="modal" onClick={(e) => e.stopPropagation()}>

          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_USER_ERROR }]} />
          <form>
            <h2>Change Account Type</h2>
            <select
              value={accountType}
              onChange={e => setAccountType(e.target.value)}>
              <option value="REGULAR">REGULAR</option>
              {(userInfo.supid === 6 || userInfo.supid === 1) && <option value="PREMIUM">PREMIUM</option>}
              <option value="RESELLER">RESELLER</option>
            </select>

            <Button variant="contained" onClick={handleSubmit}>Change Account Type</Button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export const BecomeReseller = (props) => {
  const { userInfo } = useSelector(state => state.user);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [errorArray, setErrorArray] = useState("");
  const [domain, setDomain] = useState(userInfo.domain || "");
  const [companyname, setCompanyname] = useState(userInfo.companyname);
  const [logo, setLogo] = useState(userInfo.logo || "");
  const [backgroundImage, setBackgroundImage] = useState(userInfo.backgroundImage || "");
  const [files, setFiles] = useState([]);
  const [filesBackground, setFilesBackground] = useState([]);
  const [senderid, setSenderid] = useState(userInfo.senderid === "DUMMY" ?
    "" : userInfo.senderid);
  const [enablereferral, setEnablereferral] = useState(userInfo.enablereferral === 1);
  const [enablereseller, setEnablereseller] = useState(userInfo.enablereseller === 1);
  const [referralpercentage, setReferalpercentage] = useState(userInfo.referralpercentage * 100);
  const [usepaymentgateway, setUsepaymentgateway] = useState(userInfo.usepaymentgateway === 1);
  const [paystackseckey, setPaystackseckey] = useState(userInfo.paystackseckey);
  const [paystackpubkey, setPaystackpubkey] = useState(userInfo.paystackpubkey);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(userInfo.recaptcha_site_key);
  const [recaptchaSecretKey, setRecaptchaSecretKey] = useState(userInfo.recaptcha_secret_key);
  const [showImage, setShowImage] = useState(false);
  const [showImageBackground, setShowImageBackground] = useState(false);
  const [currency, setCurrency] = useState("GHS");
  const handleClose = () => { setOpen(false); setShowImage(false); setShowImageBackground(false); }
  const dispatch = useDispatch();
  const mountedRef = useRef(true);

  const imageUploadHandler = (files) => {
    //https://www.youtube.com/watch?v=NZElg91l_ms
    // files is an array of file
    if (files.length < 1) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an image file", status: "warning" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) {
          return null
        }
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return
    }
    const file = files[0];

    const filetype = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp']

    if (!filetype.includes(file.name.split(".")[1])) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an image file", status: "warning" }];
      });
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setLogo(reader.result);
      setShowImage(true);
    }
  }

const imageUploadHandlerBackground = (files) => {
   if (files.length < 1) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an image file", status: "warning" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) {
          return null
        }
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return
    }
    const file = files[0];

    const filetype = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp']

    if (!filetype.includes(file.name.split(".")[1])) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an image file", status: "warning" }];
      });
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBackgroundImage(reader.result);
      setShowImageBackground(true);
    }
  }


  useEffect(() => {
    return () => {
      mountedRef.current = false
    };
  }, []);

  const updateDomainDetailsHandler = async () => {
    try {
      let { data } = await axios.post(`/smsgateway/api/frontend/update-domain-details/`, {
        profile_id: userInfo.profile_id,
        domain,
        companyname,
        localrate: userInfo.localrate,
        logo,
        backgroundImage,
        senderid,
        enablereferral,
        referralpercentage,
        usepaymentgateway,
        paystackseckey,
        paystackpubkey,
        recaptcha_secret_key: recaptchaSecretKey,
        recaptcha_site_key: recaptchaSiteKey,
        enablereseller

      }, {
        headers: { Authorization: `Bearer ${userInfo.token}` }
      });
      if (data.status === "successful") {
        let id = uuidv4()
        props.setErrorArray((prev) => {
          return [...prev, { id: id, message: data.message, status: "successful" }];
        });
        setTimeout(() => {
          if (!props.mountedRef?.current) {
            return null
          }
          props.setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });
        }, 5000)
        handleClose();
      } else if (data.error) {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: data.error.message, status: "failed" }];
        });
        setTimeout(() => {
          if (!mountedRef?.current) {
            return null
          }
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });
        }, 5000)
      }
    } catch (error) {
      console.log(error);
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Oops something went wrong", status: "failed" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) {
          return null
        }
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
    }
  }


  const deleteDataPrice = (id, type) => {
    let updatePriceData = JSON.parse(userInfo.localrate)
    let priceData = updatePriceData[type];  
    priceData = priceData.filter(x => x.id !== id);
    if(type === "REGULAR"){
      updatePriceData["REGULAR"] = priceData 
    }else if(type === "PREMIUM"){
      updatePriceData["PREMIUM"] = priceData  
    }
    dispatch({ type: RESELLER_PRICE_REQUEST, payload: { updatePriceData: JSON.stringify(updatePriceData) } });
  }


  const saveDataPriceToDB = (data, type) => {
    let updatePriceData = JSON.parse(userInfo.localrate)
    let priceData = updatePriceData[type];
    priceData = priceData.filter(x => x.id !== data.id);
    priceData.push(data);
    if(type === "REGULAR"){
      updatePriceData["REGULAR"] = priceData 
    }else if(type === "PREMIUM"){
      updatePriceData["PREMIUM"] = priceData  
    }  
    dispatch({ type: RESELLER_PRICE_REQUEST, payload: { updatePriceData: JSON.stringify(updatePriceData)} });
  }
 
  return (
    <>
      <Button variant="contained" onClick={handleOpen}>Become a reseller</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div id="modal-reseller" onClick={(e) => e.stopPropagation()}>
          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_USER_ERROR }]} />
          <form>
            <h2>Follow below steps to become a reseller without writing a single code</h2>
            <h4>1. Point your domain or sub-domain to our IP 142.93.46.83</h4>
            <h4>2. Notify us of your interest in becoming a reseller so we complete the setup for you</h4>
            <h4>3. Complete or Update the form below for your SMS platform customized with your own logo, company name etc.</h4>
            <div>
              <p>Change your account type to "RESELLER". Though not required "RESELLER" account type is ideal for running a reseller business</p>
              <h4>Complete/update below form to customize your bulksms platform</h4>
              <p>Update your domain (eg. domain.com)  or subdomain (eg sub.domain.com) you pointed to our IP</p>
              <input type="text" value={domain} onChange={e => setDomain(e.target.value)} placeholder="domain name" />
              <p>Update your company name</p>
              <input type="text" value={companyname} onChange={e => setCompanyname(e.target.value)} placeholder="company name" />
              <p>Update your sender id for communicating with your customers.(Kindly ensure sender id is approved)</p>
              <input type="text" value={senderid} onChange={e => setSenderid(e.target.value)} placeholder="sender id" />
              <p>Update your company logo</p>
              {showImage &&
                <img style={{ objectFit: 'contain', height: '80px', borderRadius: '10px', marginBottom: '10px' }} src={logo} alt="logo" />
              }
              <div className="logo-upload">
                <input type="file" onChange={(e) => {
                  setFiles(e.target.files);
                  imageUploadHandler(e.target.files);
                }}
                />
                <button style={{ backgroundColor: 'blue', color: 'white', cursor: 'pointer' }} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  document.querySelector('.logo-upload>input[type="file"]').click();
                }}>Upload logo</button>
              </div>
              {showImageBackground &&
                <img style={{ objectFit: 'contain', height: '80px', borderRadius: '10px', marginBottom: '10px' }} src={backgroundImage} alt="logo" />
              }
              <p>Update background image (optional)</p>
              <div className="background-image-upload">
                <input type="file" onChange={(e) => {
                  setFilesBackground(e.target.files);
                  imageUploadHandlerBackground(e.target.files);
                }}
                />
                <button style={{ backgroundColor: 'blue', color: 'white', cursor: 'pointer' }} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  document.querySelector('.background-image-upload>input[type="file"]').click();
                }}>Upload background Image</button>
              </div>

              {JSON.parse(userInfo.localrate)?.["REGULAR"] &&
                <>
                 <hr />
                  <AddPriceRegular />
                  <div>UPDATE REGULAR PRICE</div>
                  {JSON.parse(userInfo.localrate)["REGULAR"].sort((a,b) => b.price - a.price).map((data, i) => {
                    return (
                      <NormalPrice key={data.id} data={data} currency={currency} deleteDataPrice={deleteDataPrice} saveDataPriceToDB={saveDataPriceToDB} type="REGULAR" />
                    )}
                  )}
                  <hr /> 
                </>

              }

              <hr /> 
            

              {(userInfo.profile_id === 6 || userInfo.profile_id === 1) && JSON.parse(userInfo.localrate)?.["PREMIUM"] && 
              <> 
                <hr />
                <AddPricePremium />
                <div>UPDATE PREMIUM PRICE</div>
                {JSON.parse(userInfo.localrate)["PREMIUM"].sort((a,b) => b.price - a.price).map((data, i) => {
                  return (
                    <NormalPrice key={data.id} data={data} currency={currency} deleteDataPrice={deleteDataPrice} saveDataPriceToDB={saveDataPriceToDB} type="PREMIUM" />
                  )}
                )}
                <hr /> 
              </>
              }
            
              {/*
              (userInfo.supid === 6 || userInfo.supid === 1) &&
              <>
              <p>Allow your clients to also become resellers</p>
              <label>
                <input
                  type="checkbox"
                  checked={enablereseller}
                  value="Use in build payment gateway"
                  onChange={e => setEnablereseller(!enablereseller)}
                />
                {enablereseller ? "No" : "Yes"}
              </label>
              </>
              */
              }

              {/*
              <p>Update your your google recaptcha site key for your domain. <a style={{ color: 'blue' }} href="https://www.youtube.com/watch?v=s9gw5ArrEz4" target="_blank"  rel="noreferrer">See how to get recaptcha site key</a></p>
              <input type="text" value={recaptchaSiteKey} onChange={e => setRecaptchaSiteKey(e.target.value)} placeholder="update your domain google recaptcha site key" />
              <p>Update your your google recaptcha secret key for your domain <a style={{ color: 'blue' }} href="https://www.youtube.com/watch?v=s9gw5ArrEz4" target="_blank"  rel="noreferrer">See how to get recaptcha secret key</a></p>
              <input type="password" value={recaptchaSecretKey} onChange={e => setRecaptchaSecretKey(e.target.value)} placeholder="update your domain google recaptch secret key" />
                */}
              <p>Use built in payment gateway to collect payments from customers?</p>
              <label>
                <input
                  type="checkbox"
                  checked={usepaymentgateway}
                  value="Use in build payment gateway"
                  onChange={e => setUsepaymentgateway(!usepaymentgateway)}
                />
                {usepaymentgateway ? "No" : "Yes"}
              </label>

              {usepaymentgateway &&
                <>
                  <p>Update your paystack public key <a style={{ color: 'blue' }} href="https://dashboard.paystack.com/#/settings/developer" target="_blank"  rel="noreferrer">See how to get your paystack public key</a></p>
                  <input type="text" value={paystackpubkey} onChange={e => setPaystackpubkey(e.target.value)} placeholder="update paystack wave public key" />
                  <p>Update your paystack secret key  <a style={{ color: 'blue' }} href="https://dashboard.paystack.com/#/settings/developer" target="_blank"  rel="noreferrer">See how to get your paystack secret key</a> </p>
                  <input type="password" value={paystackseckey} onChange={e => setPaystackseckey(e.target.value)} placeholder="update paystack wave secret key" />
                </>
              }
              <p>Enable referral program</p>
              <label>
                <input
                  type="checkbox"
                  checked={enablereferral}
                  value="Enable referral program"
                  onChange={() => setEnablereferral(!enablereferral)}
                />
                {enablereferral ? "No" : "Yes"}
              </label>
              {enablereferral &&
                <>
                  <p>Enter percentage referral fees (example 10%) </p>
                  <input type="text" value={referralpercentage} onChange={e => setReferalpercentage(e.target.value.replaceAll(/[^0-9]/g, ''))} />
                </>
              }

            </div>
            <Button variant="contained" onClick={updateDomainDetailsHandler}>Save</Button>
          </form>
        </div>
      </Modal>
    </>
  );
}


export const AddPriceRegular = () => {
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  const dispatch = useDispatch();
  const addDataPrice = () => {
    let updatePriceData = JSON.parse(userInfo.localrate)
    let priceData = updatePriceData["REGULAR"];
    let data = { price: 0, rate: 0.048, expiry: 0.25, id: uuidv4() };
    priceData.push(data);
    updatePriceData["REGULAR"] = priceData 
    dispatch({ type: RESELLER_PRICE_REQUEST, payload: { updatePriceData: JSON.stringify(updatePriceData) } });
  }

  return (
    <>

      <div className="price-management-container">
        <p>Add/Update Regular pricing</p>
        <div className="add-new-price" title="Add new price">
          <IconButton onClick={addDataPrice} >
            <AddIcon />
          </IconButton>
        </div>

      </div>

    </>
  );
}



export const AddPricePremium = () => {
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  const dispatch = useDispatch();
  const addDataPrice = () => {
    let updatePriceData = JSON.parse(userInfo.localrate)
    let priceData = updatePriceData["PREMIUM"];
    let data = { price: 0, rate: 0.048, expiry: 1, id: uuidv4() };
    priceData.push(data);
    updatePriceData["REGULAR"] = priceData 
    dispatch({ type: RESELLER_PRICE_REQUEST, payload: { updatePriceData: JSON.stringify(updatePriceData) } });
  }
  return (
    <>

      <div className="price-management-container">
        <p>Add/Update Preminum pricing</p>
        <div className="add-new-price" title="Add new price">
          <IconButton onClick={addDataPrice} >
            <AddIcon />
          </IconButton>
        </div>

      </div>

    </>
  );
}