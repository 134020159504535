import { Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { approvesenderid, rejectsenderid } from '../actions/userAction'

export default function PendingSenderId(props) {
  const user = useSelector(state => state.user);
  const { userInfo, allusers} = user;
   const [supname, setSupname] = React.useState("icsms");
    
    useEffect(() => {
        let sup = allusers.filter((item) => item.profile_id === user.supid);
        if(sup.length){
            setSupname(sup[0].username);
        }
    }, [allusers]);


  return (
      <div className='pendingsenderid'>
        {userInfo.profile_id === 6 && (
          <div>Supervisor : <div style={{color:'#524eb7'}}>{supname}</div></div>
        )}
          <div>Usename : <div style={{color:'#524eb7'}}>{props.user.username}</div></div>
          <div>Sender ID : <div style={{color:'#01017a', fontSize:'18px'}}>{props.user.senderid}</div></div> 
          {userInfo.profile_id === 6 && (<><ApproveButton user= {props.user}/>
          <RejectButton user= {props.user}/></>)
          }
      </div>

  )
}



export const ApproveButton=({user})=>{
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const dispatch = useDispatch();
  
    const handleSubmit = (e) => { 
        e.preventDefault()
        dispatch(approvesenderid(user.profile_id, user.senderid))
        handleClose();
    };
  
    return (
     <>
        <button className="approve" onClick={handleOpen} >Approve</button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          
          <div className="modal" onClick={(e)=>e.stopPropagation()}>
            <form>
                <h2>Do you want to approve sender ID?</h2>
              <Button variant="contained" onClick={handleSubmit}>Approve Sender ID</Button>
            </form>
          </div>
        </Modal>
     </>
    );
  }

  
export const RejectButton=({user})=>{
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const dispatch = useDispatch();
  
    const handleSubmit = (e) => { 
        e.preventDefault()
        dispatch(rejectsenderid(user.profile_id, user.senderid))
        handleClose();
    };
  
    return (
     <>
       <button className="reject" onClick={handleOpen}>Reject</button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          
          <div className="modal" onClick={(e)=>e.stopPropagation()}>
            <form>
                <h2>Do you want to reject sender ID?</h2>
              <Button variant="contained" onClick={handleSubmit}>Reject Sender ID</Button>
            </form>
          </div>
        </Modal>
     </>
    );
  }
