import axios from "axios";
import { CREATE_QUICK_FAIL, CREATE_QUICK_REQUEST, CREATE_QUICK_SUCCESS, CREATE_RECURRING_FAIL, CREATE_RECURRING_REQUEST, CREATE_RECURRING_SUCCESS, CREATE_SCHEDULED_FAIL, CREATE_SCHEDULED_REQUEST, CREATE_SCHEDULED_SUCCESS, DELETE_RECURRING_FAIL, DELETE_RECURRING_REQUEST, DELETE_RECURRING_SUCCESS, DELETE_SCHEDULED_FAIL, DELETE_SCHEDULED_REQUEST, DELETE_SCHEDULED_SUCCESS, FETCH_ALL_QUICK_FAIL, FETCH_ALL_QUICK_REQUEST, FETCH_ALL_QUICK_SUCCESS, FETCH_ALL_RECURRING_FAIL, FETCH_ALL_RECURRING_REQUEST, FETCH_ALL_RECURRING_SUCCESS, FETCH_ALL_SCHEDULDED_FAIL, FETCH_ALL_SCHEDULED_REQUEST, FETCH_ALL_SCHEDULED_SUCCESS, FETCH_QUICK_FAIL, FETCH_QUICK_REQUEST, FETCH_QUICK_SUCCESS, FETCH_RECURRING_FAIL, FETCH_RECURRING_REQUEST, FETCH_RECURRING_SUCCESS, FETCH_SCHEDULED_FAIL, FETCH_SCHEDULED_REQUEST, FETCH_SCHEDULED_SUCCESS, UPDATE_QUICK_FAIL, UPDATE_QUICK_REQUEST, UPDATE_QUICK_SUCCESS, UPDATE_RECURRING_FAIL, UPDATE_RECURRING_REQUEST, UPDATE_RECURRING_SUCCESS, UPDATE_SCHEDULED_FAIL, UPDATE_SCHEDULED_REQUEST, UPDATE_SCHEDULED_SUCCESS } from "../constants/bulksmsConstants";
import { USER_FETCH_SUCCESS } from "../constants/userConstants";
import { signout } from "./userAction";





//fetch ALL qucik sms - lazy loads
export const allbulksmsx = (search, pageNum) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: FETCH_ALL_QUICK_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/allbulksms', { profile_id: userInfo.profile_id, search, pageNum }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });

        dispatch({ type: FETCH_ALL_QUICK_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: FETCH_ALL_QUICK_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};


//fetch quicksms - Lazy loads
export const quickrequest = (search, pageNum) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: FETCH_QUICK_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/quickrequest', { profile_id: userInfo.profile_id, search, pageNum }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: FETCH_QUICK_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: FETCH_QUICK_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};

export const updatequick = (messge_id) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: UPDATE_QUICK_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/updatequick', { profile_id: userInfo.profile_id, messge_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: UPDATE_QUICK_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: UPDATE_QUICK_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};


//create/send quicksms 
export const createquick = (messageObjects) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();
    dispatch({ type: CREATE_QUICK_REQUEST });
    try {

        const { data } = await axios.post('/smsgateway/api/sendsms', { messageobject: messageObjects }, {
            headers: { Accept: 'application/json', Authorization: `Bearer ${userInfo.apikey}` }
        });
        
        dispatch({ type: CREATE_QUICK_SUCCESS, payload: { error: data } });

        for (let i = 0; i < messageObjects.length; i++) {
            let messageObject = messageObjects[i];
            if(messageObject.messageType === "scheduled" || "birthday"){
                userInfo.scheduledsmsTab = true;
            }else if(messageObject.messageType === "recurring"){
                userInfo.recurringsmsTab = true;
            }else{
                userInfo.quicksmsTab = true;
            }
        }
       
        let rccg_array = messageObjects.filter(x => x.sourceAddress.toLowerCase().includes("rccg"));
        if(rccg_array.length === 0){
            // update the user info
            userInfo.creditbalance = data.creditbalance;
            userInfo.internationalcreditbalance = data.internationalcreditbalance;
        }
        dispatch({ type: USER_FETCH_SUCCESS, payload: { userInfo : userInfo } });
        localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...userInfo }))

        if (data.message && data.message === "Invalid Token") {
            dispatch(signout()); 
        }


    } catch (error) {
        if (error.message.trim() === "Request failed with status code 413") {

            dispatch({
                type: CREATE_QUICK_FAIL,
                payload: {
                    message: "Kindly keep the browser open while we send the messages in batches for you. Thank you",
                    status: "info"
                }
            });

            let batchsize = 100;
            let batches = Math.ceil(messageObjects.length / batchsize);

            // loop through the array and send in batches
            for (let i = 0; i < batches; i++) {
                let batch = messageObjects.slice(i * batchsize, (i + 1) * batchsize);

                try {
                    await axios.post('/smsgateway/api/sendsms', { messageobject: batch }, {
                        headers: { Accept: 'application/json', Authorization: `Bearer ${userInfo.apikey}` }
                    });
                  
                    
                } catch (error) {
                    console.log(error);
                }

                // wait for 2s before sending the next batch
                await new Promise(resolve => setTimeout(resolve, 2000));
            }


            dispatch({
                type: CREATE_QUICK_SUCCESS,
                payload: {
                    message: "Messages sent successfully, Thank you",
                    status: "successful"
                }
            });


        } else {

            dispatch({
                type: CREATE_QUICK_FAIL,
                payload: {
                    message: error.response && error.response.data.message ? error.response.data.message : error.message,
                    status: "failed"
                }
            });
        }
    }

};





//fetch  ALL scheduled sms - lazy load
export const allscheduledx = (search, pageNum) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: FETCH_ALL_SCHEDULED_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/allscheduled', { profile_id: userInfo.profile_id, search, pageNum }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: FETCH_ALL_SCHEDULED_SUCCESS, payload: data });


    } catch (error) {
        dispatch({
            type: FETCH_ALL_SCHEDULDED_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};





//fetch  scheduled sms - lazy loads
export const schedeuledrequest = (search, pageNum) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: FETCH_SCHEDULED_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/scheduledrequest', { profile_id: userInfo.profile_id, search, pageNum }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: FETCH_SCHEDULED_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: FETCH_SCHEDULED_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};



//create scheduled sms - lazy loads
export const createscheduled = (messageObjects) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: CREATE_SCHEDULED_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/sendsms', { messageobject: messageObjects }, {
            headers: { Accept: 'application/json', Authorization: `Bearer ${userInfo.apikey}` }
        });
        dispatch({ type: CREATE_SCHEDULED_SUCCESS, payload: { error: data } });
        
        userInfo.scheduledsmsTab = true;
        dispatch({ type: USER_FETCH_SUCCESS, payload: { userInfo : userInfo } });
        localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...userInfo }))
 

    } catch (error) {
        dispatch({
            type: CREATE_SCHEDULED_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};


//update scheduled sms - lazy loads

export const updatescheduled = (message_id, shortMessage, sourceAddress, messageDescription, expectedDeliveryTime) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: UPDATE_SCHEDULED_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/updatescheduled', { profile_id: userInfo.profile_id, message_id, shortMessage, sourceAddress, messageDescription, expectedDeliveryTime }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: UPDATE_SCHEDULED_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: UPDATE_SCHEDULED_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};

//delete  scheduled sms - lazy load
export const deletescheduled = (message_id) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: DELETE_SCHEDULED_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/deletescheduled', { profile_id: userInfo.profile_id, message_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: DELETE_SCHEDULED_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: DELETE_SCHEDULED_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};



//fetch  ALL recurring sms - lazy loads
export const allrecurringx = (search, pageNum) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: FETCH_ALL_RECURRING_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/allrecurring', { profile_id: userInfo.profile_id, search, pageNum }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: FETCH_ALL_RECURRING_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: FETCH_ALL_RECURRING_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};





//fetch recurring sms - lazy loads
export const recurringrequest = (search, pageNum) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: FETCH_RECURRING_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/recurringrequest', { profile_id: userInfo.profile_id, search, pageNum }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: FETCH_RECURRING_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: FETCH_RECURRING_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};



//create scheduled sms - lazy loads
export const createrecurring = (messageObjects) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: CREATE_RECURRING_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/sendsms', { messageobject: messageObjects }, {
            headers: { Accept: 'application/json', Authorization: `Bearer ${userInfo.apikey}` }
        });
        dispatch({ type: CREATE_RECURRING_SUCCESS, payload: { error: data } });

        userInfo.recurringsmsTab = true;
        dispatch({ type: USER_FETCH_SUCCESS, payload: { userInfo : userInfo } });
        localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...userInfo }))
 
    } catch (error) {
        dispatch({
            type: CREATE_RECURRING_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};


//update scheduled sms - lazy loads
export const updaterecurring = (message_id, shortMessage, sourceAddress, messageDescription, expectedDeliveryTime, recurringPeriod, recurringInterval) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: UPDATE_RECURRING_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/updaterecurring', { profile_id: userInfo.profile_id, message_id, shortMessage, sourceAddress, messageDescription, expectedDeliveryTime, recurringPeriod, recurringInterval }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: UPDATE_RECURRING_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: UPDATE_RECURRING_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};

//delete  scheduled sms - lazy load
export const deleterecurring = (message_id) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();

    dispatch({ type: DELETE_RECURRING_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/deleterecurring', { profile_id: userInfo.profile_id, message_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: DELETE_RECURRING_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: DELETE_RECURRING_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};
