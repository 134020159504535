import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


export default function ClientCard(props) {
    const { user }= props;
    const { domainDetails } = useSelector(state => state.domainDetails);
    const { allusers } = useSelector(state => state.user);
    const [supname, setSupname] = React.useState("icsms");
    
    useEffect(() => {
        let sup = allusers.filter((item) => item.profile_id === user.supid);
        if(sup.length){
            setSupname(sup[0].username);
        }
    }, [allusers]);

    let accountType = user.accountType 
    
    return (
    
        <div className="user_client">
            {domainDetails.domainprofile_id === 6 &&
            <div>
                <span>{supname}'s- Client</span>
            </div>
            }
            <div>
                Username: <span>{user.username}</span>
            </div>
            <div >
                Email: <span>{user.email}</span>
            </div>
            <div>
                Mobile number: <span>{user.telnumber}</span>
            </div>
            {user.content==="local" && 
            <div>
                Credit balance: <span>{user.creditbalance}</span>
            </div>
            }
            {domainDetails.internationalcreditbalance > 0 && false &&
            <div>
                International creditbalance: <span>{user.internationalcreditbalance}</span>
            </div>
            }

             <div>
                Company name: <span>{user.companyname}</span>
            </div>
            <div>
                Account type: <span>{accountType}</span>
            </div>
            <div >
                Approved Sender IDs: <span>{user.senderidapproved}</span>
            </div>
            <div>
                Country: <span>{user.country}</span>
            </div>
            <div>
                Time of registration: <span>{new Date(user.setuptime).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</span>
            </div>
        
        </div>
    )
}
