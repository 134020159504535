import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Alerts from '../../components/mainapp/Alerts';
import LoadingBox from '../../components/LoadingBox';
import SearchField from "react-search-field";
import { CLEAN_ALL_PURCHASE_CREDIT_HISTORY_SUCCESS, DELETE_CREDIT_ERROR } from '../../constants/historypurchasedbulksmsConstants';
import { allpurchasehistory } from '../../actions/historypurchsedbulksmsAction';
import { v4 as uuidv4 } from 'uuid';
import ClientPaymentHistory from '../../components/ClientPaymentHistory';

export default function ClientPurchaseCreditHistoryScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState('');
   const mountedRef = useRef(true)

  const { allcredithistory, error, loading } = useSelector(state => state.historypurchasedsms);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
         if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      
    }
  }, [error]);

  useEffect(() => {
    dispatch(allpurchasehistory(search, pageNum))
  }, [search, pageNum]);

  const fetchData = () => {
    console.log("run")
    setPageNum(prev =>{
      return prev + 1
    })
  };

  useEffect(() => {
    setErrorArray([]);
    return () => {
      dispatch({ type: DELETE_CREDIT_ERROR });
      
      mountedRef.current = false
    
    };
  }, []);

  const onChangeHandler = (val) => {
    dispatch({ type: CLEAN_ALL_PURCHASE_CREDIT_HISTORY_SUCCESS });
    setSearch(val);
    setPageNum(1);
  };


  return (
    <>
      {loading && <LoadingBox></LoadingBox>}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_CREDIT_ERROR }]} />
      <div className="search">
        <SearchField
          placeholder="Search..."
          onChange={onChangeHandler}
          searchText=""
          classNames="test-class"
        />
      </div>
      <div
        id="scrollableDivCH"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          width: '100%',

        }}
      >
       <InfiniteScroll
            dataLength={allcredithistory.length}
            next={fetchData}
            loader={loading && <LoadingBox></LoadingBox>}
            hasMore={true}
            scrollableTarget="scrollableDivCH"

            endMessage={
              <p style={{ textAlign: 'center' }} >
                <b>Data fetch completed</b>
              </p>
            }
          >
      <div className="payment-headtitle">Client credit purchase history</div> 
      <div className="payment-container">
       
          {allcredithistory.map(payment => (
              <ClientPaymentHistory key={payment.paymentid} payment={payment} />
            ))}
        
      </div>
      </InfiniteScroll>
      </div>
    </>
  )
}