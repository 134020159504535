import '../screens/signup/SignUpScreen.css';
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBox from './LoadingBox';

import { Alert } from '@mui/material';
import axios from 'axios';
import useEffectAsync from './useEffectAsync';
import { USER_SIGNUP_SUCCESS } from '../constants/userConstants';

export default function NumberValidationForm(props) {

    const { loading, error } = useSelector(state => state.user);
    const { domainDetails } = useSelector(state => state.domainDetails);

    const { setUsername, setCname, setPhone, setEmail, setPassword, setReferralCode,
        setCpassword, setHumanKey, submitToSignUp, attempts, setAttempts, codeId, country,
        sendSMSFunction, setToggleFromSignUpToValidation, errorArray,
        removeErrorMessage, setErrorArray, temporalid } = props;

    const [code, setCode] = useState("");
    const [timer, setTimer] = useState(600);
    const mountedRef = useRef(true)

    useEffect(()=>{
    return () => { 
      mountedRef.current = false
    }
  }, [])

    const dispatch = useDispatch();

    const verifyCode = async () => {
        //send sms to retrieve sent Code
        const { data } = await axios.post('/smsgateway/api/frontend/verifysmscode', { codeId, code, temporalid, country });
        if (data.hasOwnProperty("isVerified")) {
            return data;
        } else {

            dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });
            if (data.userInfo) {
                localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
            }
            setErrorArray([]);
            setUsername("");
            setCname("");
            setPhone("");
            setEmail("");
            setPassword("");
            setReferralCode("");
            setCpassword("");
            setHumanKey("")
        }
    };
     useEffectAsync(async ()  => {
        if(country !== 'Ghana'){
            const { data } = await axios.post('/smsgateway/api/frontend/verifysmscode', { codeId, code, temporalid, country });
            dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });
            if (data.userInfo) {
                localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
            }
            setErrorArray([]);
            setUsername("");
            setCname("");
            setPhone("");
            setEmail("");
            setPassword("");
            setReferralCode("");
            setCpassword("");
            setHumanKey("")
        }
     }, [country]);


    /*
        const confirmCode = async (e) => {
            e.preventDefault();
            const sentCode = await retrieveCode();
               
                 if (code === sentCode) {
                     submitToSignUpHandler();
                 } else {
                 //  alert(`Wrong Code Entered, please re-enter value code again or resend code again you have ${attempts} attempts left`);
                     setAttempts(attempts - 1);
                    
                 }
           
        };
        */



    useEffect(() => {
        if (error) {
            let id = uuidv4()
            error.id = id;
            setErrorArray((prev) => {
                return [...prev, error];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

        }
    }, [error]);

    useEffectAsync(async () => {
        if (code.length === 4) {
            try {
                const data = await verifyCode();

                if (data.error) {
                    let id = uuidv4()
                    error.id = id;
                    setErrorArray((prev) => {
                        return [...prev, error];
                    });

                    setTimeout(() => {
                        if (!mountedRef?.current) return null
                        setErrorArray(prev => {
                            return prev.filter(x => x.id !== id);
                        });

                    }, 5000)


                    return
                }
                if (data.isVerified) {
                    // submitToSignUpHandler();

                } else {
                    let id = uuidv4()
                    setErrorArray((prev) => {
                        return [...prev, { id: id, message: "Wrong Code Entered, please re-enter code again or resend code again", status: "failed" }];
                    });

                    setTimeout(() => {
                        if (!mountedRef?.current) return null
                        setErrorArray(prev => {
                            return prev.filter(x => x.id !== id);
                        });

                    }, 5000)

                    setAttempts(attempts - 1);
                }
            } catch (error) {
                let id = uuidv4()
                setErrorArray((prev) => {
                    return [...prev, { id: id, message: "Something went wrong please try again", status: "error" }];
                });

                setTimeout(() => {
                    if (!mountedRef?.current) return null
                    setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });

                }, 5000)


            }

        }

    }, [code, country]);

    const submitToSignUpHandler = () => {
        return submitToSignUp(temporalid);
    };
    /*
      useEffect(() => {
         if (attempts === 0) {
             setToggleFromSignUpToValidation(true);
             setUsername("");
          setCname("");
          setPhone("");
          setEmail("");
             setPassword("");
              setCpassword("");
          setReferralCode("");
        
         }
         
      }, [attempts]);
 */

    const timerout = useRef(null)
    useEffect(() => {
        // useRef value stored in .current property
        timerout.current = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        if (timer === 0) {
            setToggleFromSignUpToValidation(true);
        }
        // clear on component unmount
        return () => {
            clearInterval(timerout.current);
        };
    }, [timer]);


    const sendSMSFunctionHandler = () => {
        sendSMSFunction();
        setTimer(600);
    };

    return (
        <div className="signin">
            <div className="message-form">
                {loading && <LoadingBox></LoadingBox>}
                {errorArray.length > 0 && errorArray.map((error, index) =>
                    <Alert key={uuidv4()} onClose={() => { removeErrorMessage(error, index) }} variant="filled" severity={error.status === "failed" ? "warning" : error.status === "successful" ? "success" : error.status === "error" ? "error" : "info"}>{error.message}</Alert>
                )}

                {/*
                    {usernameMessage && <MessageBox variant="error"> {usernameMessage}</MessageBox>}
                    */
                }
            </div>
            <div className="signin_portal">

                <img src={domainDetails.logo} alt="logo" ></img>
                <p>{`${domainDetails.companyname} SMS platform`}</p>
            </div>
            <form>
                <h3>Validate Mobile Number</h3>

                <input className="form-control" autoComplete="off" type="text" id="code" value={code} placeholder="Enter code received on your phone" required onChange={e => setCode(e.target.value)} />

            </form>

            <div className="resettimer">
                <button onClick={sendSMSFunctionHandler} className="resend">Resend Code</button>

                <div className="timer">Timer : {`${Math.floor(timer / 60) < 1 ? 0 : Math.floor(timer / 60)}min  ${timer % 60}sec`} </div>
            </div>
        </div>

    )
}
