import axios from "axios";
import {FETCH_PURCHASED_CREDIT_HISOTRY_REQUEST, FETCH_PURCHASED_CREDIT_HISOTRY_SUCCESS , FETCH_PURCHASED_CREDIT_HISOTRY_FAIL ,FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST,  FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS, FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL} from "../constants/historypurchasedbulksmsConstants";

 
// lazy load data
export const purchasehistory = (search, pageNum) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type: FETCH_PURCHASED_CREDIT_HISOTRY_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/purchasehistory', {profile_id: userInfo.profile_id, search, pageNum},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
          dispatch({ type:FETCH_PURCHASED_CREDIT_HISOTRY_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: FETCH_PURCHASED_CREDIT_HISOTRY_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};

/*
export const ALLpurchasehistoryicsms = ( ) => async (dispatch, getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type: FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/ALLpurchasehistoryicsms', { profile_id: userInfo.profile_id}, { 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
        dispatch({ type:FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};
*/

export const allpurchasehistory = (search, pageNum) => async (dispatch, getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type: FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/allpurchasehistory', {profile_id: userInfo.profile_id,  search, pageNum}, { 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
        dispatch({ type:FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};

