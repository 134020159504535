import React from 'react';


export default function SidebarLink(props) {

  const { Icon, setActiveTab, activeTab, link } = props;

  const onClickHandler = () => {
    setActiveTab(link);
  };

  return(
    <div className="link" onClick={onClickHandler} style={{
             backgroundColor: activeTab === link && "rgb(126, 164, 247)",
             color: activeTab === link && "rgb(15, 15, 56)"
        }}>
        <Icon color="action" sx={{fontSize:10}}/>
        <div>{props.children}</div>
    </div>
  );
}
