
export const RemoveErrorMessage = (error, index, setErrorArray, dispatch, dispatchtype) => {
   
    setErrorArray(prev => {
        return prev.filter((e, i) => i !== index);
    });

    dispatchtype.forEach(type => {
        dispatch(type);
    });
   
};