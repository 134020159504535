import './LandingScreen.css';

import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
export default function LandingScreen(props) {

  useEffect(() => {
      if(window.location.pathname !=="/"){
        window.location.href = "/";
      }
  }, []);

  return (
    <>
        <div class="landing">
          <nav class="navbar navbar-default navbar-fixed-top">
            <div class="container">
              <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <p> 
                <a class="navbar-brand company-name" href="/">
                <img src="/smsgateway/api/frontend/images/logo-6.jpeg" alt=" icsmslogo" loading="lazy" title="ic logo"/>
              Integrity Comfort SMS (ICSMS)
              </a>
              </p>
              <div class="collapse navbar-collapse" id="myNavbar">
                <ul class="nav navbar-nav navbar-right">

                  <li><a href="https://icsms.net/bulksms/#home">Home</a></li>
                  <li class="li-nav" ><a href="https://icsms.net/bulksms/#pricing" target='_blank'  rel="noreferrer">Pricing</a></li>
                  <li class="li-nav" ><a href="https://icsms.net/bulksms/#contacts" target='_blank'  rel="noreferrer">Contacts</a></li>
                  <li class="li-nav"><a href="https://developer.icsms.net/" target='_blank'  rel="noreferrer">Developers</a></li>
                  <li class="li-nav"><Link to="/login">Login</Link></li>
                  <li class="li-nav"><Link to="/signup">Signup</Link></li>
                </ul>
              </div>
            </div>
          </nav>

          <div class="container landing" id="home">
            <div class="row">
              <div class="col-md-12">
                <div class="benefit">
                  <h1>Reliable BULK SMS and SMS GATEWAY Service</h1>
                  <h2 ><span>Send
                    free BulkSMS always. Click <a href="https://icsms.net/bulksms/#freesms">here</a> to learn how</span></h2>
                  <h2 >
                    <Link to="/signup"><span >Sign Up </span></Link>
                    for your free ten(10) SMS credit to test our system </h2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">

                <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="0">
                  <ol class="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>

                  </ol>

                  <div class="carousel-inner">
                    <div class="item active">

                      <img class="img-responsive" src="/smsgateway/api/frontend/images/scrol2-min.jpg" alt="keep customers smilling with online sms" loading="lazy" title="Keep the smiles"/>
                      <div class="carousel-caption">

                        <h2>Keep the smiles</h2>
                      </div>

                    </div>

                    <div class="item">
                      <img class="img-responsive" src="/smsgateway/api/frontend/images/scrol4.jpg" alt="engage customers with online sms" loading="lazy" title="Keep the smiles" />
                      <div class="carousel-caption">

                        <h2>Engage your customers</h2>

                      </div>

                    </div>
                  </div>

                  <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="right carousel-control" href="#myCarousel" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="pricing" id="pricing">
                <div class="col-md-6">
                  <h2>Pricing for REGULAR account</h2>
                  <table class="table-bordered">
                    <thead>
                      <tr>
                        <th>Price(GHS)</th>
                        <th>SMS Credits</th>
                        <th>Rate</th>
                        <th>Expiry</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>20</td>
                        <td>690</td>
                        <td>0.029 GHS per SMS</td>
                        <td>3 months</td>
                      </tr>
                      <tr>
                        <td>50</td>
                        <td>1,799</td>
                        <td>0.0278 GHS per SMS</td>
                        <td>3 months</td>

                      </tr>
                      <tr>
                        <td>100</td>
                        <td>3,760</td>
                        <td>0.0266 GHS per SMS</td>
                        <td>3 months</td>
                      </tr>
                      <tr>
                        <td>200</td>
                        <td>7,875</td>
                        <td>0.0254 GHS per SMS</td>
                        <td>3 months</td>
                      </tr>
                      <tr>
                        <td>500</td>
                        <td>20,662</td>
                        <td>0.0242 GHS per SMS</td>
                        <td>3 months</td>
                      </tr>
                      <tr>
                        <td>1000</td>
                        <td>43,479</td>
                        <td>0.0230 GHS per SMS</td>
                        <td>3 months</td>
                      </tr>
                      <tr>
                        <td>2000 or more</td>
                        <td>91,744 or more</td>
                        <td>0.0218 GHS per SMS</td>
                        <td>3 months</td>
                      </tr>

                    </tbody>
                  </table>

                </div>

                <div class="col-sm-6">
                  <h2>Pricing for PREMIUM account</h2>
                  <table class="table-bordered">
                    <thead>
                      <tr>
                        <th>Price(GHS)</th>
                        <th>SMS Credits</th>
                        <th>Rate</th>
                        <th>Expiry</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>20</td>
                        <td>589</td>
                        <td>0.0340 GHS per SMS</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>50</td>
                        <td>1,525</td>
                        <td>0.0328 GHS per SMS</td>
                        <td>1 year</td>

                      </tr>
                      <tr>
                        <td>100</td>
                        <td>3,165</td>
                        <td>0.0316 GHS per SMS</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>200</td>
                        <td>6,579</td>
                        <td>0.0304 GHS per SMS</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>500</td>
                        <td>17,124</td>
                        <td>0.0292 GHS per SMS</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>1000</td>
                        <td>35,715</td>
                        <td>0.0280 GHS per SMS</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>2000 or more</td>
                        <td>74,627</td>
                        <td>0.0268 GHS per SMS</td>
                        <td>1 year</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr class="my-1" />
            <div class="row">
              <div class="col-md-12">
                <div class="com" >
                  <h2> What you get with our Bulk SMS solution </h2>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="panel-head-icon">
                  <i class="fa fa-bullhorn"></i>
                </div>
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h2 class="panel-title">Reach out to your customers with a click</h2>
                  </div>
                  <div class="panel-body">

                    <p> Timely alert and notify your clients on pending, events, appointments, meeting etc. Send all your promotional and transactional messages using state of the art Bulk SMS platform. Excellent interface with easy navigation to provide
                      the perfect user experience. </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="panel-head-icon">
                  <i class="fa fa-mobile"></i>
                </div>
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h2 class="panel-title">Any time, Any Where, Any means</h2>
                  </div>
                  <div class="panel-body">
                    <p>Our Bulk SMS platform is carefully designed to work on all device. Send your messages on any device of your choice, anytime and at your convinience</p>
                  </div>
                </div>
              </div>



              <div class="col-md-4">
                <div class="panel-head-icon">
                  <i class="fa fa-money"></i>
                </div>
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h2 class="panel-title">Most affordable online SMS credits </h2>
                  </div>
                  <div class="panel-body">
                    <p>We provide you with competitive <a href="https://icsms.net/bulksms/#pricing">pricing</a> for your business. We are the cheapest and best Bulk SMS company in Ghana. With us you feel no pressure, so you can communicate what really matters to your customers
                    </p>

                  </div>

                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-md-4">
                <div class="panel-head-icon">
                  <i class="fa fa-credit-card"></i>
                </div>
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h2 class="panel-title">Ease of Purchase</h2>
                  </div>
                  <div class="panel-body">
                    <p> Purchase your SMS credit with Mobile Money (all Networks) or debit/credit card payment. Credit is immediately and automatically given upon completion of payment. Receive SMS alerts and Invoice (provided via email) on every transanction.
                    </p>

                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="panel-head-icon">
                  <i class="fa fa-microchip"></i>
                </div>
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h2 class="panel-title">Integrate with HTTP or API or SMPP into your app </h2>
                  </div>
                  <div class="panel-body">
                    <p> Developers and Resellers are welcome to integrate our Bulk SMS APIs into their applications. <a href="https://developer.icsms.net/" target="_blank"  rel="noreferrer">Click here to go to our developer page</a> We also provide SMPP connection</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="com">
                  <h2 > Features</h2>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="panel-head-icon">
                  <i class="fa fa-envelope"></i>
                </div>
                <div class="panel panel-warning">
                  <div class="panel-heading">
                    <h2 class="panel-title">Supports all type of Bulk messaging</h2>
                  </div>
                  <div class="panel-body">
                    <p>Our Bulk SMS platform allows you to send quick, scheduled or recurring  online SMS. You can also schedule birthday messages.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="panel-head-icon">
                  <i class="fa fa-address-card"></i>
                </div>
                <div class="panel panel-warning">
                  <div class="panel-heading">
                    <h2 class="panel-title">Personalized SMS</h2>
                  </div>
                  <div class="panel-body">
                    <p>Personalized SMS is when Bulk SMS is composed and sent to numerous people but addresses each individual with his unique details(eg say his name). This feature is easily achieved on ICSMS Bulk SMS platform</p>
                  </div>
                </div>
              </div>





            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="panel-head-icon">
                  <i class="fa fa-address-book"></i>
                </div>
                <div class="panel panel-warning">
                  <div class="panel-heading">
                    <h2 class="panel-title"> Create Groups on our phone book</h2>
                  </div>
                  <div class="panel-body">
                    <p>Our bulk sms platform comes with a phone book feature where you can create multiple groups and populate each group with contact details of members of the group. This feature allows you to send messages to members of the group with
                      just the selection of the group name. You can scheduled birthday messages to all members of a group with just a click.
                    </p>

                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="panel-head-icon">
                  <i class="fa fa-file"></i>
                </div>
                <div class="panel panel-warning">
                  <div class="panel-heading">
                    <h2 class="panel-title">Message Report</h2>
                  </div>
                  <div class="panel-body">
                    <p> know the statuses (whether delivered, failed or pending) of each message sent immediately on our message report page. The time of message delivery is also provided. All Reports own platform can be exported to excel.

                    </p>
                  </div>
                </div>
              </div>

            </div>

            <div id="freesms"></div>
            <hr class="my-2" />

            <div class="row free-container">
              <div class="col-md-12">
                <div class="com">
                  <h2 >Follow below steps to send free Bulk SMS always</h2>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 free-sms">

                <ol type="1">
                  <li><Link to="/signup">Signup</Link> (get 10 free SMS on signup) account on ICSMS Bulk SMS platform</li>
                  <li>Login to ICSMS Bulk SMS platform </li>
                  <li>Navigate to USER PROFILE page and copy referral code</li>
                  <li>Encourage individuals or companies to sign up using your referral code and receive ten(10) percent bonus SMS credits each time they purchase SMS</li>
                </ol>
                <div >Now you can send free Bulk SMS always with ICSMS {'\u{1F609}'}</div>
              </div>
            </div>

            <div class="row">
              <div class=" col-sm-12 ">
                <div class="contacts" id="contacts">
                  <img src="/smsgateway/api/frontend/images/iclogo.png" alt=" icsmslogo" loading="lazy" title="Integrity Comfort Limited" />
                  <h2>Contact Us</h2>
                  <h2>Integrity Comfort Ltd</h2>
                  <p> P.O Box 3185 Accra </p>
                  <p> info@icsms.net </p>
                  <p> Tel:+233503306205 </p>
                  <p> Location: Behind Focos Hospital, Oyarifa, Accra, Ghana</p>

                  <p>
                    <a href="https://web.facebook.com/Integrity-Comfort-Ltd-107660011041583">
                      <i class=" fa fa-facebook-official "></i><span>Follow us on facebook</span></a>
                  </p>

                </div>
              </div>

            </div>


            <div class=" navbar navbar-inverse navbar-fixed-buttom ">
              <div class=" navbar-text pull-left " id=" contacts ">
                <div class=" row ">
                  <div class=" col-sm-12 ">

                    <p>&copy; Integrity Comfort Ltd</p>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
    </>
  )
}