import { CREATE_QUICK_FAIL, CLEAN_QUICK_SUCCESS, CREATE_QUICK_REQUEST, DELETE_SMS_ERROR, CREATE_QUICK_SUCCESS, CREATE_RECURRING_FAIL, CREATE_RECURRING_REQUEST,  CREATE_RECURRING_SUCCESS,  CREATE_SCHEDULED_FAIL, CREATE_SCHEDULED_REQUEST, CREATE_SCHEDULED_SUCCESS, DELETE_RECURRING_FAIL, DELETE_RECURRING_REQUEST, DELETE_RECURRING_SUCCESS, DELETE_SCHEDULED_FAIL, DELETE_SCHEDULED_REQUEST, DELETE_SCHEDULED_SUCCESS, FETCH_ALL_QUICK_FAIL, FETCH_ALL_QUICK_REQUEST, FETCH_ALL_QUICK_SUCCESS, FETCH_ALL_RECURRING_FAIL, FETCH_ALL_RECURRING_REQUEST, FETCH_ALL_RECURRING_SUCCESS, FETCH_ALL_SCHEDULDED_FAIL, FETCH_ALL_SCHEDULED_REQUEST, FETCH_ALL_SCHEDULED_SUCCESS, FETCH_QUICK_FAIL, FETCH_QUICK_REQUEST, FETCH_QUICK_SUCCESS, FETCH_RECURRING_FAIL, FETCH_RECURRING_REQUEST, FETCH_RECURRING_SUCCESS, FETCH_SCHEDULED_FAIL, FETCH_SCHEDULED_REQUEST, FETCH_SCHEDULED_SUCCESS, UPDATE_RECURRING_FAIL, UPDATE_RECURRING_REQUEST, UPDATE_RECURRING_SUCCESS, UPDATE_SCHEDULED_FAIL, UPDATE_SCHEDULED_REQUEST, UPDATE_SCHEDULED_SUCCESS, CLEAN_ALL_QUICK_SUCCESS, CLEAN_ALL_SCHEDULED_SUCCESS, CLEAN_SCHEDULED_SUCCESS, CLEAN_ALL_RECURRING_SUCCESS, CLEAN_RECURRING_SUCCESS, UPDATE_QUICK_REQUEST, UPDATE_QUICK_FAIL, UPDATE_QUICK_SUCCESS, ALL_RECURRING_SEARCH, ALL_RECURRING_PAGENUM, RECURRING_SEARCH, RECURRING_PAGENUM, ALL_SCHEDULED_SEARCH, ALL_SCHEDULED_PAGENUM, SCHEDULED_SEARCH, SCHEDULED_PAGENUM, ALL_QUICK_SEARCH, ALL_QUICK_PAGENUM, QUICK_SEARCH, QUICK_PAGENUM, QUICK_VISITED, ALL_QUICK_VISITED, SCHEDULED_VISITED, ALL_SCHEDULED_VISITED, RECURRING_VISITED, ALL_RECURRING_VISITED, UPDATE_MESSAGE_STATUS_ALL_BULKSMS, UPDATE_MESSAGE_STATUS } from "../constants/bulksmsConstants";
 
import { USER_SIGNOUT } from "../constants/userConstants";

export const bulksmsReducer = (state = {  }, action) => {


    switch (action.type) {
        //requests
       
        case FETCH_QUICK_REQUEST:
        case CREATE_QUICK_REQUEST:
        case DELETE_RECURRING_REQUEST:
        case UPDATE_RECURRING_REQUEST:
        case CREATE_RECURRING_REQUEST:
        case FETCH_RECURRING_REQUEST:
        case FETCH_ALL_RECURRING_REQUEST:
        case FETCH_ALL_QUICK_REQUEST:
        case FETCH_ALL_SCHEDULED_REQUEST:
        case CREATE_SCHEDULED_REQUEST:
        case UPDATE_SCHEDULED_REQUEST:
        case DELETE_SCHEDULED_REQUEST:
        case FETCH_SCHEDULED_REQUEST:
        case UPDATE_QUICK_REQUEST:
            return {...state, loading: true};

        //failed
        case FETCH_ALL_QUICK_FAIL:
        case DELETE_RECURRING_FAIL:
        case UPDATE_RECURRING_FAIL:
        case CREATE_RECURRING_FAIL:
        case FETCH_ALL_RECURRING_FAIL:
        case FETCH_RECURRING_FAIL:
        case UPDATE_SCHEDULED_FAIL:
        case DELETE_SCHEDULED_FAIL:
        case CREATE_QUICK_FAIL:
        case FETCH_QUICK_FAIL:
        case FETCH_SCHEDULED_FAIL:
        case FETCH_ALL_SCHEDULDED_FAIL:
        case CREATE_SCHEDULED_FAIL:
        case UPDATE_QUICK_FAIL:
            return { ...state, loading: false, error: action.payload  };
        
        //qucik fetch success
        case FETCH_ALL_QUICK_SUCCESS:
        case FETCH_QUICK_SUCCESS:
        case CREATE_QUICK_SUCCESS:
            return { ...state, loading: false, error: action.payload.error, bulksms: action.payload.bulksms ? [ ...state.bulksms,...action.payload.bulksms]: [...state.bulksms] , allbulksms: action.payload.allbulksms ? [...state.allbulksms,...action.payload.allbulksms ]: [...state.allbulksms]};

       
        //sceduled fetch success
        case FETCH_SCHEDULED_SUCCESS:
        case FETCH_ALL_SCHEDULED_SUCCESS:
        case CREATE_SCHEDULED_SUCCESS:
            return { ...state, loading: false, error: action.payload.error, scheduled: action.payload.scheduled ? [...state.scheduled, ...action.payload.scheduled ]: [...state.scheduled],  allscheduled: action.payload.allscheduled ? [ ...state.allscheduled,...action.payload.allscheduled] :[...state.allscheduled]};

        //quick sms update sucess
        case UPDATE_QUICK_SUCCESS:
                    return { ...state, loading: false, error: action.payload.error, bulksms: [...state.bulksms.filter(x => x.message_id !== action.payload.bulksms.message_id), action.payload.bulksms]};

        //sceduled  update success
        case UPDATE_SCHEDULED_SUCCESS:
            return { ...state, loading: false, error: action.payload.error, scheduled: [...state.scheduled.filter(x => x.message_id !== action.payload.scheduled.message_id), action.payload.scheduled] };

        //delete scheduled success
        case DELETE_SCHEDULED_SUCCESS:
            return { ...state, loading: false, error: action.payload.error , scheduled:  [...state.scheduled.filter(x => x.message_id !== action.payload.scheduled.message_id)]};
        
        //recurring fetch success
        case FETCH_ALL_RECURRING_SUCCESS:
        case FETCH_RECURRING_SUCCESS:
         case CREATE_RECURRING_SUCCESS:
            return { ...state, loading: false, error: action.payload.error,  recurring: action.payload.recurring ? [...state.recurring,...action.payload.recurring ]:[...state.recurring], allrecurring: action.payload.allrecurring ? [...state.allrecurring,...action.payload.allrecurring]:[...state.allrecurring]};

        //recurring  update success
        case UPDATE_RECURRING_SUCCESS:
           return { ...state, loading: false,error: action.payload.error, recurring:  [...state.recurring.filter(x => x.message_id !== action.payload.recurring.message_id), action.payload.recurring]};

        //recurring  delete success
        case DELETE_RECURRING_SUCCESS:
           return { ...state, loading: false,error: action.payload.error , recurring:  [...state.recurring.filter(x => x.message_id !== action.payload.recurring.message_id)] };
        case DELETE_SMS_ERROR:
            return { ...state, loading: false, error: null };
        case CLEAN_QUICK_SUCCESS:
            return { ...state, bulksms: [] };
         case CLEAN_ALL_QUICK_SUCCESS:
            return { ...state, allbulksms: [] };
        case CLEAN_ALL_SCHEDULED_SUCCESS:
            return { ...state, allscheduled: [] };
        case CLEAN_SCHEDULED_SUCCESS:
            return { ...state, scheduled: [] };
         case CLEAN_ALL_RECURRING_SUCCESS:
            return { ...state, allrecurring: [] };
        case CLEAN_RECURRING_SUCCESS:
             return { ...state, recurring:  [] };
        case QUICK_PAGENUM:
            return { ...state, bulksearch:{ ...state.bulksearch, pageNum:action.payload }}; 
        case QUICK_SEARCH:
            return { ...state, bulksearch:{ ...state.bulksearch, search:action.payload }}; 
        case ALL_QUICK_PAGENUM:
            return { ...state, allbulksearch:{ ...state.allbulksearch, pageNum:action.payload }};
        case ALL_QUICK_SEARCH:
            return { ...state, allbulksearch:{ ...state.allbulksearch, search:action.payload }};  
        case SCHEDULED_PAGENUM:
            return { ...state, scheduledsearch:{ ...state.scheduledsearch, pageNum:action.payload }}; 
        case SCHEDULED_SEARCH:
            return { ...state, scheduledsearch:{ ...state.scheduledsearch, search:action.payload }}; 
        case ALL_SCHEDULED_PAGENUM:
            return { ...state, allscheduleddearch:{ ...state.allscheduleddearch, pageNum:action.payload }}; 
        case ALL_SCHEDULED_SEARCH:
            return { ...state, allscheduleddearch:{ ...state.allscheduleddearch, search:action.payload }}; 
        case RECURRING_PAGENUM:
            return { ...state, recurringsearch:{ ...state.recurringsearch, pageNum:action.payload }}; 
        case RECURRING_SEARCH:
            return { ...state, recurringsearch:{ ...state.recurringsearch, search:action.payload }};     
        case ALL_RECURRING_PAGENUM:
            return { ...state, bulksearch:{ ...state.allrecurringsearch, pageNum:action.payload }}; 
        case ALL_RECURRING_SEARCH:
            return { ...state, allrecurringsearch:{ ...state.allrecurringsearch, search:action.payload }}; 

        case QUICK_VISITED:
            return { ...state, bulksearch:{ ...state.bulksearch, visited:action.payload }}; 
        case ALL_QUICK_VISITED:
            return { ...state, allbulksearch:{ ...state.allbulksearch, visited:action.payload }};
        case SCHEDULED_VISITED:
            return { ...state, scheduledsearch:{ ...state.scheduledsearch, visited:action.payload }}; 
        case ALL_SCHEDULED_VISITED:
            return { ...state, allscheduleddearch:{ ...state.allscheduleddearch, visited:action.payload }};
        case RECURRING_VISITED:
            return { ...state, recurringsearch:{ ...state.recurringsearch, visited:action.payload }};     
        case ALL_RECURRING_VISITED:
            return { ...state, bulksearch:{ ...state.allrecurringsearch, visted:action.payload }}; 
        
        //update message status
        case UPDATE_MESSAGE_STATUS:
            return { ...state, bulksms: [...state.bulksms.filter(x => x.randomAlpha !== action.payload.randomAlpha), action.payload.updatedBulksms] }; 
        case UPDATE_MESSAGE_STATUS_ALL_BULKSMS:
            return { ...state, allbulksms: [...state.allbulksms.filter(x => x.randomAlpha !== action.payload.randomAlpha), action.payload.updatedBulksms] }; 
        
        case USER_SIGNOUT:
            return {  bulksms: [],allbulksms: [],scheduled: [], allscheduled: [],recurring: [],allrecurring:[], bulksearch:{pageNum:1,search:"", visited:false}, allbulksearch:{pageNum:1,search:"", visited:false}, scheduledsearch:{pageNum:1,search:"", visited:false},allscheduledsearch:{pageNum:1,search:"", visited:false},recurringsearch:{pageNum:1,search:"", visited:false}, allrecurringsearch:{pageNum:1,search:"", visited:false} };
        default:
            return state;
    }   

};
