import './LoginScreen.css';

import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Redirect, Link } from 'react-router-dom';

import LoginForm from '../../components/LoginForm';

export default function LoginScreen(props) {
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const [emailusername, setEmailUsername] = useState("");
    const [password, setPassword] = useState("");

    const user = useSelector(state => state.user);
    const { userInfo } = user;
    const { domainDetails } = useSelector(state => state.domainDetails);
    const s = {
        'background-image': `url(${domainDetails.backgroundImage})`,
        'background-size': 'cover',
        'background-repead': 'no-repeat',
        'background-position': 'center',
        'height': '100vh' 
    }
    const [style, setStyle] = useState(s);

    function formatExpiry(expiry) {
        // Get the whole number part of the expiry value
        let years = Math.floor(expiry);
        // Get the fractional part and convert it to months
        let months = Math.round((expiry - years) * 12);
    
        // Handle the case where expiry is a simple fractional year
        if (years === 0 && months === 3) {
            return "3 months";
        } else if (years === 1 && months === 3) {
            return "1 year 3 months";
        } else {
            // General case formatting
            let result = "";
            if (years > 0) {
                result += years + " year" + (years > 1 ? "s" : "");
            }
            if (months > 0) {
                if (result.length > 0) {
                    result += " ";
                }
                result += months + " month" + (months > 1 ? "s" : "");
            }
            return result;
        }
    }
    

    return (
        <>
            {!userInfo ? (
                <div className="login-container"
                    style={domainDetails.backgroundImage ? style : {}}
                > 
                    {/*
                    domainDetails && domainDetails.profile_id === 6 && (
                        <div className='home-links'>
                            <Link to="/"><div>Home Page</div></Link>
                            <a href="https://developer.icsms.net"  target='_blank'  rel="noreferrer"><div>SMS APIs</div></a>
                        </div>
                    )*/
                    }
                    <LoginForm
                        emailusername={emailusername}
                        setEmailUsername={setEmailUsername}
                        password={password}
                        setPassword={setPassword}
                    />
                    
                    
                    {domainDetails && domainDetails.profile_id !== 6 && JSON.parse(domainDetails.localrate)?.["REGULAR"] &&
                    <div className="table1">
                        <table style={{ width: '100%', textAlign: 'center'}}>
                            <thead >
                                <tr>
                                    <th>Price(GHS)</th>
                                    <th>SMS Credits</th>
                                    <th>Rate</th>
                                    <th>Expiry</th>
                                </tr>
                            </thead>
                            <tbody>
                                {JSON.parse(domainDetails.localrate)["REGULAR"].sort((a, b) => a.price - b.price).map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.price}{(JSON.parse(domainDetails.localrate)["REGULAR"].length - 1) === i ? " or more" : ""}</td>
                                            <td>{numberWithCommas(Math.ceil(data.price / data.rate))}</td>
                                            <td>{data.rate} GHS per SMS</td>
                                            <td>{formatExpiry(data.expiry)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    }
                    
                </div>
            ) : (
                <Redirect to="/" />
            )}
        </>

    )
}
