import axios from "axios";
import { DOMAIN_FETCH_FAIL, DOMAIN_FETCH_REQUEST, DOMAIN_FETCH_SUCCESS } from "../constants/domainConstants";
import { getStoredAPIdata } from "../utils";

export const fetchDomainDetails = (domain) => async (dispatch) => {
    dispatch({ type: DOMAIN_FETCH_REQUEST }); 
    try {
        const url = `/smsgateway/api/frontend/domain?domain=${domain}`
        const response = getStoredAPIdata(url)
        if(response && response.domainDetails){
            dispatch({ type: DOMAIN_FETCH_SUCCESS, payload: response });
        }

       
        const { data } = await axios.get(url);
        
        dispatch({ type: DOMAIN_FETCH_SUCCESS, payload: data });

        let cache_url_key = url

        const cache = await caches.open(`${domain}-data-cache-v1`);
        let expiryDate = Date.now() + 1000 * 60 * 60 * 24 * 365 // cache for 1year
        const responseToCache = new Response(JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                'cache-expiration': expiryDate.toString()
            }
        });
        await cache.put(cache_url_key, responseToCache.clone());
       
    } catch (error) {
        dispatch({
            type: DOMAIN_FETCH_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};