import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { userReducer } from './reducers/userReducer';
import { bulksmsReducer } from './reducers/bulksmsReducer';
import { historypurchasedbulksmsReducer } from './reducers/historypurchasedbulksmsReducer';
import { groupsReducer } from './reducers/groupsReducer';
import { domainDetailsReducer } from './reducers/domainDetailsReducer';


const initialState = {
    user: {
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
       allusers:[],
       allStatus: [],
       clientPage: 0, 
    },
    bulksms: {
        bulksms: [],
        bulksearch:{pageNum:1,search:"", visited:false},
        allbulksms: [],
        allbulksearch:{pageNum:1,search:"", visited:false},
        scheduled: [],
        scheduledsearch:{pageNum:1,search:"", visited:false},
        allscheduled: [],
        allscheduleddearch:{pageNum:1,search:"", visited:false},
        recurring: [],
        recurringsearch:{pageNum:1,search:"", visited:false},
        allrecurring:[], 
        allrecurringsearch:{pageNum:1,search:"", visited:false},
    },
    historypurchasedsms: {
        credithistory: [],
        allcredithistory:[]
    },
    groups: {
        groups: [],
        allgroups:[]
    },
    domainDetails: {
        domainDetails: {},
    }
    
    
};
const reducer = combineReducers({
    user : userReducer,
    bulksms : bulksmsReducer,
    historypurchasedsms: historypurchasedbulksmsReducer,
    groups: groupsReducer,
    domainDetails: domainDetailsReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));  

export default store;