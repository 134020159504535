import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoadingBox from '../../components/LoadingBox';
import Alerts from '../../components/mainapp/Alerts';
import { DELETE_GROUP_ERROR } from '../../constants/groupsConstants';
import { v4 as uuidv4 } from 'uuid';
import SearchField from "react-search-field";
import ContactCard from '../../components/ContactCard';
import { Redirect} from 'react-router-dom';
import { allgroupsx } from '../../actions/groupsAction';



export default function PhoneBookDetailsClientScreen(props) {
  const [errorArray, setErrorArray] = useState([]);
  const [search, setSearch] = useState('');
  const [contacts, setContacts] = useState([]);
  //const [group, setGroup] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const mountedRef = useRef(true)

  const { allgroups, error, loading } = useSelector(state => state.groups);
  const group = allgroups.filter(x => x.id === Number(props.match.params.id));
  if (group) {
    // console.log( group[0].contacts);
    group[0].contacts.sort((a, b) => b.id - a.id);

    for (var i = 0; i < group[0].contacts.length; i++) {
      group[0].contacts[i].birthday = group[0].contacts[i].birthday ? group[0].contacts[i].birthday.split(" ")[0] : "";
    }

  }

  const dispatch = useDispatch();



  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
        if(!mountedRef?.current) return null
          setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
          });  
            

      }, 5000)
      
    }
  }, [error]);

  useEffect(() => {
    if(group){
      dispatch(allgroupsx());
    }
  }, []);


  useEffect(() => {
    setErrorArray([]);
    return () => {

      dispatch({ type: DELETE_GROUP_ERROR });
      mountedRef.current = false
    };

  }, []);


  useEffect(() => {
    if(group.length){
      setContacts(group[0].contacts);
    }
    if (search) {
    let newContacts= group[0].contacts.filter(x => {
        for (const [key, value] of Object.entries(x)) {
          if (key !== "id") {
            if (key !== "referencekey") {
              if (value.toString().toLowerCase().includes(search.toLowerCase())) {
                return true
              }
            }
          }
        };
        return false
      })
      setContacts(newContacts);
    }
  }, [search]);


  const onChangeHandler = (val) => {
    setSearch(val);
  };

  const goBack = () => {
    window.history.back();
  };
 

  return (
    <>
  
      <div className="back">
        <button onClick={goBack}>Back</button>
      </div>
      {loading && <LoadingBox></LoadingBox>}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />
      
      <div className="search">
        <SearchField
          placeholder="Search..."
          onChange={onChangeHandler}
          searchText=""
          classNames="test-class"
        />
      </div>
      {group.length > 0 &&
    <>
      <div className="phonebook-contacts">
      <div className="phonebook-contacts-headtitle">Contacts of {group[0].groupname} </div>
        
        <div className="contacts-container">
        {contacts.map(contact => (
            <ContactCard key={contact.id}  groupid={group[0].id} contact={contact}  showEdit={showEdit} />
          ))}
        </div>
      </div>
      </>}
    </>
  )
}
