import React, { useState } from 'react';
import { BrowserRouter,  Switch , Route } from 'react-router-dom';

import LocalPhoneBookScreen from '../localphonebook/LocalPhoneBookScreen';
import ClientPhoneBookScreen from '../clientphonebook/ClientPhoneBookScreen';


import ScreenTabs from '../../components/ScreenTabs';
import PhoneBookDetailsScreen from '../PhoneBookDetailsScreen/PhoneBookDetailsScreen';
import PhoneBookDetailsClientScreen from '../PhoneBookDetailsScreen/PhoneBookDetailsClientScreen';


export default function PhoneBookScreen(props) {

 const buttonText1 = "P";
    const tabLink1 = "/phonebook";
    const buttonText2 = "C";
    const tabLink2 = "/clientphonebook";

    const activeBtn  = props.location.pathname === "/clientphonebook" ? buttonText2 : buttonText1;

    const [activeTab, setActiveTab] = useState(activeBtn );

  const toggleActiveTab = () => activeTab === buttonText1 ?
        setActiveTab(buttonText2) :setActiveTab(buttonText1);
    const onClickHandler = (e) => {
          e.preventDefault();
        toggleActiveTab();
    };
 

    return (
           <BrowserRouter>
           
            <div className="phonebook">
                <ScreenTabs buttonText1={buttonText1} buttonText2={buttonText2} tabLink1={tabLink1} tabLink2={tabLink2} activeTab={activeTab} onClickHandler={onClickHandler} />
                {/*search bar */}
          <Switch>
               <Route path="/clientphonebook/:id" component={PhoneBookDetailsClientScreen} ></Route>
                  <Route path="/phonebook/:id" component={PhoneBookDetailsScreen} ></Route>
                   <Route path="/clientphonebook" component={ClientPhoneBookScreen} ></Route>
                   <Route path="/phonebook" component={LocalPhoneBookScreen} ></Route>
                 </Switch>      
            </div>
             
        </BrowserRouter>
    )
}
