import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingBox from '../../components/LoadingBox';
import RecurringSMS from '../../components/RecurringSMS';
//import 'animate.css/animate.min.css';
import SearchField from "react-search-field";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ALL_RECURRING_PAGENUM, ALL_RECURRING_SEARCH, ALL_RECURRING_VISITED, CLEAN_ALL_RECURRING_SUCCESS, DELETE_SMS_ERROR } from '../../constants/bulksmsConstants';
import { v4 as uuidv4 } from 'uuid';

import { allrecurringx } from '../../actions/bulksmsAction';
import Alerts from '../../components/mainapp/Alerts';


export default function ClientRecurringScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const { allrecurring, allrecurringsearch, error, loading } = useSelector(state => state.bulksms);
  const { pageNum, search, visited } = allrecurringsearch;
  const mountedRef = useRef(true)

  //remove duplicates
  const key = 'message_id';
  const uniqueallrecurring = [...new Map(allrecurring.map(item => [item[key], item])).values()];
  uniqueallrecurring.sort((a, b) => b.message_id - a.message_id);



  const dispatch = useDispatch();
  

  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
    
      setTimeout(() => {
         if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
            

      }, 20000)

    }
  }, [error]);


  useEffect(() => {
    if (!visited) {
      dispatch(allrecurringx(search, pageNum));
      dispatch({ type: ALL_RECURRING_VISITED, payload: true });
    }
  }, []);

  useEffect(() => {
    setErrorArray([]);
    return () => {
      //dispatch({ type: CLEAN_ALL_RECURRING_SUCCESS });
      mountedRef.current = false
      dispatch({ type: DELETE_SMS_ERROR });
    };

  }, []);

  const fetchData = () => {
    dispatch({ type: ALL_RECURRING_PAGENUM, payload: pageNum + 1 });
    let increaesdPageNum = pageNum + 1;
    dispatch(allrecurringx(search, increaesdPageNum));
  };


  const onChangeHandler = (val) => {
    dispatch({ type: CLEAN_ALL_RECURRING_SUCCESS });
    dispatch({ type: ALL_RECURRING_SEARCH, payload: val });
    dispatch({ type: ALL_RECURRING_PAGENUM, payload: 1 });
    dispatch(allrecurringx(search, pageNum));
  };
  let val = search ? search : "";
  return (
    <>
      {/*loading && <LoadingBox></LoadingBox>*/}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_SMS_ERROR }]} />

      <div className="search">
        <SearchField
          placeholder="Search..."
          onEnter={onChangeHandler}
          searchText={val}
          classNames="test-class"
        />
      </div>


      <div
        id="scrollableDiv"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          width:'100%',

        }}
      >

        {uniqueallrecurring ? (


          <InfiniteScroll
            dataLength={uniqueallrecurring.length}
            next={fetchData}
            loader={loading && <LoadingBox></LoadingBox>}
            hasMore={true}
            scrollableTarget="scrollableDiv"

            endMessage={
              <p style={{ textAlign: 'center' }} >
                <b>Data fetch completed</b>
              </p>
            }
          >
            {uniqueallrecurring.map((rs) => {
              return (
                <RecurringSMS key={rs.message_id} rs={rs} setErrorArray={setErrorArray} personal={false} />
              )
            })}

          </InfiniteScroll>

        ) : (<></>)

        }
      </div>


    </>
  )
}


