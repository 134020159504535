import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingBox from './LoadingBox';
import { Alert, Button, Modal } from '@mui/material';
import Alerts from '../components/mainapp/Alerts';
import { login } from '../actions/userAction';
import { DELETE_USER_ERROR } from '../constants/userConstants';
import axios from 'axios';
//import { Turnstile } from '@marsidev/react-turnstile';


export default function LoginForm(props) {
    const user = useSelector(state => state.user);
    const { error, loading } = user;
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [errorArray, setErrorArray] = useState([]);
    const [humanKey, setHumanKey] = useState("");
    const { domainDetails } = useSelector(state => state.domainDetails);
    const mountedRefp = useRef(true)

    const {
        emailusername,
        setEmailUsername,
        password,
        setPassword
    } = props;



    useEffect(() => {
        if (error) {
            let id = uuidv4()
            error.id = id;
            setErrorArray((prev) => {
                return [...prev, error];
            });

            setTimeout(() => {
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });
            }, 5000)

        }
    }, [error]);


    useEffect(() => {
        //focus on username field
        inputRef.current.focus();
        return () => {
            mountedRefp.current = false
        }
    }, []);

    const onChangeRecaptch = (value) => {
        setHumanKey(value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setErrorArray([]);
        if(domainDetails){
            dispatch(login(emailusername, password, domainDetails.profile_id, humanKey));
        }else{
            let id = uuidv4();
            setErrorArray((prev) => {
                return [...prev, { id: id, message: "Please wait for while and try again", status: "error" }];
            });
            setTimeout(() => {
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });
            }, 5000)

        }
        
    };
    const removeErrorMessage = (el, index) => {
        setErrorArray(prev => {
            return prev.filter((e, i) => i !== index);
        });
        dispatch({ type: DELETE_USER_ERROR });
    };

    return (

        <div className="login">
            <div className="message-form">
                {loading && <LoadingBox></LoadingBox>}
                {errorArray.length > 0 && errorArray.map((error, index) =>
                    <Alert key={uuidv4()} onClose={() => { removeErrorMessage(error, index) }} variant="filled" severity={error.status === "failed" ? "warning" : error.status === "successful" ? "success" : error.status === "error" ? "error" : "info"}>{error.message}</Alert>
                )}
            </div>
            <div className="login_portal">

                <img src={domainDetails.logo} alt="logo" ></img>
                <p>{`${domainDetails.companyname} SMS platform`}</p>
            </div>
            <form onSubmit={submitHandler}>
                <h2>Login</h2>

                <input className="form-control" autoComplete="off" ref={inputRef} type="text" id="emailusername" value={emailusername} placeholder="Enter email or username" required onChange={e => setEmailUsername(e.target.value)} />
                <input className="form-control" autoComplete="off" type="password" id="password" value={password} placeholder="Enter password" required onChange={e => setPassword(e.target.value)} />

             
                <div className="form-group">
                    {/*
                    <ReCAPTCHA sitekey={domainDetails.recaptcha_site_key} onChange={onChangeRecaptch} />        
                    <Turnstile siteKey={domainDetails.recaptcha_site_key} onSuccess={setHumanKey} />
                    */}
                </div>
                <button disabled={loading} type="submit">Login</button>
                <div className="login_link">
                    New customer? {' '} <Link to={'/signup'}>Create an account</Link>
                </div>
                <div className="forgot-password">
                    <div>Forgot password? </div> <PasswordResetRequest mountedRefp={mountedRefp} domainDetails={domainDetails} setErrorArray={setErrorArray} />
                </div>
            </form>
        </div>

    )
}


export const PasswordResetRequest = (props) => {
    const [open, setOpen] = useState(false);
    const [errorArrayp, setErrorArrayp] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("")
    const [resetCode, setResetCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [toggleResetForm, setToggleResetForm] = useState(false);
    const [attempts, setAttempts] = useState(3);
    const mountedRef = useRef(true)

    useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])


    const handleUpdate = async () => {
        if (resetCode.length === 0) {
            let id = uuidv4()
            setErrorArrayp(prev => {
                return [...prev, { id: id, message: "Please enter code sent to your phone", status: "failed" }]
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null;
                setErrorArrayp(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

            return
        }
        if (newPassword.length < 6) {
            let id = uuidv4()
            setErrorArrayp(prev => {
                return [...prev, { id: id, message: "Password must be more than six characters", status: "failed" }]
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArrayp(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

            return
        }
        if (newPassword !== confirmNewPassword) {
            let id = uuidv4()
            setErrorArrayp(prev => {
                return [...prev, { id: id, message: "Please ensure passwords the are same", status: "failed" }];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArrayp(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

            return
        }
        try {
            const { data } = await axios.post('/smsgateway/api/frontend/changepassword', { resetCode, newPassword, confirmNewPassword });
            if (data.passwordchanged) {
                let id = uuidv4()
                data.error.id = id;
                props.setErrorArray((prev) => {
                    return [...prev, data.error];
                });

                setTimeout(() => {
                    if (!props.mountedRefp.current) return null

                    props.setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });

                }, 5000)

                handleClose();

                setToggleResetForm(false)
                setConfirmNewPassword("")
                setNewPassword("")
                setResetCode("")
            } else {
                let id = uuidv4()
                data.error.id = id;
                props.setErrorArray((prev) => {
                    return [...prev, data.error];
                });

                setTimeout(() => {
                    if (!props.mountedRefp.current) return null
                    props.setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });

                }, 5000)

            }

        } catch (error) {
            let id = uuidv4()
            setErrorArrayp(prev => {
                return [...prev, { id: id, message: "Something went wrong please try again", status: "error" }];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArrayp(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

        }


    }
    const handleReset = async () => {
        setAttempts(prev => prev - 1);
        if (attempts === 0) {
            handleClose();
            setToggleResetForm(false)
            setEmail("")
            setNewPassword("")
            setConfirmNewPassword("")
            setResetCode("")
            return
        }

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email.toLowerCase())) {
            let id = uuidv4()
            setErrorArrayp((prev) => {
                return [...prev, { id: id, message: "Please enter a valid email", status: "error" }];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArrayp(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

            return
        }
        try {
            const { data } = await axios.post('/smsgateway/api/frontend/setpasswordreset', { email, supid: props.domainDetails.profile_id });

            if (data.passcodeSet) {
                let id = uuidv4()
                data.error.id = id;
                setErrorArrayp((prev) => {
                    return [...prev, data.error];
                });

                setTimeout(() => {
                    if (!mountedRef?.current) return null
                    setErrorArrayp(prev => {
                        return prev.filter(x => x.id !== id);
                    });

                }, 5000)

                // toggle reset forms
                setToggleResetForm(true)
            } else {
                let id = uuidv4()
                data.error.id = id;
                setErrorArrayp((prev) => {
                    return [...prev, data.error];
                });

                setTimeout(() => {
                    if (!mountedRef?.current) return null
                    setErrorArrayp(prev => {
                        return prev.filter(x => x.id !== id);
                    });

                }, 5000)

            }
        } catch (error) {
            let id = uuidv4()
            setErrorArrayp((prev) => {
                return [...prev, { id: id, message: "Something went wrong please try again", status: "error" }];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArrayp(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)

        }
    }

    return (
        <>
            <div onClick={handleOpen}> reset password</div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                    <Alerts errorArray={errorArrayp} setErrorArray={setErrorArrayp} dispatch={dispatch} dispatchtype={[{ type: DELETE_USER_ERROR }]} />

                    {toggleResetForm ? (
                        <form>
                            <h2>Change password</h2>
                            <input className="form-control" autoComplete="off" type="text" value={resetCode} placeholder="Enter reset code" required onChange={e => setResetCode(e.target.value)} />
                            <input className="form-control" autoComplete="off" type="password" value={newPassword} placeholder="Enter new password" required onChange={e => setNewPassword(e.target.value)} />
                            <input className="form-control" autoComplete="off" type="password" value={confirmNewPassword} placeholder="Confirm new password" required onChange={e => setConfirmNewPassword(e.target.value)} />
                            <Button variant="contained" onClick={(e) => { e.preventDefault(); handleUpdate(); }}>Change password</Button>
                            <Button variant="contained" onClick={(e) => { e.preventDefault(); handleReset(); }}>Resend reset code </Button>
                        </form>
                    ) : (
                        <form>
                            <h2>Request password reset code</h2>
                            <input className="form-control" autoComplete="off" type="text" id="email" value={email} placeholder="Enter email" required onChange={e => setEmail(e.target.value)} />
                            <Button variant="contained" onClick={(e) => { e.preventDefault(); handleReset(); }}>Send reset code </Button>
                        </form>
                    )}
                </div>
            </Modal>
        </>
    );
}