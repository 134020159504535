import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingBox from '../../components/LoadingBox';
import SentSMS from '../../components/SentSMS';
//import 'animate.css/animate.min.css';
import SearchField from "react-search-field";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ALL_QUICK_PAGENUM, ALL_QUICK_SEARCH, ALL_QUICK_VISITED, CLEAN_ALL_QUICK_SUCCESS, DELETE_SMS_ERROR } from '../../constants/bulksmsConstants';
import { v4 as uuidv4 } from 'uuid';

import { allbulksmsx } from '../../actions/bulksmsAction';
import Alerts from '../../components/mainapp/Alerts';

export default function ClientDashboardScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const { allbulksms, allbulksearch, error, loading } = useSelector(state => state.bulksms);
  const { pageNum, search, visited } = allbulksearch;

  const key = 'message_id';
  const uniqueallbulksms = [...new Map(allbulksms.map(item => [item[key], item])).values()];
  uniqueallbulksms.sort((a, b) => b.message_id - a.message_id);

  const dispatch = useDispatch();
  const mountedRef = useRef(true)

 
  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
            
      }, 5000)
      
    }
  }, [error]);


  useEffect(() => {
    if (!visited) {
      dispatch(allbulksmsx(search, pageNum));
      dispatch({ type: ALL_QUICK_VISITED, payload: true });
    }
     return () => { 
      mountedRef.current = false
    }
  }, []);

  useEffect(() => {
    setErrorArray([]);
    return () => {
      // dispatch({ type: CLEAN_ALL_QUICK_SUCCESS });
      dispatch({ type: DELETE_SMS_ERROR });
    };

  }, []);

  const fetchData = () => {
    dispatch({ type: ALL_QUICK_PAGENUM, payload: pageNum + 1 });
    let increaesdPageNum = pageNum + 1;
    dispatch(allbulksmsx(search, increaesdPageNum));
  };

  const onChangeHandler = (val) => {
    dispatch({ type: CLEAN_ALL_QUICK_SUCCESS });
    dispatch({ type: ALL_QUICK_SEARCH, payload: val });
    dispatch({ type: ALL_QUICK_PAGENUM, payload: 1 });
    dispatch(allbulksmsx(search, pageNum));
  };
  let val = search ? search : "";

  return (
    <>
      {/*loading && <LoadingBox></LoadingBox>*/}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_SMS_ERROR }]} />

      <div className="search">
        <SearchField
          placeholder="Search..."
          onEnter={onChangeHandler}
          searchText={val}
          classNames="test-class"
        />
      </div>

      <div
        id="scrollableDiv"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
         width:'100%',
        }}
      >

        {uniqueallbulksms ? (


          <InfiniteScroll
            dataLength={uniqueallbulksms.length}
            next={fetchData}
            loader={loading && <LoadingBox></LoadingBox>}
            hasMore={true}
            scrollableTarget="scrollableDiv"

            endMessage={
              <p style={{ textAlign: 'center' }} >
                <b>Data fetch completed</b>
              </p>
            }
          >
            {uniqueallbulksms.map((bs, i) => {
              return (
                <SentSMS key={bs.message_id} index={i} bs={bs} />
              )
            })}

          </InfiniteScroll>

        ) : (<></>)

        }
      </div>
    </>
  )
}


