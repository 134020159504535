import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Alerts from '../../components/mainapp/Alerts';
import LoadingBox from '../../components/LoadingBox';
import SearchField from "react-search-field";
import { DELETE_CREDIT_ERROR } from '../../constants/historypurchasedbulksmsConstants';
import { purchasehistory } from '../../actions/historypurchsedbulksmsAction';
import { v4 as uuidv4 } from 'uuid';
import PaymentHistory from '../../components/PaymentHistory';


export default function LocalPurchasesmsScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const { credithistory, error, loading } = useSelector(state => state.historypurchasedsms);

  const dispatch = useDispatch();
  const mountedRef = useRef(true)
  


  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
    
     setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
          });
              

      }, 5000)
      
    }
  }, [error]);


  useEffect(() => {
    dispatch(purchasehistory(search, pageNum))
    setErrorArray([]);
    return () => {
      mountedRef.current = false
      dispatch({ type: DELETE_CREDIT_ERROR });
    };
  }, [search, pageNum]);

   const onChangeHandler = (val) => {
    setSearch(val);
  };

    const fetchData = () => {
    setPageNum(prev =>{
      return prev + 1
    })
  };

  return (
    <>
      {loading && <LoadingBox></LoadingBox>}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_CREDIT_ERROR }]} />
      <div className="search">
        <SearchField
          placeholder="Search..."
          onChange={onChangeHandler}
          searchText=""
          classNames="test-class"
        />
      </div>
       <div
        id="scrollableDivLH"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          width: '100%',

        }}
      >
        <InfiniteScroll
          dataLength={credithistory.length}
          next={fetchData}
          loader={loading && <LoadingBox></LoadingBox>}
          hasMore={true}
          scrollableTarget="scrollableDivLH"

          endMessage={
            <p style={{ textAlign: 'center' }} >
              <b>Data fetch completed</b>
            </p>
          }
        >
          <div className="payment-headtitle">Credit purchase history </div> 
          <div className="payment-container">
            
              {credithistory.map(payment => (
                <PaymentHistory key={payment.paymentid} payment={payment} />
              ))}
            
          </div>
       </InfiniteScroll>
      </div>
    </>
  )
}