import React, { useState, useRef } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { updaterecurring, deleterecurring } from '../actions/bulksmsAction';
import { Button, Modal } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function RecurringSMS({ rs, setErrorArray, personal = true }) {
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const shortMessage = rs.shortMessage;

  function convertHourToSentence(recurringPeriod, recurringInterval) {
    if (recurringPeriod === "Hourly") {
      let hours = Math.floor(recurringInterval);
      let minutes = Math.round((recurringInterval - hours) * 60);
      let hourText = hours === 1 ? "hour" : "hours";
      let minuteText = minutes === 1 ? "minute" : "minutes";
      return `${hours} ${hourText} ${minutes > 0 ? `${minutes} ${minuteText}` : ""}`.trim();
      
    } else if (recurringPeriod === "Daily") {
      let days = Math.floor(recurringInterval);
      let hours = Math.round((recurringInterval - days) * 24);
      let dayText = days === 1 ? "day" : "days";
      let hourText = hours === 1 ? "hour" : "hours";
      return `${days} ${dayText} ${hours > 0 ? `${hours} ${hourText}` : ""}`.trim();
      
    } else if (recurringPeriod === "Monthly") {
      let months = Math.floor(recurringInterval);
      let days = Math.round((recurringInterval - months) * 30); // assuming 30 days in a month
      let monthText = months === 1 ? "month" : "months";
      let dayText = days === 1 ? "day" : "days";
      return `${months} ${monthText} ${days > 0 ? `${days} ${dayText}` : ""}`.trim();
      
    } else if (recurringPeriod === "Yearly") {
      let years = Math.floor(recurringInterval);
      let months = Math.round((recurringInterval - years) * 12); // assuming 12 months in a year
      let yearText = years === 1 ? "year" : "years";
      let monthText = months === 1 ? "month" : "months";
      return `${years} ${yearText} ${months > 0 ? `${months} ${monthText}` : ""}`.trim();
    }
  
    return "";
  }
  
   

  return (
    <div className="sentSms">
      <div className="sentSms-flex">
        <div className="sentSms-details">
          {userInfo.isAdmin ? (
            <div className="sentSms-description">{rs.messageDescription} by {rs.username}</div>
          ) : (
            <div className="sentSms-senderId">{rs.sourceAddress}</div>
          )}
          <div className="sentSms-messageType">{rs.messageType}</div>
        </div>
        {personal &&
          <div className="scheduled-icon">
            <RecurringEditModal rs={rs} setErrorArray={setErrorArray} />
            <RecurringDeleteModal rs={rs} />


          </div>
        }
        <div className="sentSms-details">
          <div className="sentSms-messageRegTime">{new Date(rs.messageRegistrationTime).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</div>
          <div className="sentSms-unicodeSupport">{rs.specialUnicodeSupport ? "Message was encoded" : "Message was not encoded"}</div>

        </div>
      </div>
      <div className="sentSms-message">
        {shortMessage}
        { /*shortMessage.length > shortMessage.substring(0,200).length ? shortMessage.substring(0,200)+"..." : shortMessage*/}
      </div>
      <div className="sentSms-flex">

        <div className="sentSms-details">

          <div className="sentSms-deliveryTime">Expected delivery time {new Date(rs.expectedDeliveryTime).toLocaleString("en-US", { timeZone: "Africa/Accra" })} </div>
          <div className="sentSms-recurring">Recurring every {convertHourToSentence(rs.recurringPeriod, rs.recurringInterval)}</div>

        </div>


      </div>
    </div>
  )
}

export const RecurringEditModal = (props) => {
  const rs = props.rs;

  const [messageDescription, setMessageDescription] = useState(rs.messageDescription);
  const [shortMessage, setShortMessage] = useState(rs.shortMessage);
  const [messageId, setMessageId] = useState(rs.message_id);
  const [expectedDeliveryTime, setExpectedDeliveryTime] = useState(new Date(rs.expectedDeliveryTime));
  const [recurringvalue, setRecurringvalue] = useState(rs.recurringPeriod);
  const [recurringInterval, setRecurringInterval] = useState(rs.recurringInterval);
  const [sourceAddress, setSourceAddress] = useState(rs.sourceAddress);
  const [disablebutton, setDisablebutton] = useState(false);
  const [open, setOpen] = useState(false);

  const { userInfo } = useSelector(state => state.user);
  const mountedRef = useRef(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var offset = (new Date()).getTimezoneOffset();
  var absolutoffset = Math.abs(offset);
  var hrs = Math.floor(absolutoffset / 60);
  var min = absolutoffset % 60;
  var timezonedisplay;

  if (offset < 0) {
    timezonedisplay = "Your Timezone is " + timezone + " and its " +
      hrs + " Hours and " + min + " Minutes behind GMT time zone";
  } else if (offset === 0) {
    timezonedisplay = "Your timezone is " + timezone + " same GMT Timezone";
  } else if (offset > 0) {
    timezonedisplay = "Your time zone is " + timezone + " and its " + hrs +
      " Hours and " + min +
      " Minutes ahead of  GMT time zone";
  }

  const recurringOption = [
    { value: "Hourly", label: "Hourly" },
    { value: "Daily", label: "Daily" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" }
  ];

  var period;
  var typeofperiod;

  if (recurringvalue === "Hourly") {
    period = "Enter hourly period below (eg. If you want SMS to resend every 3 hours enter 3)";
    typeofperiod = "Hour(s)";
  } else if (recurringvalue === "Daily") {
    period = "Enter daily period below (eg. If you want SMS to resend every 20days enter 20)";
    typeofperiod = "Day(s)";
  } else if (recurringvalue === "Monthly") {
    period = "Enter monthly period below (eg. If you want SMS to resend every 2 months enter 2)";
    typeofperiod = "Month(s)";
  } else if (recurringvalue === "Yearly") {
    period = "Enter yearly period below (eg. If you want SMS to resend every 1 year enter 1)";
    typeofperiod = "Year(s)";
  }


  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    if (expectedDeliveryTime.toString() === "Invalid Date") {
      let id = uuidv4()
      props.setErrorArray((prev) => {
        return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
      });
      setDisablebutton(false)
      setTimeout(() => {
        if (!mountedRef?.current) return null
        props.setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });


      }, 5000)
      return;
    }
    dispatch(updaterecurring(messageId, shortMessage, sourceAddress, messageDescription, expectedDeliveryTime, recurringvalue, recurringInterval));

    handleClose();


  };

  return (
    < >
      <Edit onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal" >

          <form >
            <h2> Edit Recurring message</h2>

            <div style={{ color: '#003366', marginTop: '5px' }}>Message Description / Campaign Name (optional)</div>
            <input type="text" autoComplete="off" value={messageDescription} placeholder="Enter campaign name (optional)" onChange={e => setMessageDescription(e.target.value)} />

            <div style={{ color: '#003366', marginTop: '5px' }}>Sender ID </div>
            {/*<input type="text" required  autoComplete="off" value={sourceAddress} placeholder="Enter Sender ID"  onChange={e => setSourceAddress(e.target.value)} />*/}
            <select
              value={sourceAddress}
              onChange={e => setSourceAddress(e.target.value)}>
              {["-- select Sender ID --", ...userInfo.senderidapproved.split(",")].map((appsid, i) => (
                <option key={i} value={appsid}>{appsid}</option>
              ))}
            </select>

            <div style={{ color: '#003366', marginTop: '5px' }}>Edit message </div>
            <textarea onChange={(e) => setShortMessage(e.target.value)} required rows="2" placeholder="Edit messge" value={shortMessage} />



            <div style={{ color: '#003366', marginTop: '5px' }}>Initial delivery time </div>
            <div className="datetimepicker">
              <DatePicker
                selected={expectedDeliveryTime}
                onChange={(val) => setExpectedDeliveryTime(val)}
                showIcon
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              /> 
            </div>



            <div className="timezone">{timezonedisplay}</div>
            <div style={{ color: '#003366', marginTop: '5px' }}>Choose Recurring Period </div>
            <select
              value={recurringvalue}
              onChange={e => setRecurringvalue(e.target.value)}>
              {recurringOption.map((ro, i) => (
                <option key={i} value={ro.value}>{ro.label}</option>
              ))}
            </select>
            <div className="recurringspanmessge">
              <span>{period}</span>
            </div>
            <input value={recurringInterval} onChange={(e) => setRecurringInterval(e.target.value)} type="text" autocomplete="off" />
            <div className="recurringspan">
              <span>{typeofperiod}</span>
            </div>
            <Button  disabled={disablebutton} onClick={onSubmitHandler} variant="contained">Save changes</Button>


          </form>
        </div>


      </Modal>
    </>
  );
}


export const RecurringDeleteModal = ({ rs }) => {


  const [open, setOpen] = useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const dispatch = useDispatch();


  const deleteHander = (e) => {
    e.preventDefault();

    dispatch(deleterecurring(rs.message_id));

  };

  return (
    < >
      <Delete onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal" >

          <form >
            <h2> Do you want to delete/stop recurring message?</h2>
            <div className="delete">
              <div>
                <Button onClick={deleteHander} variant="contained">Yes</Button>
              </div>
              <div>
                <Button onClick={handleClose} variant="contained">No</Button>
              </div>
            </div>
          </form>
        </div>


      </Modal>
    </>
  );
}