import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updatequick } from '../actions/bulksmsAction';

export default function SentSMS(props) {
    //let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { bs, index } = props;
    const user = useSelector(state => state.user);
    const { userInfo } = user;

    const shortMessage = bs.shortMessage;
    const mstatnumb = bs.messageStatus.length;
    const destnumb = bs.destinationAddress.split(",").length;
    let sent = 0;
    let pending = 0;
    let failed = 0;
    bs.messageStatus.forEach(x => {
        if (x.status === "SENT") {
            sent++;
        } else if (x.status === "FAILED") {
            failed++;
        } else {
            pending++
        }
    });

    const location = window.location.pathname;
    return (
       
            <div className="sentSms" style={{ boxShadow: mstatnumb < destnumb && '0px 0px 5px lightgreen' }}>
                 <Link to={`${location}/${bs.randomAlpha}`}>
                <div className="sentSms-flex">
                    <div className="sentSms-details">
                        {userInfo.isAdmin ? (
                            <div className="sentSms-description">{bs.messageDescription} by {bs.username}</div>
                        ) : (
                            <div className="sentSms-description">{bs.messageDescription}</div>
                        )}
                        <div className="sentSms-senderId">{bs.sourceAddress}</div>
                        <div className="sentSms-messageType">{bs.messageType}</div>
                    </div>
                    <div className="sentSms-details">
                        <div className="sentSms-messageSentTime">{new Date(bs.messageSentTime).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</div>
                        <div className="sentSms-messageCount">Message Count: {bs.messagecount}</div>
                        <div className="sentSms-unicodeSupport">{bs.specialUnicodeSupport ? "Message was encoded" : "Message was not encoded"}</div>
                    </div>
                </div>
                </Link>
                
                <div className="sentSms-message" onClick={e => e.stopPropagation()}>
                    {shortMessage}
                    { /*shortMessage.length > shortMessage.substring(0,200).length ? shortMessage.substring(0,200)+"..." : shortMessage*/}
                </div>
                 < Link to={`${location}/${bs.randomAlpha}`}>
                <div className="sentSms-flex">
                    <div className="sentSms-details">
                        <div className="sentSms-totalSmsSent">Posted to {destnumb} numbers </div>
                        {mstatnumb < destnumb && (<div className="sentSms-totalSmsSubmitted">Submitted to {mstatnumb}  numbers</div>)}
                        {mstatnumb < destnumb && (<div style={{ color: 'darkgreen' }}> Message submission in progress</div>)}
                    </div>
                    <div className="sentSms-details">
                        <div className="totalSmsSent" style={{ display: 'flex', justifyContent: 'space-between' }} >Number of Sent Message(s): <span style={{ color: 'green', fontWeight: 'bold', fontSize: '14px', marginLeft: '5px' }}>{sent}</span></div>
                        <div className="totalSmsPending" style={{ display: 'flex', justifyContent: 'space-between' }} >Number of Pending Message(s): <span style={{ color: 'gold', fontWeight: 'bold', fontSize: '14px', marginLeft: '5px' }}>{pending}</span></div>
                        <div className="totalSmsFailed" style={{ display: 'flex', justifyContent: 'space-between' }} >Number of Failed Message(s): <span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px', marginLeft: '5px' }}>{failed}</span></div>
                    </div>
                 </div>
                
                 </Link>
            </div>
    )
}
