
import { Button, Modal } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';


import { useDispatch, useSelector } from 'react-redux';
import { creategroup, groupsrequest } from '../../actions/groupsAction';
import Alerts from '../../components/mainapp/Alerts';
import { DELETE_GROUP_ERROR } from '../../constants/groupsConstants';
import { v4 as uuidv4 } from 'uuid';
import LoadingBox from '../../components/LoadingBox';
import PhoneBookCard from '../../components/PhoneBookCard';
import SearchField from "react-search-field";


export default function LocalPhoneBookScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const [search, setSearch] = useState('');

  const { groups, error, loading } = useSelector(state => state.groups);
  groups.sort((a, b) => b.id - a.id);
  const mountedRef = useRef(true)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(groupsrequest());
  }, []);

 


  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
            

      }, 5000)
      

    }
  }, [error]);



  useEffect(() => {
    setErrorArray([]);
    return () => {
      mountedRef.current = false
      dispatch({ type: DELETE_GROUP_ERROR });
    };

  }, []);
 
  const onChangeHandler = (val) => {
   setSearch(val);
  };


  return (

    <>
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />
       <div className="search">
        <SearchField
          placeholder="Search..."
          onEnter={onChangeHandler}
          searchText=""
          classNames="test-class"
        />
      </div>
      <div className="createpb">
        <BasicModal />
      </div>
      {loading && <LoadingBox></LoadingBox>}
      <div className="phone-book">
        {groups.map(phonebook => (
          <PhoneBookCard showEdit={true} key={phonebook.id} phonebook={phonebook} />
        ))}
      </div>      
    </>

  )
}


export const BasicModal = () => {
  const [open, setOpen] = useState(false);
  const [phoneBook, setPhoneBook] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(creategroup(phoneBook));
    handleClose();
    setPhoneBook("");
  };

  return (
    <>

      <Button variant="contained" onClick={handleOpen}>Create Phone Book</Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal">
          <form>
            <h2> Create Phone Book</h2>
            <input type="text" autoComplete="off" value={phoneBook} placeholder="Enter name of new Phone Book" onChange={e => setPhoneBook(e.target.value)} />
            <Button variant="contained" onClick={handleSubmit}>Create</Button>
          </form>
        </div>
      </Modal>

    </>
  );
}