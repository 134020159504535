import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quickrequest } from '../../actions/bulksmsAction';
import LoadingBox from '../../components/LoadingBox';
import SentSMS from '../../components/SentSMS';
//import 'animate.css/animate.min.css';
import SearchField from "react-search-field";
import InfiniteScroll from 'react-infinite-scroll-component';
import { CLEAN_QUICK_SUCCESS, DELETE_SMS_ERROR, QUICK_PAGENUM, QUICK_SEARCH, QUICK_VISITED } from '../../constants/bulksmsConstants';
import { v4 as uuidv4 } from 'uuid';

import Alerts from '../../components/mainapp/Alerts';

export default function LocalDashboardScreen() {
  const [errorArray, setErrorArray] = useState([]);
  const { bulksms, error, bulksearch, loading } = useSelector(state => state.bulksms);
  const { pageNum, search, visited } = bulksearch;

  //remove duplicates
  const key = 'message_id';
  const uniquebulksms = [...new Map(bulksms.map(item => [item[key], item])).values()];
  uniquebulksms.sort((a, b) => b.message_id - a.message_id);


  const dispatch = useDispatch();
  const mountedRef = useRef(true)



  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });

      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });


      }, 5000)

    }
  }, [error]);


  useEffect(() => {
    if (!visited) {
      dispatch({ type: CLEAN_QUICK_SUCCESS });
      dispatch(quickrequest("", 1));
      dispatch({ type: QUICK_SEARCH, payload: "" });
      dispatch({ type: QUICK_PAGENUM, payload: 1 });
      dispatch({ type: QUICK_VISITED, payload: true });
    }
    return () => {
      mountedRef.current = false
    }
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type !== performance.navigation.TYPE_RELOAD) {
        if (error) {
          /*
          if (uniquebulksms.length < 1) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not sent any SMS yet, kindly send sms to your contacts", status: "info" }];
            });

            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });


            }, 5000)
          }
          */
        }
      }
    }
    return () => {
      // dispatch({ type: CLEAN_QUICK_SUCCESS });
      dispatch({ type: DELETE_SMS_ERROR });
    };

  }, [bulksms]);

  const fetchData = () => {
    dispatch({ type: QUICK_PAGENUM, payload: pageNum + 1 });
    let increaesdPageNum = pageNum + 1;
    dispatch(quickrequest(search, increaesdPageNum));
  };


  const onChangeHandler = (val) => {
    dispatch({ type: CLEAN_QUICK_SUCCESS });
    dispatch({ type: QUICK_SEARCH, payload: val });
    dispatch({ type: QUICK_PAGENUM, payload: 1 });
    dispatch(quickrequest(val, 1));
  };

  let val = search ? search : "";

  return (
    <>
        {/*loading && <LoadingBox></LoadingBox>*/}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_SMS_ERROR }]} />

      <div className="search">
        <SearchField
          placeholder="Search..."
          onEnter={onChangeHandler}
          searchText={val}
          classNames="test-class"
        />
      </div>


      <div
        id="scrollableDiv"
        style={{
          height: '72vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',

        }}
      >

        {uniquebulksms.length ? (


          <InfiniteScroll
            dataLength={uniquebulksms.length}
            next={fetchData}
            loader={loading && <LoadingBox></LoadingBox>}
            hasMore={true}
            scrollableTarget="scrollableDiv"

            endMessage={
              <p style={{ textAlign: 'center' }} >
                <b>Data fetch completed</b>
              </p>
            }
          >
            {uniquebulksms.map((bs, i) => {
              return (
                <SentSMS key={bs.message_id} index={i} bs={bs} />
              )
            })}

          </InfiniteScroll>

        ) : (<></>)

        }
      </div>
    </>
  )
}
