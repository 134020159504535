import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allgroupsx } from '../../actions/groupsAction';
import LoadingBox from '../../components/LoadingBox';
import Alerts from '../../components/mainapp/Alerts';
import { v4 as uuidv4 } from 'uuid';
import SearchField from "react-search-field";

import { DELETE_GROUP_ERROR } from '../../constants/groupsConstants';
import PhoneBookCard from '../../components/PhoneBookCard';


export default function ClientPhoneBookScreen() {


  const [errorArray, setErrorArray] = useState([]);
  const [search, setSearch] = useState('');
  const { allgroups, error, loading } = useSelector(state => state.groups);
  allgroups.sort((a, b) => b.id - a.id);
   const mountedRef = useRef(true)

  const dispatch = useDispatch();
  useEffect(() => {

    dispatch(allgroupsx());


  }, []);
 


  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
            

      }, 5000)
      

    }
  }, [error]);
  useEffect(() => {
    setErrorArray([]);
    return () => {
      mountedRef.current = false
      dispatch({ type: DELETE_GROUP_ERROR });
    };

  }, []);

  const onChangeHandler = (val) => {
    setSearch(val);
  };

 
  return (

    <>
      {loading && <LoadingBox></LoadingBox>}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />
      <div className="search">
          <SearchField
            placeholder="Search..."
            onEnter={onChangeHandler}
            searchText=""
            classNames="test-class"
          />
        </div>
      <div className="phone-book">
        {allgroups.map(phonebook => (
          <PhoneBookCard showEdit={false} key={phonebook.id} phonebook={phonebook} />
        ))}
      </div> 
      
    </>

  )
}

