import { FETCH_PURCHASED_CREDIT_HISOTRY_REQUEST, DELETE_CREDIT_ERROR,FETCH_PURCHASED_CREDIT_HISOTRY_SUCCESS, FETCH_PURCHASED_CREDIT_HISOTRY_FAIL, FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST,FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS, FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL, CLEAN_ALL_PURCHASE_CREDIT_HISTORY_SUCCESS, CLEAN_PURCHASE_CREDIT_HISTORY_SUCCESS } from "../constants/historypurchasedbulksmsConstants";
import { USER_SIGNOUT } from "../constants/userConstants";
export const historypurchasedbulksmsReducer = (state = { }, action) => {
    switch (action.type) {
             
        case FETCH_PURCHASED_CREDIT_HISOTRY_REQUEST:
        case FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST:
            return { ...state, loading: true};
        case FETCH_PURCHASED_CREDIT_HISOTRY_SUCCESS:
        case FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS:
            return { ...state, loading: false,  error: action.payload.error,  credithistory: action.payload.credithistory ? [ ...state.credithistory,...action.payload.credithistory]:[...state.credithistory],  allcredithistory: action.payload.allcredithistory ?  [ ...state.allcredithistory, ...action.payload.allcredithistory]:[...state.allcredithistory] };
        case FETCH_PURCHASED_CREDIT_HISOTRY_FAIL:  
        case FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL:
            return {  ...state,loading: false, error: action.payload  };
        case DELETE_CREDIT_ERROR:
            return { ...state, loading: false, error: null };
        case CLEAN_PURCHASE_CREDIT_HISTORY_SUCCESS:
            return { ...state, credithistory: [] };
        case CLEAN_ALL_PURCHASE_CREDIT_HISTORY_SUCCESS:
            return { ...state, allcredithistory: [] };
      case USER_SIGNOUT:
            return { credithistory: [],allcredithistory:[]};
        
        default:
            return state;
    }   

};