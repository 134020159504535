import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Alerts from '../../components/mainapp/Alerts';
import LoadingBox from '../../components/LoadingBox';
import { v4 as uuidv4 } from 'uuid';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser, paymentgatewaycreditpurchase } from '../../actions/userAction';
import axios from 'axios';
import { DELETE_USER_ERROR } from '../../constants/userConstants';
import { ChangeAccountType } from '../profile/ProfileScreen';



export default function PurchaseCreditScreen() {
    const user = useSelector(state => state.user);
    const { domainDetails } = useSelector(state => state.domainDetails);
    const { userInfo, error, loading } = user;
    const dispatch = useDispatch();
    const [errorArray, setErrorArray] = useState([]);
    const [amount, setAmount] = useState("");
    const [currency, setCurrency] = useState('GHS');
    const [localrate, setLocalrate] = useState(domainDetails.localrate);
    const [usepaymentgateway, setUsepaymentgateway] = useState(domainDetails.usepaymentgateway);
    const [paystackpubkey, setPaystackpubkey] = useState(domainDetails.paystackpubkey);
    const [internationalAmount, setInternationalAmount] = useState("");
    const [paymentLoading, setPaymentLoading] = useState(false)
    const mountedRef = useRef(true)

    useEffect(() => {
        if (Object.keys(domainDetails).length) {
            if (userInfo.profile_id === domainDetails.profile_id) {
                setUsepaymentgateway(domainDetails.supusepaymentgateway);
                setLocalrate(domainDetails.suplocalrate);
                setPaystackpubkey(domainDetails.suppaystackpubkey);
            } else {
                setUsepaymentgateway(domainDetails.usepaymentgateway);
                setLocalrate(domainDetails.localrate);
                setPaystackpubkey(domainDetails.paystackpubkey);
            }
        }
    }, [domainDetails]);

    useEffect(() => {
        if (error) {
            let id = uuidv4()
            error.id = id;
            setErrorArray((prev) => {
                return [...prev, error];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });


            }, 5000)

        }
    }, [error]);


    /*
    const config = {
        public_key: paystackpubkey,
        tx_ref: uuidv4(),
        amount: amount,
        currency: currency,
        payment_options: 'card,mobilemoney',
        country: "GH",
        customer: {
            email: userInfo.email,
            phonenumber: userInfo.telnumber,
            name: userInfo.companyname,
        },
        customizations: {
            title: domainDetails.companyname,
            description: 'Payment for sms credit',
            //logo: domainDetails.logo,
        },
    };
    */
    // const handleFlutterPayment = useFlutterwave(config);
    useEffect(() => {
        return () => {
            mountedRef.current = false
        };
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        setErrorArray([]);
        setPaymentLoading(true)
        const { data } = await axios.post('/smsgateway/api/frontend/checksupcredit', { profile_id: userInfo.profile_id, amount }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });

        if (data.error && data.error.status === "successful") {

            const { data } = await axios.post('/smsgateway/api/frontend/paystack-link/', { profile_id: userInfo.profile_id, amount, currency, country: "GH", title: domainDetails.companyname, description: 'Payment for sms credit' }, {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            });

            if (data.status === "successful") {
                // open link in new tab
                //window.open(data.link, '_blank');
                window.location.href = data.link;
            } else {
                let id = uuidv4()
                data.error.id = id;
                setErrorArray((prev) => {
                    return [...prev, data.error];
                });

                setTimeout(() => {
                    if (!mountedRef?.current) return null
                    setErrorArray(prev => {
                        return prev.filter(x => x.id !== id);
                    });

                }, 5000)
            }

            /*
            handleFlutterPayment({
                callback: (response) => {
                    dispatch(paymentgatewaycreditpurchase(response.amount, response.currency, response.transaction_id));
                    closePaymentModal();
                },
                onClose: () => { },
            });
            */
        } else {
            let id = uuidv4()
            data.error.id = id;

            setErrorArray((prev) => {
                return [...prev, data.error];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)
        }
        setPaymentLoading(false)

    };
    function formatExpiry(expiry) {
        // Get the whole number part of the expiry value
        let years = Math.floor(expiry);
        // Get the fractional part and convert it to months
        let months = Math.round((expiry - years) * 12);
    
        // Handle the case where expiry is a simple fractional year
        if (years === 0 && months === 3) {
            return "3 months";
        } else if (years === 1 && months === 3) {
            return "1 year 3 months";
        } else {
            // General case formatting
            let result = "";
            if (years > 0) {
                result += years + " year" + (years > 1 ? "s" : "");
            }
            if (months > 0) {
                if (result.length > 0) {
                    result += " ";
                }
                result += months + " month" + (months > 1 ? "s" : "");
            }
            return result;
        }
    }

    let accountType = userInfo.accountType

    return (
        <>
            {loading && <LoadingBox></LoadingBox>}

            <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_USER_ERROR }]} />

            <div className="creditscreen">
                {userInfo.content === "local" && (
                    <div>
                        <div style={{ color: '#003366', fontWeight: 'bold', marginBottom: '10px', marginTop: '5px' }}>Change account type to update price accordingly</div>
                        <ChangeAccountType setErrorArray={setErrorArray} />
                        <h2 className="price_title">Price table for {accountType} account type</h2>

                        <div className="table1">

                            {userInfo.accountType !== "RESELLER" && JSON.parse(domainDetails.localrate)?.[userInfo.accountType] && (
                                <table style={{ width: '100%', textAlign: 'center'}}>
                                    <thead >
                                        <tr>
                                            <th>Price(GHS)</th>
                                            <th>SMS Credits</th>
                                            <th>Rate</th>
                                            <th>Expiry</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {JSON.parse(domainDetails.localrate)[userInfo.accountType].sort((a, b) => a.price - b.price).map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{data.price}{(JSON.parse(domainDetails.localrate)[userInfo.accountType].length-1) === i? " or more":""}</td>
                                                    <td>{Math.ceil(data.price / data.rate)}</td>
                                                    <td>{data.rate} GHS per SMS</td>
                                                    <td>{formatExpiry(data.expiry)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                
                            )}
                        {userInfo.accountType === "RESELLER" && (
                            <table style={{ width: '100%', textAlign: 'center'}}>
                                <thead>
                                    <tr>
                                        <th>Monthly SMS Consumption

                                        </th>
                                        <th>Rate</th>
                                        <th>Expiry</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    <tr >
                                        <td>0-50,000

                                        </td>
                                        <td>0.025</td>
                                        <td> 1 Year

                                        </td>

                                    </tr>
                                    <tr >
                                        <td>50,001-100,000</td>
                                        <td>0.024</td>
                                        <td> 1 Year

                                        </td>
                                    </tr>
                                    <tr >
                                        <td>100,001-200,000</td>
                                        <td>0.023</td>
                                        <td> 1 Year

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>200,001-500,000</td>
                                        <td>0.022</td>
                                        <td> 1 Year

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>500,001-1,000,000
                                        </td>
                                        <td>0.021</td>
                                        <td> 1 Year
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>1,000,000 or More</td>
                                        <td>0.020</td>
                                        <td> 1 Year

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        )}
                    </div>
                    {userInfo.accountType === "RESELLER" && (
                        <h2 className="price_title">Current applicable rate based on volume of SMS sent in the last 30days - {userInfo.applicableRate} GHS per sms </h2>
                        )}
                    </div>)}

            <div className="sms">

                {(userInfo.content === "local" && usepaymentgateway === 1) &&
                    <form>
                        <h2>Purchase SMS credit (GHS)</h2>
                        {paymentLoading && <LoadingBox></LoadingBox>}
                        <input className="form-control" autoComplete="off" type="text" id="amount" value={amount} placeholder="Enter amount" required onChange={e => setAmount(e.target.value)} />
                        <button onClick={submitHandler} >Buy SMS credit in GHS</button>

                    </form>
                }

                {(usepaymentgateway === 1 && domainDetails.supInternationalBalance && false) &&
                    <InternationalPurchaseForm
                        internationalAmount={internationalAmount}
                        setInternationalAmount={setInternationalAmount}
                        userInfo={userInfo}
                        domainDetails={domainDetails}
                        paystackpubkey={paystackpubkey}
                        setErrorArray={setErrorArray}
                        setPaymentLoading={setPaymentLoading}
                    />
                }

            </div>
        </div >
        </>

    );
}


export function InternationalPurchaseForm({ internationalAmount, setInternationalAmount, userInfo, domainDetails, paystackpubkey, setErrorArray, setPaymentLoading }) {

    //const dispatch = useDispatch();
    /*
    const config = {
        public_key: paystackpubkey,
        tx_ref: uuidv4(),
        amount: internationalAmount,
        currency: "USD",
        payment_options: 'card',
        country: "US",
        customer: {
            email: userInfo.email,
            phonenumber: userInfo.telnumber,
            name: userInfo.companyname,
        },
        customizations: {
            title: domainDetails.companyname,
            description: 'Payment for sms credit',
            //logo: domainDetails.logo,
        },
    };
    */
    //const handleFlutterPayment = useFlutterwave(config);
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => {
            mountedRef.current = false
        };
    }, []);
    const submitInternationalHandler = async (e) => {
        e.preventDefault();
        setPaymentLoading(true)
        const { data } = await axios.post('/smsgateway/api/frontend/paystack-link/', { profile_id: userInfo.profile_id, amount: internationalAmount, currency: "USD", country: "US", title: domainDetails.companyname, description: 'Payment for sms credit' }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });

        if (data.status === "successful") {
            // open link in new tab
            //window.open(data.link, '_blank');
            window.location.href = data.link;
        } else {
            let id = uuidv4()
            data.error.id = id;
            setErrorArray((prev) => {
                return [...prev, data.error];
            });

            setTimeout(() => {
                if (!mountedRef?.current) return null
                setErrorArray(prev => {
                    return prev.filter(x => x.id !== id);
                });

            }, 5000)
        }
        setPaymentLoading(false)

        /*
        handleFlutterPayment({
            callback: (response) => {
                dispatch(paymentgatewaycreditpurchase(response.amount, response.currency, response.transaction_id));
                closePaymentModal();
            },
            onClose: () => { },
        });
        */
    };


    return (
        <>
            <form  >
                <h2>Purchase international SMS credit (USD)</h2>
                <a href="#" target="_blank"  rel="noreferrer"> <span>Click here for international credit price table</span></a>
                <input className="form-control" autoComplete="off" type="text" value={internationalAmount} placeholder="Enter amount in USD" required onChange={e => setInternationalAmount(e.target.value)} />
                <button onClick={submitInternationalHandler} >Buy SMS international credit (USD)</button>
            </form>
        </>
    );
}