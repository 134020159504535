import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allusers_funct, getAllUsers, manualcreditpurchase, directcreditpurchase } from '../../actions/userAction';
import LoadingBox from '../../components/LoadingBox';
import Alerts from '../../components/mainapp/Alerts';
import PendingSenderId from '../../components/PendingSenderId';
import { DELETE_USER_ERROR } from '../../constants/userConstants';
import { v4 as uuidv4 } from 'uuid';
import SearchField from "react-search-field";
import ReactExport from 'react-data-export';
import ClientCard from '../../components/ClientCard';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;;



export default function ClientManagementScreen() {
    const dispatch = useDispatch();
    const { allusers, allStatus, userInfo, loading, clientPage } = useSelector(state => state.user);
    const [errorArray, setErrorArray] = useState([]);
    const [userEmail, setUserEmail] = useState("");
    const [directUserEmail, setDirectUserEmail] = useState("");
    const [beneficiary, setBeneficiary] = useState({})
    const [directBeneficiary, setDirectBeneficiary] = useState({})
    const [currency, setCurrency] = useState('GHS');
    const [rate, setRate] = useState();
    const [amount, setAmount] = useState("");
    const [directAmount, setDirectAmount] = useState("");
    const [showManualCreditForm, setShowManualCreditForm] = useState(false);
    const [showDirectCreditForm, setShowDirectCreditForm] = useState(false);
    const inputRef = useRef(null);
    const [search, setSearch] = useState('');
    const [allusersData, setAllusersData] = useState([]);
    

  
    useEffect(() => {
        inputRef.current.focus();
    }, []);
    
   

    const getUserHandlerAmount = async (e) => {
        e.preventDefault();

        let userArray = allusers.filter(x => x.username === userEmail.trim())
        if (userArray.length > 0) {
            setBeneficiary(userArray[0])
            setShowManualCreditForm(true)
            return
        }
        let emailArray = allusers.filter(x => x.email === userEmail.trim())
        if (emailArray.length > 0) {
            setBeneficiary(emailArray[0])
            setShowManualCreditForm(true)
            return
        }
        setShowManualCreditForm(false)
        let id = uuidv4()
        setErrorArray((prev) => {
            return [...prev, { id: id, message: "User not found", status: "failed" }];
        });

    };
    const getUserHandlerDirect = async (e) => {
        e.preventDefault();
        let userArray = allusers.filter(x => x.username === directUserEmail.trim())
        if (userArray.length > 0) {
            setDirectBeneficiary(userArray[0])
            setShowDirectCreditForm(true)
            return
        }
        let emailArray = allusers.filter(x => x.email === directUserEmail.trim())
        if (emailArray.length > 0) {
            setDirectBeneficiary(emailArray[0])
            setShowDirectCreditForm(true)
            return
        }
        setShowDirectCreditForm(false)
        let id = uuidv4()
        setErrorArray((prev) => {
            return [...prev, { id: id, message: "User not found", status: "failed" }];
        });
     

    };
    const giveCreditHandlerAmount = () => {
        if (isNaN(amount)) {
            let id = uuidv4()
            setErrorArray((prev) => {
                return [...prev, { id: id, message: "Amount must be a number", status: "failed" }];
            });
            return
        }
        dispatch(manualcreditpurchase(amount, currency, beneficiary.profile_id))
        setShowManualCreditForm(false)
        setBeneficiary({});
        setUserEmail("");
        setAmount("");

    }
     const giveCreditHandlerDirect = () => {
        //check if rate is a number
        if (isNaN(rate)) {
            let id = uuidv4()
            setErrorArray((prev) => {
                return [...prev, { id: id, message: "Rate must be a number", status: "failed" }];
            });
            return
        }
        if (isNaN(directAmount)) {
            let id = uuidv4()
            setErrorArray((prev) => {
                return [...prev, { id: id, message: "Amount must be a number", status: "failed" }];
            });
            return
        }

        dispatch(directcreditpurchase(directAmount, rate, currency, directBeneficiary.profile_id))
        setShowDirectCreditForm(false)
        setDirectBeneficiary({});
        setDirectUserEmail("");
        setDirectAmount("");
        setRate("");

    }
    const UsersWithPendingSenderIdArray = [];
    for (let i = 0; i < allusers.length; i++) {
        if (allusers[i].pendingsenderid === null) {
            continue
        }

        if (allusers[i].pendingsenderid.trim() !== "") {
            let x = allusers[i].pendingsenderid.split(",");
            for (let j = 0; j < x.length; j++) {
                if (x[j].trim() !== "") {
                    UsersWithPendingSenderIdArray.push({ username: allusers[i].username, senderid: x[j], profile_id: allusers[i].profile_id })
                }
            }
        }
    }
    const onChangeHandler = (val) => {
        setSearch(val);
    };

    useEffect(() => {

        if (search) {
            setAllusersData(allusersData.filter(x => {
                for (const [key, value] of Object.entries(x)) {
                    if (isNaN(value)) {
                        if (value.toLowerCase().includes(search.toLowerCase())) {
                            return true
                        }
                    }
                };
                return false
            })
            )
        } else {
            setAllusersData(allusers)
        }

    }, [search, allusers]);

    const loadNumbers = (e) => {
        dispatch(getAllUsers(clientPage+1));
    }



    let DataSet;

    if (allStatus.length > 0) {

        DataSet = [
        {
            columns: Object.keys(allStatus[0]).map(function (key, index) {
            return { title: key }
            }),
            data: allStatus.map((data) => Object.keys(data).map(function (key, index) {
            return { value: data[key] }
            })
            )
        }
        ]
    }

    let customerDataSet;

    if (allusers.length > 0) {

        customerDataSet = [
        {
            columns: Object.keys(allusers[0]).map(function (key, index) {
            return { title: key }
            }),
            data: allusers.map((data) => Object.keys(data).map(function (key, index) {
            return { value: data[key] }
            })
            )
        }
        ]
    }
    return (
        <div className='admin'>
            {loading && <LoadingBox></LoadingBox>}
            <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_USER_ERROR }]} />
             <hr />
            <div className="manuallycredit">
                <form onSubmit={getUserHandlerAmount}>
                    <h2> Search (by username) client and transfer credits by amount</h2>
                    <input className="form-control" autoComplete="off" ref={inputRef} type="text" id="manual-credit" value={userEmail} placeholder="Enter username or email of beneficiary" required onChange={e => setUserEmail(e.target.value)} />
                    <button type="submit">Search client to give credit</button>
                </form>
            </div>

            {showManualCreditForm && (
                <div className="creditform">
                    <form onSubmit={giveCreditHandlerAmount}>
                        <h2>Give credit to {beneficiary.username}</h2>
                        <input className="form-control" autoComplete="off" readOnly type="text" value={beneficiary.email} />
                        <input className="form-control" autoComplete="off" readOnly type="text" value={beneficiary.content === "local" ? "current credit balance " + beneficiary.creditbalance + " unit(s)" : "current credit " + beneficiary.internationalcreditbalance + " USD"} />
                        <input className="form-control" autoComplete="off" type="text" class="credit-amount" value={amount} placeholder="Enter cash amount" required onChange={e => setAmount(e.target.value)} />

                        <button type="submit">Give credit</button>
                    </form>
                </div>)}
            <hr />
            {userInfo.content === "local" &&
            <div className="manuallycredit">
                <form onSubmit={getUserHandlerDirect}>
                    <h2> Search (by username) client and transfer credits by rate</h2>
                    <input className="form-control" autoComplete="off" type="text" value={directUserEmail} placeholder="Enter username or email of beneficiary" required onChange={e => setDirectUserEmail(e.target.value)} />
                    <button type="submit">Search client to give credit</button>
                </form>
            </div>
            }
            {showDirectCreditForm && (
                <div className="creditform">
                    <form onSubmit={giveCreditHandlerDirect}>
                        <h2>Give credit to {directBeneficiary.username}</h2>
                        <input className="form-control" autoComplete="off" readOnly type="text" value={directBeneficiary.email} />
                        <input className="form-control" autoComplete="off" readOnly type="text" value={directBeneficiary.content === "local" ? "current credit balance " + directBeneficiary.creditbalance + " unit(s)" : "current credit " + directBeneficiary.internationalcreditbalance + " USD"} />
                        <input className="form-control" autoComplete="off" type="text" class="credit-amount" value={directAmount} placeholder="Enter cash amount" required onChange={e => setDirectAmount(e.target.value)} />
                        <input className="form-control" autoComplete="off" type="text" class="credit-amount" value={rate} placeholder="Enter rate, eg 0.032" required onChange={e => setRate(e.target.value)} />
                        <button type="submit">Give credit</button>
                    </form>
                </div>)}
            <hr />
            {allusers.length !== 0 ? (
                <div className="excel-customers">
                    <ExcelFile
                        filename="message-customers"
                        element={<button type="button" className="btn btn-success float-right m-3">Customer Data</button>}>
                        <ExcelSheet dataSet={customerDataSet} name="Customer Data" />
                    </ExcelFile>
                </div>
            ) : <></>}
                
            {UsersWithPendingSenderIdArray.length > 0 ?
            <>
            <hr />
            <h2>Pending Sender IDs </h2> 
            </>
            : 
            <></>}

            {UsersWithPendingSenderIdArray.map((user, i) =>
                <PendingSenderId key={uuidv4()} user={user} />
            )}
            <hr />
            <div className="searchuser">
                <SearchField
                    placeholder="Search..."
                    onChange={onChangeHandler}
                    searchText=""
                    classNames="test-class"
                />
            </div>

            <div className="userlist-headtitle">
                Clients
            </div>
            <div className="userlist-container">
                
                {allusersData.map(user => (
                    <ClientCard key={user.profile_id} user={user} />
                ))}
            </div>

            {(userInfo.profile_id === 6) ? (
            <>
                <div className="excel-numbers">
                    <ExcelFile
                        filename="message-numbers"
                        element={<button type="button" className="btn btn-success float-right m-3">Export numbers</button>}>
                        <ExcelSheet dataSet={DataSet} name="Numbers" />
                    </ExcelFile>
                </div>
                <div className="excel-total">{`Total numbers loaded ${allStatus.length}`}</div>   
                <div className="excel-button">
                    <button onClick={loadNumbers} type="button">{allStatus.length ? "Load more numbers": "Load numbers"}</button>
                </div>
            </>
            ) : <></>}

        </div>
    )
}
