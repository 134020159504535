import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function ScreenTabs({activeTab,  onClickHandler, tabLink1, tabLink2, buttonText1, buttonText2}) {

     const user = useSelector(state => state.user);
    const { userInfo } = user;
      
    return (
    <>
        {userInfo.isAdmin ? (
                <div className="screentabs" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                     
                    Personal
             
                     <div className="screenBtn" style={{ display: "flex",  marginButtom: 10, margin: 10, width: 40, alignItems: "center",  justifyContent: "space-between", backgroundColor: "white", borderRadius: 30 }}>
                    <ScreenButton
                        text={buttonText1}
                        activeTab={activeTab}
                        onClickHandler={onClickHandler}
                        tabLink={tabLink1}
                        />
                      
                    <ScreenButton
                        text={buttonText2}
                        activeTab={activeTab}
                        onClickHandler={onClickHandler}
                        tabLink={tabLink2}
                    />
                    </div>
                    Client
                </div>
            ) : (<></>)
          
            }
       </>
    );
}

const ScreenButton = ({text, tabLink,  activeTab, onClickHandler}) => (
  <button 
        style={{
            backgroundColor: activeTab === text ? "black" : "white",
            paddingVertical: 6,
            borderRadius: 30,
            alignText: "center",
            margin: 0,
            border:"none"
        }}
        onClick={activeTab !== text ? onClickHandler:()=>{}}
    
    >
     
         
        <Link to={tabLink}><div style={{ color: activeTab === text ? "white" : "black", fontsize: 15, fontWeight: "900" }}>{text}</div>{ }</Link>
       
    </button>
    
)