import React, { useState } from 'react';
import {  Route } from 'react-router-dom';

import LocalScheduledScreen from '../localscheduled/LocalScheduledScreen';
import ClientScheduledScreen from '../clientscheduled/ClientScheduledScreen';


import ScreenTabs from '../../components/ScreenTabs';


export default function ScheduledScreen(props) {

    const buttonText1 = "P";
    const tabLink1 = "/scheduledsms";
    const buttonText2 = "C";
    const tabLink2 = "/clientscheduledsms";

    const activeBtn = props.location.pathname === "/clientscheduledsms" ? buttonText2 : buttonText1;

    const [activeTab, setActiveTab] = useState(activeBtn);

  const toggleActiveTab = () => activeTab === buttonText1 ?
        setActiveTab(buttonText2) :setActiveTab(buttonText1);
    const onClickHandler = (e) => {
          e.preventDefault();
        toggleActiveTab();
    };

  
    return (
        
            <div className="scheduledsms">
                <ScreenTabs buttonText1={buttonText1} buttonText2={buttonText2} tabLink1={tabLink1} tabLink2={tabLink2} activeTab={activeTab} onClickHandler={onClickHandler} />
                <Route path="/clientscheduledsms" component={ClientScheduledScreen}></Route>
                 <Route path="/scheduledsms"  component={LocalScheduledScreen} exact ></Route>  
                     
            </div>
   )
}
