export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = 'FETCH_GROUPS_FAIL';


export const FETCH_ALL_GROUPS_REQUEST = 'FETCH_ALL_GROUPSREQUEST';
export const FETCH_ALL_GROUPS_SUCCESS = 'FETCH_ALL_GROUPS_SUCCESS';
export const FETCH_ALL_GROUPS_FAIL = 'FETCH_ALL_GROUPS_FAIL';

export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';

export const CREATE_CONTACT_FAIL = 'CREATE_CONTACT_FAIL';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';

export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';

export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';

export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';

export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';

export const CLEAN_ALL_GROUP_SUCCESS = 'CLEAN_ALL_GROUP_SUCCESS';