import { DOMAIN_FETCH_FAIL, DOMAIN_FETCH_REQUEST, DOMAIN_FETCH_SUCCESS } from "../constants/domainConstants";

export const domainDetailsReducer = (state = { }, action) => {
    
    switch (action.type) {
        case DOMAIN_FETCH_REQUEST:      
            return { ...state, loading: true };
        case DOMAIN_FETCH_SUCCESS:
            return { ...state, loading: false, error: action.payload.error ? action.payload.error : null, domainDetails: action.payload.domainDetails ? { ...state.domainDetails, ...action.payload.domainDetails} : {} };
        case DOMAIN_FETCH_FAIL:           
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }   

};