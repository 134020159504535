import './SignUpScreen.css';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Redirect } from 'react-router-dom';
import ToggleVandSForms from '../../components/ToggleVandSForms';



export default function SignUpScreen(props) {

    const user = useSelector(state => state.user);
    const { userInfo } = user;
    const { domainDetails } = useSelector(state => state.domainDetails);
    const s = {
        'background-image': `url(${domainDetails.backgroundImage})`,
        'background-size': 'cover',
        'background-repead': 'no-repeat',
        'background-position': 'center',
        'height': '100vh' 
    }
    const [style, setStyle] = useState(s);

    return (
        <>
            {!userInfo ? (<div className="signin-container"
                style={domainDetails.backgroundImage ? style : {}}
            >
                <ToggleVandSForms />
            </div >
            ) : (
                <Redirect to="/" />
            )}
        </>



    )
}
