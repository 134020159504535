import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import { deletecontact, deletegroup, updatecontact } from '../actions/groupsAction';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '@mui/material';
import { Link } from 'react-router-dom';

import Alerts from '../components/mainapp/Alerts';
import { DELETE_GROUP_ERROR } from '../constants/groupsConstants';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.filter((x, i) => i > 0).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired

};

const EnhancedTableToolbar = (props) => {
  const { numSelected, headerTitle, selected, tableType, id, client = false } = props;

  const dispatch = useDispatch();
  const deleteHandler = (e) => {
    e.preventDefault();

    if (!client) {

      if (tableType === "groups") {
        dispatch(deletegroup(selected));
      }


      if (tableType === "contacts") {
        dispatch(deletecontact(id, selected));

      }
    }
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {headerTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={deleteHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  headerTitle: PropTypes.string.isRequired
};

export default function EnhancedTable(props) {
  const { headCells, rows, headerTitle, client, id = null, tableType = "", showEdit = false, showButton = false } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);




  const handleRequestSort = (event, property) => {
    event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    event.preventDefault();
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[headCells[0].id]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    event.preventDefault();
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar client={client} tableType={tableType} id={id} numSelected={selected.length} headerTitle={headerTitle} selected={selected} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              showButton={showButton}

            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {


                  const isItemSelected = isSelected(row[headCells[0].id]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row[headCells[0].id])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[headCells[0].id]}
                      selected={isItemSelected}
                    >
                      <TableCell key={uuidv4()} padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell
                        key={uuidv4()}
                        component="th"
                        id={labelId}
                        scope="row"
                        align="right"

                      >
                        {row[headCells[1].id]}
                      </TableCell>

                      {headCells.filter((x, i) => i > 1).map(k => {
                        return <TableCell key={uuidv4()} align="right">{k.id === "setuptime" ? new Date(row[k.id]).toLocaleString("en-US", { timeZone: "Africa/Accra" }) : row[k.id]}</TableCell>
                      })}
                      {showButton &&
                        <TableCell key={uuidv4()} align="right">
                          <Link to={`${window.location.pathname}/${row[headCells[0].id]}`}><Button>Open phone book</Button></Link>
                        </TableCell>
                      }

                      {showEdit &&
                        <TableCell key={uuidv4()} align="right">
                          <div className="edit-icon">
                            <ContactEditModal groupid={id} contact={row} />
                          </div>
                        </TableCell>
                      }

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}


export const ContactEditModal = ({ groupid, contact }) => {

  const [firstname, setFirstname] = React.useState(contact.firstname ? contact.firstname : "");
  const [middlename, setMiddlename] = React.useState(contact.middlename ? contact.middlename : "");
  const [surname, setSurname] = React.useState(contact.surname ? contact.surname : "");
  const [email, setEmail] = React.useState(contact.email ? contact.email : "");
  const [phonenumber, setPhonenumber] = React.useState(contact.phonenumber ? contact.phonenumber : "");
  const [birthday, setBirthday] = React.useState(contact.birthday ? contact.birthday : "");
 
  const [errorArray, setErrorArray] = React.useState("");


  const [open, setOpen] = React.useState(false);
  const mountedRef = React.useRef(true)
  const id = contact.id;
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  
   React.useEffect(()=>{
    return () => { 
      mountedRef.current = false
    }
  }, [])

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        dispatch(updatecontact(groupid, id, firstname, surname, phonenumber, birthday, middlename, email));
      } else {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: "Please enter a valid email address", status: "warning" }];
        });

        setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });
          

        }, 5000)
      
        return;
      }
    } else {

      dispatch(updatecontact(groupid, id, firstname, surname, phonenumber, birthday, middlename, email));
    }

    handleClose();

  };

  return (
    < >
      <Edit onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />

          <form >
            <h2> Edit contact</h2>
            <input type="text" autoComplete="off" value={firstname} placeholder="Enter first name (leave empty of no data)" onChange={e => setFirstname(e.target.value)} />
            <input type="text" autoComplete="off" value={middlename} placeholder="Enter middle name (leave empty of no data)" onChange={e => setMiddlename(e.target.value)} />
            <input type="text" autoComplete="off" value={surname} placeholder="Enter surname (leave empty of no data)" onChange={e => setSurname(e.target.value)} />
            <input type="text" autoComplete="off" value={email} placeholder="Enter email address (leave empty of no data)" onChange={e => setEmail(e.target.value)} />
            <input type="text" autoComplete="off" value={phonenumber} placeholder="Enter mobile number (leave empty of no data)" onChange={e => setPhonenumber(e.target.value)} />
            <div style={{ color: '#003366', marginTop: '5px' }}>Enter birth date (leave empty of no data) </div>
            <div className="datetimepicker">
            <DatePicker
                selected={birthday}
                onChange={(val) => setBirthday(val)}
                showIcon
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
            <Button variant="contained" onClick={handleSubmit}>Save Changes</Button>


          </form>
        </div>


      </Modal>
    </>
  );
}