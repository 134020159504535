
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';
import { groupsrequest } from '../../actions/groupsAction';
import { createquick } from '../../actions/bulksmsAction';
import { DELETE_GROUP_ERROR } from '../../constants/groupsConstants';

import * as XLSX from "xlsx";


import Alerts from '../../components/mainapp/Alerts';

import { ALL_QUICK_VISITED, ALL_RECURRING_VISITED, ALL_SCHEDULED_VISITED, DELETE_SMS_ERROR, QUICK_VISITED, RECURRING_VISITED, SCHEDULED_VISITED } from '../../constants/bulksmsConstants';
import LoadingBox from '../../components/LoadingBox';
import { SenderIdRequest } from '../profile/ProfileScreen';


export default function SendSMSScreen() {
  // get draft data from local storage
  let draftData = JSON.parse(localStorage.getItem('draftData'))


  const [destinationAddress, setDestinationAddress] = useState(draftData?.destinationAddress || '');
  const [messageType, setMessageType] = useState(draftData?.messageType || "Quick Bulk SMS");
  const [contactType, setContactType] = useState(draftData?.contactType || "manual");
  const [groupContact, setGroupContact] = useState(draftData?.groupContact || '');
  const [messageDescription, setMessageDescription] = useState(draftData?.messageDescription || "");
  const [shortMessage, setShortMessage] = useState(draftData?.shortMessage || '');
  const [specialUnicodeSupport, setSpecialUnicodeSupport] = useState(draftData?.specialUnicodeSupport || false);
  const [expectedDeliveryTime, setExpectedDeliveryTime] = useState(new Date());
  const [recurringvalue, setRecurringvalue] = useState(draftData?.recurringvalue || "Hourly");
  const [recurringInterval, setRecurringInterval] = useState(draftData?.recurringInterval || '');
  const [errorArray, setErrorArray] = useState([]);
  const [unicodeTracker, setUnicodeTracker] = useState(0);
  const [subType, setSubType] = useState(draftData?.subType || "normal");
  const [disablebutton, setDisablebutton] = useState(false);
  const [flash, setFlash] = useState(draftData?.flash ||  false);

  const [excelData, setExcelData] = useState(draftData?.excelData || []);
  const [sourceAddress, setSourceAddress] = useState(draftData?.sourceAddress || '');
  const [personalizedFilter, setPersonalizedFilter] = useState(draftData?.personalizedFilter || []);
  const [personalizedExcel, setPersonalizedExcel] = useState(draftData?.personalizedExcel || []);
  const [typeofperiod, setTypeofperiod] = useState(draftData?.typeofperiod || "");
  const [period, setPeriod] = useState(draftData?.period || "");
  const [recipients, setRecipients] = useState(draftData?.recipients || 0);
  const [messagecount1, setMessagecount1] = useState(draftData?.messagecount1 || 0);
  const [characterCount, setCharacterCount] = useState(draftData?.characterCount || 0);
  const [characterDenominator, setCharacterDenominator] = useState(draftData?.characterDenominator || 160);
  const [excelSheetNames, setExcelSheetNames] = useState(draftData?.excelSheetNames || []);
  const [workbookData, setWorkbookData] = useState(draftData?.workbookData || null);
  const [selectedExcelSheet, setSelectedExcelSheet] = useState(draftData?.selectedExcelSheet || "");
  const mountedRef = useRef(true);


  const [files, setFiles] = useState([]);

  const { userInfo } = useSelector(state => state.user);
  const { groups } = useSelector(state => state.groups);
  const { error, loading } = useSelector(state => state.bulksms);

  const dispatch = useDispatch();
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }


  const addToTextArea = function (text_to_add) {
    let textarea = document.getElementById("message-textarea");
    let start_position = textarea.selectionStart;
    let end_position = textarea.selectionEnd;

    return `${textarea.value.substring(
      0,
      start_position
    )}${text_to_add}${textarea.value.substring(
      end_position,
      textarea.value.length
    )}`;
  };

  function isDoubleByte(str) {
    for (var i = 0, n = str.length; i < n; i++) {
      if (str.charCodeAt(i) > 255) { return true; }
    }
    return false;
  }

  useEffect(() => {
    let senderids = userInfo.senderidapproved.split(",");
    senderids = senderids.filter(x => x.trim() !== "")
    if (senderids.length === 1) {
      setSourceAddress(userInfo.senderidapproved.split(",")[0])
    }
  }, [userInfo])

  /* commneted out because we want to auto set the unicode
  useEffect(() => {
    if (!specialUnicodeSupport && unicodeTracker === 0) {
      if (isDoubleByte(shortMessage)  {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: "You just entered a unicode character, for better performance, kindly enable unicode support by setting the encode type to unicode", status: "failed" }];
        });

        setTimeout(() => {
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)

        setUnicodeTracker(1);
      }
    }
  }, [shortMessage])
*/

  // auto set unicode
  let frenchCharactersorSpanishCharacters = /[àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ]/;

  useEffect(() => {
    if (isDoubleByte(shortMessage) || frenchCharactersorSpanishCharacters.test(shortMessage)) {
      if (!specialUnicodeSupport)
        setSpecialUnicodeSupport(true)
    } else {
      if (specialUnicodeSupport)
        setSpecialUnicodeSupport(false)
    }

    if (shortMessage.includes("{") && shortMessage.includes("}") && subType === "normal") {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "It appears you want to send a personalized sms, please change SMS sub type to personalized", status: "warning" }];
      });

      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)

    }
  }, [shortMessage])


  useEffect(() => {
    if (recipients > 100000) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "For efficiency we recommend you send to a maximum of 100,000 recipients at a time", status: "warning" }];
      });

      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
    }
  }, [recipients])

  useEffect(() => {
    if (error) {

      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });


      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });


      }, 5000)

    }
    if (error) {
      if (error.hasOwnProperty('status') && error.status === "successful") {
        setShortMessage('');
        setDestinationAddress('');
        setSourceAddress('');
        setMessageDescription('');
        setExpectedDeliveryTime(new Date());
        setSpecialUnicodeSupport(false);
        setRecurringvalue("Hourly");
        setRecurringInterval('');
        setGroupContact('');
        setContactType("manual")
        setSubType("normal")
        setFlash(false);
        setMessageType("Quick Bulk SMS")
        setExcelData([])
        setPersonalizedExcel([])
        setPersonalizedFilter([])
        setTypeofperiod("");
        setPeriod("");
        setRecipients(0);
        setMessagecount1(0);
        setCharacterCount(0);
        setCharacterDenominator(160);
        setExcelSheetNames([]);
        setWorkbookData(null);
        setSelectedExcelSheet("");
      }
    }
  }, [error]);

  useEffect(()=>{
    let draftData = {
      destinationAddress,
      messageType, 
      contactType,
      groupContact,
      messageDescription,
      shortMessage,
      specialUnicodeSupport,
      recurringvalue,
      recurringInterval,
      subType,
      flash,
      excelData,
      sourceAddress,
      personalizedFilter,
      personalizedExcel,
      typeofperiod,
      period,
      recipients,
      messagecount1,
      characterCount,
      characterDenominator,
      excelSheetNames,
      workbookData,
      selectedExcelSheet
    }
    //save draft data to localstorage
    try{
    localStorage.setItem('draftData', JSON.stringify({ ...JSON.parse(localStorage.getItem('draftData')), ...draftData }));
    }catch(e){
      console.log(e)
    }
  }, [
    destinationAddress, 
    messageType, 
    contactType,
    groupContact,
    messageDescription,
    shortMessage,
    specialUnicodeSupport,
    recurringvalue,
    recurringInterval,
    subType,
    flash,
    excelData,
    sourceAddress,
    personalizedFilter,
    personalizedExcel,
    typeofperiod,
    period,
    recipients,
    messagecount1,
    characterCount,
    characterDenominator,
    excelSheetNames,
    workbookData,
    selectedExcelSheet

  ])

  useEffect(() => {
    
    setErrorArray([]);
    return () => {
      dispatch({ type: DELETE_GROUP_ERROR });
      dispatch({ type: DELETE_SMS_ERROR });
      mountedRef.current = false
    };

  }, []);

  useEffect(() => {

    if (subType === "personalized" && contactType === "phonebook") {
      if (messageType === "birthday") {
        setPersonalizedFilter(["age", "firstname", "surname", "middlename", "email"]);
      } else {
        setPersonalizedFilter(["firstname", "surname", "middlename", "email"]);
        if (shortMessage.includes("{age}")) {
          setShortMessage(shortMessage.replace("{age}", ""));
        }
      }
    } else if (subType === "personalized" && contactType === "excel") {
      setPersonalizedFilter(personalizedExcel)
    }
  }, [subType, messageType, contactType, personalizedExcel]);

  useEffect(() => {
    let count1;
    if (specialUnicodeSupport) {
      count1 = Buffer.byteLength(shortMessage, 'UCS-2') + 60;
      if (count1 <= 160) {
        setMessagecount1(1);
        setCharacterCount(count1);
        setCharacterDenominator(160);
      } else if (count1 > 160 && count1 <= 306) {
        setMessagecount1(2);
        setCharacterCount(count1 - 160);
        setCharacterDenominator(306 - 160);
      } else if (count1 > 306 && count1 <= 459) {
        setMessagecount1(3);
        setCharacterCount(count1 - 306);
        setCharacterDenominator(459 - 306);
      } else if (count1 > 459 && count1 <= 612) {
        setMessagecount1(4);
        setCharacterCount(count1 - 459);
        setCharacterDenominator(612 - 459);
      } else if (count1 > 612 && count1 <= 765) {
        setMessagecount1(5);
        setCharacterCount(count1 - 612);
        setCharacterDenominator(765 - 612);
      } else if (count1 > 765 && count1 <= 918) {
        setMessagecount1(6);
        setCharacterCount(count1 - 765);
        setCharacterDenominator(918 - 765);
      } else if (count1 > 918 && count1 <= 1071) {
        setMessagecount1(7);
        setCharacterCount(count1 - 918);
        setCharacterDenominator(1071 - 918);
      } else if (count1 > 1071 && count1 <= 1224) {
        setMessagecount1(8);
        setCharacterCount(count1 - 1071);
        setCharacterDenominator(1224 - 1071);
      } else if (count1 > 1224 && count1 <= 1377) {
        setMessagecount1(9);
        setCharacterCount(count1 - 1224);
        setCharacterDenominator(1377 - 1224);
      } else {
        setMessagecount1(10);
        setCharacterCount("out of range");
        setCharacterDenominator("out of range");
      }

      /*       
        if (count1 <= 70) {
          messagecount1 = 1;
          characterCount = count1;
          characterDenominator = 70;
        } else if (count1 > 70 && count1 <= 134) {
          messagecount1 = 2;
          characterCount = count1 - 70;
          characterDenominator = 70 - 134;
        } else if (count1 > 134 && count1 <= 201) {
          messagecount1 = 3;
          characterCount = count1 - 134;
          characterDenominator = 134 - 201;
        } else if (count1 > 201 && count1 <= 268) {
          messagecount1 = 4;
          characterCount = count1 - 201;
          characterDenominator = 201 - 268;
        } else if (count1 > 268 && count1 <= 335) {
          messagecount1 = 5;
          characterCount = count1 - 268;
          characterDenominator = 268 - 335;
        } else if (count1 > 335 && count1 <= 402) {
          messagecount1 = 6;
          characterCount = count1 - 335;
          characterDenominator = 335 - 402;
        } else if (count1 > 402 && count1 <= 469) {
          messagecount1 = 7;
          characterCount = count1 - 402;
          characterDenominator = 402 - 469;
        } else if (count1 > 469 && count1 <= 536) {
          messagecount1 = 8;
          characterCount = count1 - 469;
          characterDenominator = 469 - 536;
        } else if (count1 > 536 && count1 <= 603) {
          messagecount1 = 9;
          characterCount = count1 - 536;
          characterDenominator = 536 - 603;
        } else {
          messagecount1 = 10;
          characterCount = "out of range";
          characterDenominator = "out of range";
        }
    */
    } else {
      count1 = Buffer.byteLength(shortMessage, 'utf8');
      if (count1 <= 160) {
        setMessagecount1(1);
        setCharacterCount(count1);
        setCharacterDenominator(160);
      } else if (count1 > 160 && count1 <= 306) {
        setMessagecount1(2);
        setCharacterCount(count1 - 160);
        setCharacterDenominator(306 - 160);
      } else if (count1 > 306 && count1 <= 459) {
        setMessagecount1(3);
        setCharacterCount(count1 - 306);
        setCharacterDenominator(459 - 306);
      } else if (count1 > 459 && count1 <= 612) {
        setMessagecount1(4);
        setCharacterCount(count1 - 459);
        setCharacterDenominator(612 - 459);
      } else if (count1 > 612 && count1 <= 765) {
        setMessagecount1(5);
        setCharacterCount(count1 - 612);
        setCharacterDenominator(765 - 612);
      } else if (count1 > 765 && count1 <= 918) {
        setMessagecount1(6);
        setCharacterCount(count1 - 765);
        setCharacterDenominator(918 - 765);
      } else if (count1 > 918 && count1 <= 1071) {
        setMessagecount1(7);
        setCharacterCount(count1 - 918);
        setCharacterDenominator(918 - 1071);
      } else if (count1 > 1071 && count1 <= 1224) {
        setMessagecount1(8);
        setCharacterCount(count1 - 1071);
        setCharacterDenominator(1071 - 1224);
      } else if (count1 > 1224 && count1 <= 1377) {
        setMessagecount1(9);
        setCharacterCount(count1 - 1224);
        setCharacterDenominator(1224 - 1377);
      } else {
        setMessagecount1(10);
        setCharacterCount("out of range");
        setCharacterDenominator("out of range");
      }
    }
  }, [shortMessage, specialUnicodeSupport]);

  useEffect(() => {
    let uniquedest;
    if (subType === "personalized") {
      uniquedest = [...destinationAddress.split(',')];
    } else {
      uniquedest = [...new Set(destinationAddress.split(','))];
    }

    let count = [];
    for (let i = 0; i < uniquedest.length; i++) {
      if (uniquedest[i] !== "") {
        count.push(uniquedest[i]);
      }
    }


    if (contactType === "manual" || contactType === "excel") {
      setRecipients(count.length);

    } else {
      let group = groups.filter(x => x.groupname === groupContact)[0]
      setRecipients(group ? group.contacts.length : 0);
    }
  }, [destinationAddress, contactType, subType, groupContact]);


  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let offset = (new Date()).getTimezoneOffset();
  let absolutoffset = Math.abs(offset);
  let hrs = Math.floor(absolutoffset / 60);
  let min = absolutoffset % 60;
  let timezonedisplay;

  if (offset < 0) {
    timezonedisplay = "Your Timezone is " + timezone + " and its " +
      hrs + " Hours and " + min + " Minutes behind GMT time zone";
  } else if (offset === 0) {
    timezonedisplay = "Your timezone is " + timezone + " same with GMT";
  } else if (offset > 0) {
    timezonedisplay = "Your time zone is " + timezone + " and its " + hrs +
      " Hours and " + min +
      " Minutes ahead of  GMT time zone";
  }

  const recurringOption = [
    { value: "Hourly", label: "Hourly" },
    { value: "Daily", label: "Daily" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" }
  ];

  const mobileOnChange = (e) => {
    // if dont allow key press when excel is selected for personalized
    if (subType === "personalized" && contactType === "excel") {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "For personalized SMS please load contacts using excel or from your phone book", status: "warning" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return

    } else {
      let mn = e.target.value.replaceAll('/', ',').replaceAll(/\r?\n/g, ',').replaceAll(/\s+/g, '');
      //let mnunique = [...new Set(mn.split(','))].join(",");
      setDestinationAddress(mn);
    }
  };

  const filterHandler = (e) => {
    setShortMessage(prev => {
      if (e.target.value !== "-- Select filter --") {
        return addToTextArea(`{${e.target.value}}`);
      }
    })

  }

  const handleShortMessageChange = (e) => {
    setShortMessage(e.target.value.replace("’", "'"));
  }

  useEffect(() => {
    if (recurringvalue === "Hourly") {
      setPeriod("Enter hourly period below (eg. If you want SMS to resend every 3 hours enter 3)");
      setTypeofperiod("Hour(s)");
    } else if (recurringvalue === "Daily") {
      setPeriod("Enter daily period below (eg. If you want SMS to resend every 20days enter 20)");
      setTypeofperiod("Day(s)");
    } else if (recurringvalue === "Monthly") {
      setPeriod("Enter monthly period below (eg. If you want SMS to resend every 2 months enter 2)");
      setTypeofperiod("Month(s)");
    } else if (recurringvalue === "Yearly") {
      setPeriod("Enter yearly period below (eg. If you want SMS to resend every 1 year enter 1)");
      setTypeofperiod("Year(s)");
    }
  }, [recurringvalue])

  const handlerExcelSheetSelector = (e, workbook = null) => {
    setSelectedExcelSheet(e.target.value);

    let worksheet = workbook ? workbook.Sheets[e.target.value] : workbookData.Sheets[e.target.value];
    if (!worksheet) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Select the right sheet containing the needed data for this SMS", status: "failed" }];
      });

      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return
    }
    // convert to json format
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    for (let i = 0; i < jsonData.length; i++) {
      if (messageType === "birthday" && i === 0) {
        setPersonalizedExcel(["age"]);
      }
      for (let [key, value] of Object.entries(jsonData[i])) {
        delete jsonData[i][key];
        key = key.replace(/\r?\n|\r/g, "").toString().trim()
      
        if (!isNaN(value) && key !== "numbers") {
          jsonData[i][key] = numberWithCommas(value)
        } else {
          jsonData[i][key] = value;
        }

        if ((key !== "numbers" || key !== "number") && typeof key === "string" && i === 0) {
          setPersonalizedExcel(prev => {
            return [...prev, key.trim()]
          })
        }
      }
    }

    if (jsonData.length && !jsonData[0].numbers) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: 'To load mobile numbers please provide heading "numbers" for the column holding the mobile numbers on the excel sheet', status: "failed" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return
    }

    setExcelData(jsonData);
    setDestinationAddress(prev => {
      if (prev === "") {
        return jsonData.map(obj => obj.numbers).join(",");
      }
      return prev + "," + jsonData.map(obj => obj.numbers).join(",");
    });

  };

  const handleReadExcel = (files) => {
    if (subType === "personalized") {
      //reset everything  
      setDestinationAddress("");
      setPersonalizedFilter([]);
    }

    // files is an array of file
    if (files.length < 1) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an excel file", status: "warning" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return
    }
    const file = files[0];

    const filetype = ['xlsx', 'xlsm', 'xls', 'xml']

    if (!filetype.includes(file.name.split(".")[1])) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an excel file", status: "warning" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      return

    }
    let reader = new FileReader();

    reader.onload = function (e) {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: 'array' });
      setWorkbookData(workbook);
      let sheetNames = workbook.SheetNames;
      setExcelSheetNames(sheetNames);
      //preselect sheet if only one
      if (sheetNames.length === 1) {
        handlerExcelSheetSelector({
          target: {
            value: sheetNames[0]
          }
        }, workbook)
      } else {
        setSelectedExcelSheet("");
      }
    }
    reader.readAsArrayBuffer(file);
  }

  let buttonText;
  if (messageType === "Quick Bulk SMS") {
    buttonText = "Send Message";
  } else if (messageType === "scheduled") {
    buttonText = "Register scheduled sms";
  } else if (messageType === "birthday") {
    buttonText = "Register birthday sms";
  } else {
    buttonText = "Register recurring sms";
  }

  function getDifferenceInYears(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24 * 365));
  }


  const onSubmitHandler = (e) => {
    e.preventDefault();
    setDisablebutton(true);
    /*
    if (sourceAddress === "TEST") {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly apply for your customized sender ID(s) from the profile page", status: "warning" }];
      });
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
      setDisablebutton(false);
      return
    }
      */


    setDisablebutton(true);
    let actshortMessage;
    let dest = [...new Set(destinationAddress.split(','))].join(",");;
    if ((sourceAddress === "-- select Sender ID --") || (sourceAddress === "")) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Please select sender ID for this messge", status: "failed" }];
      });
      setDisablebutton(false)
      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
    } else {
      let specMessage = "Thank you for choosing us, kindly apply for your customized sender ID(s) from the profile page so your actual message will be delivered"
      let messageArray = [];
      if (messageType === "Quick Bulk SMS") {
        if (subType === "personalized" && contactType === "excel") {
          if (excelData.length < 1) {
            let id = uuidv4()
            setDisablebutton(false)
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please upload an excel sheet", status: "failed" }];
            });
            return
          }
          excelData.forEach(exd => {
            if (exd.numbers === "") {
              return;
            }
            actshortMessage = shortMessage;
            for (var key in exd) {

              if (!exd.hasOwnProperty(key)) {
                continue;
              }
              actshortMessage = actshortMessage.replaceAll(`{${key}}`, exd[key]);

            }

            let msg = {
              //shortMessage: shortMessage.replaceAll('{coll}', exd.col1).replaceAll('{col2}', exd.col2).replaceAll('{col3}', exd.col3).replaceAll('{col4}', exd.col4).replaceAll('{col5}', exd.col5).replaceAll('{col6}', exd.col6).replaceAll('{col7}', exd.col7).replaceAll('{col8}', exd.col8),
              shortMessage: sourceAddress === "TEST" ? specMessage : actshortMessage,
              destinationAddress: exd.numbers,
              sourceAddress: sourceAddress,
              messageDescription: messageDescription,
              messageType: messageType,
              specialUnicodeSupport: specialUnicodeSupport,
              flash: flash
            };
            messageArray.push(msg);

          });

        } else if (subType === "personalized" && contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }

          let contacts = groups.filter(group => group.groupname === groupContact)[0].contacts;

          contacts.forEach(contact => {
            let msg = {
              shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage.replaceAll('{firstname}', contact.firstname).replaceAll('{middlename}', contact.middlename).replaceAll('{surname}', contact.surname).replaceAll('{age}', getDifferenceInYears(contact.birthday, new Date())),
              destinationAddress: contact.phonenumber,
              sourceAddress: sourceAddress,
              messageDescription: messageDescription,
              messageType: messageType,
              specialUnicodeSupport: specialUnicodeSupport,
              flash: flash
            };
            messageArray.push(msg);

          });

        } else if (subType === "normal" && (contactType === "manual" || contactType === "excel")) {
          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: dest,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            flash: flash

          };
          messageArray.push(msg);

        } else if (subType === "normal" && contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });


            }, 5000)
            return;
          }
          let destinationAddress = groups.filter(group => group.groupname === groupContact)[0]
            .contacts.map(x => x.phonenumber).join(",");

          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: destinationAddress,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            flash: flash
          };
          messageArray.push(msg);
        }
        dispatch({ type: QUICK_VISITED, payload: false });
        dispatch({ type: ALL_QUICK_VISITED, payload: false });

      } else if (messageType === "scheduled") {
        if (expectedDeliveryTime.toString() === "Invalid Date") {
          let id = uuidv4()
          setErrorArray((prev) => {
            return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
          });
          setDisablebutton(false)
          setTimeout(() => {
            if (!mountedRef?.current) return null
            setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
            });


          }, 5000)
          return;
        }

        if (subType === "personalized" && contactType === "excel") {
          if (excelData.length < 1) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please upload an excel sheet", status: "failed" }];
            });
            setDisablebutton(false)
            return
          }
          //  excelData.forEach(exd => {
          /*
          actshortMessage = shortMessage;
          for (var key in exd) {
         
            if (!exd.hasOwnProperty(key)) {
                continue;
            }
            actshortMessage = actshortMessage.replaceAll(`{${key}}`, exd[key]); 
        }
        */
          let msg = {
            //shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage.replaceAll('{coll}', exd.col1).replaceAll('{col2}', exd.col2).replaceAll('{col3}', exd.col3).replaceAll('{col4}', exd.col4).replaceAll('{col5}', exd.col5).replaceAll('{col6}', exd.col6).replaceAll('{col7}', exd.col7).replaceAll('{col8}', exd.col8),
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: JSON.stringify(excelData),
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            typeandsubtype: "excel personalized",
            flash: flash
          };
          messageArray.push(msg);

          //});

        } else if (subType === "personalized" && contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }

          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: groupContact,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            typeandsubtype: "phonebook personalized",
            flash: flash
          };
          messageArray.push(msg);
        } else if (subType === "normal" && (contactType === "manual" || contactType === "excel")) {
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });


            }, 5000)
            return;
          }

          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: dest,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            typeandsubtype: "normal",
            flash: flash
          };
          messageArray.push(msg);

        } else if (subType === "normal" && contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });


            }, 5000)
            return;
          }
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });


            }, 5000)
            return;
          }

          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: groupContact,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            specialUnicodeSupport: specialUnicodeSupport,
            typeandsubtype: "phonebook normal",
            flash: flash
          };
          messageArray.push(msg);

        }
        dispatch({ type: SCHEDULED_VISITED, payload: false });
        dispatch({ type: ALL_SCHEDULED_VISITED, payload: false })
      } else if (messageType === "recurring") {
        if (expectedDeliveryTime.toString() === "Invalid Date") {
          let id = uuidv4()
          setErrorArray((prev) => {
            return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
          });
          setDisablebutton(false)
          setTimeout(() => {
            if (!mountedRef?.current) return null
            setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
            });
          }, 5000)

          return;
        } 

        if (recurringInterval === "" || recurringInterval == 0) {
          let id = uuidv4()
          setErrorArray((prev) => {
            return [...prev, { id: id, message: "Please select a recurring interval", status: "failed" }];
          });
          setDisablebutton(false)
          setTimeout(() => {
            if (!mountedRef?.current) return null
            setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
            });
          }, 5000)
          return;
        }


        if (subType === "personalized" && contactType === "excel") {
          if (excelData.length < 1) {
            let id = uuidv4()
            setDisablebutton(false)
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please upload an excel sheet", status: "failed" }];
            });
            return
          }
          // excelData.forEach(exd => {
          /*  
          actshortMessage = shortMessage;
             for (var key in exd) {
            
               if (!exd.hasOwnProperty(key)) {
                   continue;
               }
               actshortMessage = actshortMessage.replaceAll(`{${key}}`, exd[key]); 
           }
            */
          let msg = {
            // shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage.replaceAll('{coll}', exd.col1).replaceAll('{col2}', exd.col2).replaceAll('{col3}', exd.col3).replaceAll('{col4}', exd.col4).replaceAll('{col5}', exd.col5).replaceAll('{col6}', exd.col6).replaceAll('{col7}', exd.col7).replaceAll('{col8}', exd.col8),
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: JSON.stringify(excelData),
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            recurringInterval: recurringInterval,
            recurringPeriod: recurringvalue,
            typeandsubtype: "excel personalized",
            flash: flash
          };
          messageArray.push(msg);

          //});

        } else if (subType === "personalized" && contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }
          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: groupContact,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            recurringInterval: recurringInterval,
            recurringPeriod: recurringvalue,
            typeandsubtype: "phonebook personalized",
            flash: flash
          };
          messageArray.push(msg);
        } else if (subType === "normal" && (contactType === "manual" || contactType === "excel")) {
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setDisablebutton(false)
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
            });
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }
          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: dest,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            specialUnicodeSupport: specialUnicodeSupport,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            recurringInterval: recurringInterval,
            recurringPeriod: recurringvalue,
            typeandsubtype: "normal",
            flash: flash
          };
          messageArray.push(msg);

        } else if (subType === "normal" && contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }

          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: groupContact,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            specialUnicodeSupport: specialUnicodeSupport,
            recurringInterval: recurringInterval,
            recurringPeriod: recurringvalue,
            typeandsubtype: "phonebook normal",
            flash: flash
          };
          messageArray.push(msg);

        }
        dispatch({ type: RECURRING_VISITED, payload: false });
        dispatch({ type: ALL_RECURRING_VISITED, payload: false })
      } else {
        //birthday
        if (contactType === "phonebook") {
          if ((groupContact === "-- select Phone Book --") || (groupContact === "")) {
            let id = uuidv4()
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "You have not selected any phone book", status: "failed" }];
            });
            setDisablebutton(false)
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }

          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: groupContact,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            expectedDeliveryTime: `${new Date().toJSON().replace("T", " ").slice(0, -7)}00`, 
            specialUnicodeSupport: specialUnicodeSupport,
            typeandsubtype: "birthday phonebook",
            flash: flash
          };
          messageArray.push(msg);
        } else if (contactType === "excel") {
          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: JSON.stringify(excelData),
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            expectedDeliveryTime: `${new Date().toJSON().replace("T", " ").slice(0, -7)}00`,
            specialUnicodeSupport: specialUnicodeSupport,
            typeandsubtype: "birthday excel",
            flash: flash
          };
          messageArray.push(msg);
        } else if (contactType === "manual") {
          if (expectedDeliveryTime.toString() === "Invalid Date") {
            let id = uuidv4()
            setDisablebutton(false)
            setErrorArray((prev) => {
              return [...prev, { id: id, message: "Please enter a birthday date", status: "failed" }];
            });
            setTimeout(() => {
              if (!mountedRef?.current) return null
              setErrorArray(prev => {
                return prev.filter(x => x.id !== id);
              });
            }, 5000)
            return;
          }
          let msg = {
            shortMessage: sourceAddress === "TEST" ? specMessage : shortMessage,
            destinationAddress: dest,
            sourceAddress: sourceAddress,
            messageDescription: messageDescription,
            messageType: messageType,
            expectedDeliveryTime: `${expectedDeliveryTime.toJSON().replace("T", " ").slice(0, -7)}00`,
            specialUnicodeSupport: specialUnicodeSupport,
            typeandsubtype: "birthday manual",
            flash: flash
          };
          messageArray.push(msg);
        }

        dispatch({ type: RECURRING_VISITED, payload: false });
        dispatch({ type: ALL_RECURRING_VISITED, payload: false })
      }

      dispatch(createquick(messageArray));

      setDisablebutton(false)
      /*
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Thank you for choosing us, your message(s) is/are being submitted", status: "info" }];
      });
      */
      /*
     setTimeout(() => {
         if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });
              

        }, 5000)
*/
    }

  };

  return (
    <>
      {loading && <LoadingBox></LoadingBox>}
      <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }, { type: DELETE_SMS_ERROR }]} />
      <div className="app-form">
        <form onSubmit={onSubmitHandler}>
          <h2> BulkSMS</h2>
          <div style={{ display: 'flex', marginTop: '10px', marginLeft: '5%', marginRight: '5%', justifyContent: 'space-between' }}>
            <label style={{ fontWeight: 'bold', marginBottom: '5px', alignSelf: 'flex-start', borderBottom: 'solid 0.5px' }}>Choose Message Type</label>
            <div className='link-senderid'><SenderIdRequest /></div>
          </div>
          <div className="radio-buttons">
            <div>

              <input

                checked={messageType === "Quick Bulk SMS"}
                name="messageType"
                type="radio"

                onChange={e => setMessageType("Quick Bulk SMS")}
              />
              <span>Quick SMS</span>
            </div>
            <div>

              <input

                checked={messageType === "scheduled"}
                name="messageType"
                type="radio"
                onChange={e => setMessageType("scheduled")}
              />
              <span>
              Scheduled SMS
              </span>
            </div>
            <div>
              <input

                checked={messageType === "recurring"}
                name="messageType"
                type="radio"
                onChange={e => setMessageType("recurring")}
              />
              <span>Recurring SMS</span>
              
            </div>

            <div>
              <input
                checked={messageType === "birthday"}
                name="messageType"
                type="radio"
                onChange={e => setMessageType("birthday")}
              />
              <span>
              Birthday Message
              </span>
            </div>


          </div>
         
          <label>Choose SMS sub-type</label>
          <div className="radio-buttons">
            <div>

              <input

                checked={subType === "normal"}

                type="radio"

                onChange={e => setSubType("normal")}
              />
              <span>Regular SMS</span>
              
            </div>
            <div>

              <input

                checked={subType === "personalized"}

                type="radio"
                onChange={e => { setSubType("personalized"); setContactType("excel") }}
              />
              <span>Personalized SMS</span>
              
            </div>

          </div>
      

          <label>Choose contact type</label>
          <div className="radio-buttons">
            {subType !== "personalized" &&
              <div>
                <input

                  checked={contactType === "manual"}
                  name="manual"
                  type="radio"

                  onChange={e => setContactType("manual")}
                />
                <span>Load contacts manually</span>
                
              </div>
            }
            <div>

              <input

                checked={contactType === "excel"}
                name="manual"
                type="radio"

                onChange={e => setContactType("excel")}
              />
              <span>Load contacts from excel</span>
              
            </div>
            <div>

              <input

                checked={contactType === "phonebook"}
                name="phonebook"
                type="radio"
                onChange={e => setContactType("phonebook")}
              />
              <span>Load contacts from phone book</span>
            </div>

          </div>

          {(subType === "normal" && contactType === "excel") && <div className="excel1"><img style={{ width: '200px' }} src="../assets/images/numbers.jpg" alt="excel" />
            <span> The column containing the numbers must have "numbers" as the heading as seen in the image above. The heading is the first cell of the column (on the first row).
            </span></div>}
          {(subType === "personalized" && contactType === "excel" && messageType !== "birthday") && <div className="excel1"><img src="../assets/images/excel.jpg" alt="excel" />
            <span> Keep all headings on the first Row of your excel sheet. One heading per column. You can give your headings any name but heading for mobile numbers should be "numbers" as seen in the above image. You can have as many headings as required
            </span></div>}
          {(subType === "personalized" && contactType === "excel" && messageType === "birthday") && <div className="excel1"><img src="../assets/images/birthday.jpg" alt="excel" />
            <span> Keep all headings on the first Row of your excel sheet. One heading per column. You can have as many headings as required. Give your headings any name but heading for mobile numbers should be "numbers". For birthday day messages, place the birthdate under column with birthday as heading. A sample has been shown in image above.
            </span></div>}
          {(subType === "personalized" && contactType === "phonebook") && <div className="excel1"><img src="../assets/images/contacts.jpg" alt="contacts" /><span>sample phone book data</span></div>}

          {contactType === "manual" ? (
            <>
              <label>Mobile Numbers (Incase of duplicate numbers, only one will apply)</label>
              <textarea onChange={mobileOnChange} rows="8" required placeholder='Enter comma separated mobile numbers eg. 0599010105, 599010103,233599010104 or copy and paste mobile numbers.' value={destinationAddress} />
            </>
          ) : (
            contactType === "excel" ? (
              <>
                <div className="excel-mobile">
                  <input
                    style={{ cursor: 'pointer' }}
                    type="file"

                    onChange={(e) => {
                      setFiles(e.target.files);
                      handleReadExcel(e.target.files);
                    }}
                  />
                  <div
                    style={{ backgroundColor: 'green', color: 'white' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      document.querySelector('.excel-mobile>input[type="file"]').click();
                    }}
                  >
                    Load excel contacts
                  </div>
                </div>

                {(excelSheetNames.length > 1) &&
                  <>
                    <label>Select excel sheet</label>
                    <select
                      value={selectedExcelSheet}
                      onChange={handlerExcelSheetSelector}>
                      {["-- Select sheet --", ...excelSheetNames].map((sheet, i) => (
                        <option key={i} value={sheet}>{sheet}</option>
                      ))}
                    </select>
                  </>
                }


                {subType === "personalized" ? (
                  <>
                    <label>Mobile Numbers</label>
                    <textarea onChange={mobileOnChange} rows="8" required placeholder='For loading numbers from excel sheet, first row of the excel sheet should contain a heading with the name "numbers" (in any column). Mobile numbers will be placed in subsequent rows under the column with "numbers" as it`s heading. See image above' value={destinationAddress} />
                  </>
                ) : (
                  <>
                    <label>Mobile Numbers (Incase of duplicate numbers, only one will apply)</label>
                    <textarea onChange={mobileOnChange} rows="8" required placeholder='For loading numbers from excel sheet, first row of the excel sheet should contain a heading with the name "numbers" (in any column). Mobile numbers will be placed in subsequent rows under the column with "numbers" as it`s heading. See image above' value={destinationAddress} />
                  </>
                )}
              </>
            ) : (
              <GroupSelect setErrorArray={setErrorArray} setGroupContact={setGroupContact} groupContact={groupContact} setPersonalizedFilter={setPersonalizedFilter} />
            ))}
          <div className="recipients">
            <div>Number of recipients :{recipients}</div>
          </div>

          <label>Message Description / Campaign Name (optional)</label>
          <input type="text" autoComplete="off" value={messageDescription} placeholder="Enter campaign name (optional)" onChange={e => setMessageDescription(e.target.value)} />
          <div style={{ display: 'flex', marginTop: '10px', marginLeft: '5%', marginRight: '5%', justifyContent: 'space-between' }}>
            <label style={{ fontWeight: 'bold', marginBottom: '5px', alignSelf: 'flex-start', borderBottom: 'solid 0.5px' }}>Sender ID </label>
          </div>
          {/*<input type="text" required  autoComplete="off" value={sourceAddress} placeholder="Enter Sender ID"  onChange={e => setSourceAddress(e.target.value)} />*/}
          <select
            value={sourceAddress}
            onChange={e => setSourceAddress(e.target.value.trim())}>
            {["-- select Sender ID --", ...userInfo.senderidapproved.split(",")].filter(x => x.trim() !== "").map((appsid, i) => (
              <option key={i} value={appsid}>{appsid}</option>
            ))}
          </select>
          {/* comment out to auto set unicode
          <label>Encode type</label>

          <div className="radio-buttons">
            <div>

              <input

                checked={!specialUnicodeSupport}
                name="normal"
                type="radio"

                onChange={e => setSpecialUnicodeSupport(false)}
              />
              <span>
              Normal(GSM7)
              </span>
            </div>

            <Tooltip describeChild title="Supports Chinese characters, Latin, Arabic characters etc. Also suporrts special characters like etc. Charges might be higher than normal sms">
              <div>

                <input

                  checked={specialUnicodeSupport}
                  name="unicode"
                  type="radio"
                  onChange={e => setSpecialUnicodeSupport(true)}
                />
                <span>
                Unicode
                </span>
              </div>
            </Tooltip>

          </div>
            */}
          {(subType === "personalized" && personalizedFilter.length > 0) &&
            <>
              <label>Select a filter to personalize message</label>
              <select
                value="-- Select filter --"
                onChange={filterHandler}>
                {["-- Select filter --", ...personalizedFilter].map((appsid, i) => (
                  <option key={i} value={appsid}>{appsid}</option>
                ))}
              </select>
            </>
          }
          {/* flash select goes here */}
          {/* 
          <label>Flash messaging<span><div className="message-warning">Kindly note that some devices do not support flash messaging</div></span></label>

          <div className="radio-buttons">
            <div>

              <input

                checked={!flash}
                type="radio"

                onChange={e => setFlash(false)}
              />
              <span>
              Disable flash
              </span>
            </div>
            <div>
              <input
                checked={flash}
                type="radio"
                onChange={e => setFlash(true)}
              />
              <span>
              Enable flash
              </span>
            </div>

          </div>
          */}
          <label>Enter message <span><div className="message-warning">Kindly first send a sample message to yourself before sending to bulk numbers</div></span></label>
          {(subType === "personalized" && contactType === "excel" && messageType !== "birthday") && <textarea id="message-textarea" onChange={handleShortMessageChange} required rows="8" placeholder="Enter messge.To personalized SMS as per the columns in the above image, place them in between {}. eg. Hello {col1} your id is {col2}. as per the above image this will send to John, 'Hello John your id is 220' and to Mark, 'Hello Mark your id is 320'" value={shortMessage} />}
          {(subType === "personalized" && contactType === "excel" && messageType === "birthday") && <textarea id="message-textarea" onChange={handleShortMessageChange} required rows="8" placeholder="Enter messge.To personalized SMS as per the columns in the above image, place them in between {}. eg. Happy {age}th birthday {col1}. as per the above image this will send to John, 'Happy 36th birthday John, and to Mark, 'Happy 34th birthday Mark'. Your age is automatically calculated and be accessed by {age}" value={shortMessage} />}
          {(subType === "personalized" && contactType === "phonebook" && messageType !== "birthday") && <textarea id="message-textarea" onChange={handleShortMessageChange} required rows="8" placeholder="Enter messge. To personalized SMS as per the columns in the above image, place them in between {}. eg. Hello {firstname} {surname} your phone number is {mobile}. This will send to Kojo,'Hello Kojo Christian your phone number is 0598939596' and to Kwaku, 'Hello Kwaku Alano your phone number is 0296759597'." value={shortMessage} />}
          {(subType === "personalized" && contactType === "phonebook" && messageType === "birthday") && <textarea id="message-textarea" onChange={handleShortMessageChange} required rows="8" placeholder="Enter messge. To personalized SMS as per the columns in the above image, place them in between {}. eg. Happy {age}th birthday {firstname}. This will send to Kojo,'Happy 36th birthday Kojo' and to Kwaku, 'Happy 34th birthday'. Your age is automatically calculated and be accessed by {age}" value={shortMessage} />}          
          {subType === "normal" && <textarea id="message-textarea" onChange={handleShortMessageChange} required rows="8" placeholder="Enter messge" value={shortMessage} />}

          <div className="message-count">
            <div>Character count: <span>{characterCount}/{characterDenominator}</span></div>
            <div>Message count: <span>{messagecount1}</span> </div>
          </div>

          {messageType === "scheduled" && (
            <><label>Set delivery time </label>
              <div className="datetimepicker">
                <DatePicker
                  selected={expectedDeliveryTime}
                  onChange={(val) => setExpectedDeliveryTime(val)}
                  showIcon
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <div className="timezone">{timezonedisplay}</div>
            </>)}
            {messageType === "birthday" && contactType==="manual" && (
            <><label>Set birthday </label>
              <div className="datetimepicker">
                <DatePicker
                  selected={expectedDeliveryTime}
                  onChange={(val) => setExpectedDeliveryTime(val)}
                  showIcon 
                  dateFormat="MMMM d, yyyy"
                />
              </div>
              <div className="timezone">{timezonedisplay}</div>
            </>)}
          {messageType === "recurring" && (
            <><label>Initial delivery time </label>
              <div className="datetimepicker">
                <DatePicker
                  selected={expectedDeliveryTime}
                  onChange={(val) => setExpectedDeliveryTime(val)}
                  showIcon
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <div className="timezone">{timezonedisplay}</div>
              <label>Choose Recurring Period </label>
              <select
                value={recurringvalue}
                onChange={e => setRecurringvalue(e.target.value)}>
                {recurringOption.map((ro, i) => (
                  <option key={i} value={ro.value}>{ro.label}</option>
                ))}
              </select>
              <div className="recurringspanmessge">
                <span>{period}</span>
              </div>
              <input value={recurringInterval} onChange={(e) => setRecurringInterval(e.target.value)} type="text" autocomplete="off" required />
              <div className="recurringspan">
                <span>{typeofperiod}</span>
              </div>
            </>)}

          <button disabled={disablebutton}>{buttonText}</button>
        </form>
      </div>

    </>
  )
};

const GroupSelect = (props) => {

  const { setErrorArray, setGroupContact, groupContact, setPersonalizedFilter } = props;
  const { groups, error } = useSelector(state => state.groups);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);

  useEffect(() => {
    dispatch(groupsrequest());
    return () => {
      mountedRef.current = false;
    }
  }, []);


  useEffect(() => {
    if (error) {

      setErrorArray((prev) => {
        return [...prev, error];
      });

    }
  }, [error]);

  const onChangeHander = (e) => {
    setGroupContact(e.target.value);
  }



  return (
    <>
      <label>Choose a phone book</label>
      <select
        value={groupContact}
        onChange={onChangeHander}>
        {[{ groupname: "-- select Phone Book --" }, ...groups].map(group => (
          <option key={uuidv4()} value={group.groupname}>{group.groupname.toUpperCase()}</option>
        ))}
      </select>
    </>
  )
};