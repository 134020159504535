import axios from "axios";
import { USER_SIGNUP_FAIL, USER_SIGNUP_REQUEST, USER_SIGNUP_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_FETCH_SUCCESS, USER_FETCH_FAIL, USER_LOGIN_SUCCESS, USER_SIGNOUT, USER_FETCH_REQUEST, USER_CREDIT_UPDATE_SUCCESS, USER_CREDIT_UPDATE_REQUEST, USER_CREDIT_UPDATE_FAIL, USER_REQUESTING_SENDERID_FAIL, USER_REQUESTING_SENDERID_SUCCESS, USER_REQUESTING_SENDERID_REQUEST, USER_FETCH_ALL_FAIL, USER_FETCH_ALL_SUCCESS, USER_FETCH_ALL_REQUEST, USER_APPROVING_SENDERID_REQUEST, USER_APPROVING_SENDERID_SUCCESS, USER_APPROVING_SENDERID_FAIL, USER_REJECTING_SENDERID_REQUEST, USER_REJECTING_SENDERID_SUCCESS, USER_REJECTING_SENDERID_FAIL, USER_ACCOUNTTYPE_REQUEST, USER_ACCOUNTTYPE_SUCCESS, USER_ACCOUNTTYPE_FAIL, ALLUSER_CREDIT_UPDATE_FAIL, ALLUSER_CREDIT_UPDATE_SUCCESS, ALLUSER_CREDIT_UPDATE_REQUEST, ALL_STATUS } from "../constants/userConstants";

export const login = (emailusername, password, supid, humanKey) => async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST });
    let domain = window.location.hostname;
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/login', { emailusername, password, domain_supid:supid, humanKey, domain });

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
        if (data.userInfo) {

            localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
        }
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};

export const signup = (id) => async (dispatch) => {
    dispatch({ type: USER_SIGNUP_REQUEST });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/signup', { id });
        dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
        }
    } catch (error) {
        dispatch({
            type: USER_SIGNUP_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};

export const signout = () => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();


    try {
        await axios.post('/smsgateway/api/frontend/signout', { profile_id: userInfo.profile_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });

    } catch (error) {

    }
    dispatch({ type: USER_SIGNOUT });
    localStorage.removeItem('userInfo');   
};


export const fetchUser = () => async (dispatch, getState) => {
    dispatch({ type: USER_FETCH_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const response = await axios.post('/smsgateway/api/frontend/fetchuser', { profile_id: userInfo.profile_id }, {

            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        const { data } = response;
        dispatch({ type: USER_FETCH_SUCCESS, payload: data });
         
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));
        }

        if (data.message && data.message === "Invalid Token") {
            dispatch(signout());
        }

    } catch (error) {    
        dispatch({
            type: USER_FETCH_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
        dispatch(signout()); 
    }

};


export const allusers_funct = () => async (dispatch, getState) => {
    dispatch({ type: USER_FETCH_ALL_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/allusers', { profile_id: userInfo.profile_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });

        dispatch({ type: USER_FETCH_ALL_SUCCESS, payload: data });
    } catch (error) {
        /*
        dispatch({
            type: USER_FETCH_ALL_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
        */
    }

};

//change account type
export const changeaccountype = (accountType) => async (dispatch, getState) => {
    dispatch({ type: USER_ACCOUNTTYPE_REQUEST })
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/changeaccountype', { profile_id: userInfo.profile_id, accountType }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });

        dispatch({ type: USER_ACCOUNTTYPE_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));
        }
    } catch (error) {

        dispatch({
            type: USER_ACCOUNTTYPE_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });

    }

};




//action for requesting sender ID

export const senderidrequest = (pendingsenderid) => async (dispatch, getState) => {
    dispatch({ type: USER_REQUESTING_SENDERID_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/senderidrequest', { profile_id: userInfo.profile_id, pendingsenderid }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_REQUESTING_SENDERID_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));

        }

    } catch (error) {

        dispatch({
            type: USER_REQUESTING_SENDERID_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });

    }

};


//action for approving sender ID

export const approvesenderid = (id, pendingsenderid) => async (dispatch, getState) => {
    dispatch({ type: USER_APPROVING_SENDERID_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/approvesenderid', { profile_id: userInfo.profile_id, id, pendingsenderid }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }

        });

        dispatch({ type: USER_APPROVING_SENDERID_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));

        }

    } catch (error) {
        dispatch({
            type: USER_APPROVING_SENDERID_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });

    }

};

//action for rejecting sender ID

export const rejectsenderid = (id, pendingsenderid) => async (dispatch, getState) => {
    dispatch({ type: USER_REJECTING_SENDERID_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/rejectsenderid', { profile_id: userInfo.profile_id, id, pendingsenderid }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_REJECTING_SENDERID_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));

        }

    } catch (error) {
        dispatch({
            type: USER_REJECTING_SENDERID_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });

    }

};


export const paymentgatewaycreditpurchase = (amount, currency, transactionid) => async (dispatch, getState) => {
    dispatch({ type: USER_CREDIT_UPDATE_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/paymentgatewaycreditpurchase', { profile_id: userInfo.profile_id, amount, currency, transactionid }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_CREDIT_UPDATE_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));
        }

    } catch (error) {
        dispatch({
            type: USER_CREDIT_UPDATE_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }

};


export const manualcreditpurchase = (amount, currency, clientprofile_id) => async (dispatch, getState) => {
    dispatch({ type: ALLUSER_CREDIT_UPDATE_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/manualcreditpurchase', { profile_id: userInfo.profile_id, amount, currency, clientprofile_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });



        dispatch({ type: ALLUSER_CREDIT_UPDATE_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));
        }

    } catch (error) {
        dispatch({
            type: ALLUSER_CREDIT_UPDATE_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }
};

export const directcreditpurchase = (amount, rate, currency, clientprofile_id) => async (dispatch, getState) => {
    dispatch({ type: ALLUSER_CREDIT_UPDATE_REQUEST });
    const { user: { userInfo } } = getState();

    try {
        const { data } = await axios.post('/smsgateway/api/frontend/directcreditpurchase', { profile_id: userInfo.profile_id, amount, rate, currency, clientprofile_id }, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });



        dispatch({ type: ALLUSER_CREDIT_UPDATE_SUCCESS, payload: data });
        if (data.userInfo) {
            localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), ...data.userInfo }));
        }

    } catch (error) {
        dispatch({
            type: ALLUSER_CREDIT_UPDATE_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status: "failed"
            }
        });
    }
};


export const getAllUsers = (page) => async (dispatch, getState) => {
    const { user: { userInfo } } = getState();
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/numbers/', { pageNum:page, profile_id: userInfo.profile_id } ,{
                headers: { Authorization: `Bearer ${userInfo.token}` }});

        dispatch({ type: ALL_STATUS, payload: {...data, clientPage :(page + 1) }});
    } catch (error) {
        console.log(error);
    }
};

