import React, { useState } from 'react';
import {   Route } from 'react-router-dom';

import LocalRecurringScreen from '../localrecurring/LocalRecurringScreen';
import ClientRecurringScreen from '../clientrecurring/ClientRecurringScreen';


import ScreenTabs from '../../components/ScreenTabs';

export default function RecurringScreen(props) {

     const buttonText1 = "P";
    const tabLink1 = "/recurringsms";
    const buttonText2 = "C";
    const tabLink2 = "/clientrecurringsms";

    const activeBtn = props.location.pathname === "/clientrecurringsms" ? buttonText2 : buttonText1;

    const [activeTab, setActiveTab] = useState(activeBtn );

  const toggleActiveTab = () => activeTab === buttonText1 ?
        setActiveTab(buttonText2) :setActiveTab(buttonText1);
    const onClickHandler = (e) => {
          e.preventDefault();
        toggleActiveTab();
    };
  

    return (
        
            <div className="recurringsms">
                <ScreenTabs buttonText1={buttonText1} buttonText2={buttonText2} tabLink1={tabLink1} tabLink2={tabLink2} activeTab={activeTab} onClickHandler={onClickHandler} />
                {/*search bar */}
              
                 <Route path="/clientrecurringsms" component={ClientRecurringScreen}></Route>
                 <Route path="/recurringsms"  component={LocalRecurringScreen} exact ></Route>  
                   
            </div>
       
    )
}
