import React from 'react';
import { useDispatch } from 'react-redux';
import { deletecontact } from '../actions/groupsAction';
import { EditModal } from './EditModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

export default function ContactCard(props) {
    const contact = props.contact;
    const showEdit = props.showEdit;
    const groupid = props.groupid;

    const dispatch = useDispatch();

    const deleteHandler = (e) => {
        e.preventDefault();
        dispatch(deletecontact(groupid, [contact.id]));
    }

    return (
        <div className="contact-wrap">
            <div className="contact-icon-containers">
                {showEdit &&
                    <div title="Edit" className="edit-icon">
                        <EditModal groupid={groupid} contact={contact} />
                    </div>
                }
                {showEdit &&
                    <div className="delete-icon" title="Delete contact">
                        <IconButton onClick={deleteHandler}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                }
            </div>
           
            <div className="firstname-contact">
                First Name: <span>{contact.firstname}</span>
            </div>
            <div className="middlename-contact">
                Middle name: <span>{contact.middlename}</span>
            </div>
           
            <div className="surname-contact">
                Surname: <span>{contact.surname}</span>
            </div>
           
            <div className="phonenumber-contact">
                Mobile Number: <span>{contact.phonenumber}</span>
            </div>
           
            <div className="birthday-contact">
                Birthdate: <span>{contact.birthday ? new Date(contact.birthday).toISOString().slice(0, 10):""}</span>
            </div>
           
        </div>
    )
}
