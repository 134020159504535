import "./sidebar.css";

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarLink from "./SidebarLink";
import { Link } from 'react-router-dom';

import { Dashboard, Textsms, ContactPhone, Schedule, Repeat, ShopTwo, ShoppingCart, Person, People, Phone, WhatsApp } from '@mui/icons-material';
import { useState } from "react";
import { signout } from "../../actions/userAction";





export default function Sidebar(props) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const user = useSelector(state => state.user);
  const { userInfo, allusers } = user;
  const { domainDetails } = useSelector(state => state.domainDetails);
  const [activeTab, setActiveTab] = useState("/");
  const [showDashboardTab, setShowDashboardTab] = useState(false);
  const [showScheduledSMSTab, setShowScheduledSMSTab] = useState(false);
  const [showRecurringSMSTab, setShowRecurringSMSTab] = useState(false);
  const [showCreditHistoryTab, setShowCreditHistoryTab] = useState(false);

  let width = props.windowDimensions.width;

  const dispatch = useDispatch();

  const logOutHandler = (e) => {
    e.preventDefault();
    dispatch(signout());
  };
  useEffect(() => {
    if(props){
      if(props.activepath === "/login" || props.activepath === "/signup"){
        setActiveTab("/");
      }else if(props.activepath.includes("clientdashboard") || props.activepath.includes("dashboard")){
        setActiveTab("/dashboard");
      }else if(props.activepath.includes("clientscheduledsms") || props.activepath.includes("scheduledsms")){
        setActiveTab("/scheduledsms");
      }else if(props.activepath.includes("clientrecurringsms") || props.activepath.includes("recurringsms")){
        setActiveTab("/recurringsms");
      }else if(props.activepath.includes("clientphonebook") || props.activepath.includes("phonebook")){
        setActiveTab("/phonebook");
      }else if(props.activepath.includes("clientcredithistory") || props.activepath.includes("credithistory")){
        setActiveTab("/credithistory");
      }else{
        setActiveTab(props.activepath);
      }
    }
    
  }, []);
  
  useEffect(() => {
    if(userInfo){
      setShowDashboardTab(userInfo?.dashboardTab);
      setShowScheduledSMSTab(userInfo?.scheduledsmsTab);
      setShowRecurringSMSTab(userInfo?.recurringsmsTab);
      setShowCreditHistoryTab(userInfo?.credithistoryTab);
    }
  }, [userInfo]);

  const listOfUserWithPendingSenderID = [];
  for (let i = 0; i < allusers.length; i++) {
    if (allusers[i].pendingsenderid === null) {
      continue
    }

    if (allusers[i].pendingsenderid.trim() !== "") {
      let x = allusers[i].pendingsenderid.split(",");
      for (let j = 0; j < x.length; j++) {
        if (x[j].trim() !== "") {
          listOfUserWithPendingSenderID.push(allusers[i].username)
        }
      }
    }
  }

  let style;
  listOfUserWithPendingSenderID.length > 0 ? style = { 'background': 'red' } : style = { 'background': 'green' }

  return (
    <>
      {width < 1000 &&
        <div className="header-menu">
          <>
            <div className="creditexpires">
              <span className="text"> Credit expires: {new Date(userInfo.creditexpires * 1000).toLocaleString("en-US", { timeZone: "Africa/Accra" })} </span>
            </div>
            
            <div className="contact">
              <Phone color="action" sx={{ fontSize: 10 }} />
              <WhatsApp color="success" sx={{ fontSize: 10 }} />


              <span className="text">{' '} {domainDetails.telnumber}</span>
            </div>
            {userInfo.isAdmin && listOfUserWithPendingSenderID.length > 0 ? (
              <div className="senderid" style={style} title={`${listOfUserWithPendingSenderID.length} senderid(s) pending for approval or rejection`}>
              </div>
            ) : (<div className="senderid" style={style} title="No senderid(s) pending for approval or rejection">
            </div>)}

          </>

        </div>}
      <div className="sidebar">
        <Link to={"/"}><SidebarLink link={"/"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={Textsms}>Send SMS</SidebarLink></Link>
        {showDashboardTab && 
        <Link to={"/dashboard"}><SidebarLink link={"/dashboard"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={Dashboard}>Dashboard</SidebarLink></Link>
        }
        {showScheduledSMSTab && 
        <Link to={"/scheduledsms"} ><SidebarLink link={"/scheduledsms"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={Schedule}>Scheduled SMS</SidebarLink></Link>
        }
        {showRecurringSMSTab && 
        <Link to={"/recurringsms"} ><SidebarLink link={"/recurringsms"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={Repeat} >Recurring SMS</SidebarLink></Link>
        }
        <Link to={"/phonebook"} ><SidebarLink link={"/phonebook"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={ContactPhone} >Phone book</SidebarLink></Link>
        <Link to={"/purchasesms"}><SidebarLink link={"/purchasesms"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={ShoppingCart} >Purchase SMS credits </SidebarLink></Link>
        {showCreditHistoryTab && 
        <Link to={"/credithistory"}><SidebarLink link={"/credithistory"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={ShopTwo} >SMS credits purchase history</SidebarLink></Link>
        }
        <Link to={"/profile"}><SidebarLink link={"/profile"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={Person} >Profile</SidebarLink></Link>
        {userInfo.isAdmin ? (<Link to={"/clientmanagement"} ><SidebarLink link={"/clientmanagement"} activeTab={activeTab} setActiveTab={setActiveTab} Icon={People} >Client management</SidebarLink></Link>) : <></>}
      </div>
      {width < 1000 &&
        <div className="logout-button">
          <button onClick={logOutHandler}>Log out </button>
        </div>
      }

    </>
  );
}
