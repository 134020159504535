export const FETCH_PURCHASED_CREDIT_HISOTRY_REQUEST = 'FETCH_PURCHASED_CREDIT_HISOTRY_REQUEST';
export const FETCH_PURCHASED_CREDIT_HISOTRY_SUCCESS = 'FETCH_PURCHASED_CREDIT_HISOTRY_SUCCESS';
export const FETCH_PURCHASED_CREDIT_HISOTRY_FAIL = 'FETCH_PURCHASED_CREDIT_HISOTRY_FAIL';


export const FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST = 'FETCH_ALL_PURCHASED_CREDIT_HISOTRY_REQUEST';
export const FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS = 'FETCH_ALL_PURCHASED_CREDIT_HISOTRY_SUCCESS';
export const FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL = 'FETCH_ALL_PURCHASED_CREDIT_HISOTRY_FAIL';

export const CLEAN_PURCHASE_CREDIT_HISTORY_SUCCESS ="CLEAN_PURCHASE_CREDIT_HISTORY_SUCCESS"
export const CLEAN_ALL_PURCHASE_CREDIT_HISTORY_SUCCESS = "CLEAN_ALL_PURCHASE_CREDIT_HISTORY_SUCCESS"

export const DELETE_CREDIT_ERROR = 'DELETE_CREDIT_ERROR';