import React, { useEffect, useState } from 'react';
import ScreenTabs from '../../components/ScreenTabs';
import { Route} from 'react-router-dom';

import LocalDashboardScreen from '../localdashboard/LocalDashboardScreen';
import ClientDashboardScreen from '../clientdashboard/ClientDashboardScreen';


export default function DashboardScreen(props) {

    const buttonText1 = "P";
    const tabLink1 = "/dashboard";
    const buttonText2 = "C";
    const tabLink2 = "/clientdashboard";

    const activeBtn = props.location.pathname === "/clientdashboard" ? buttonText2 : buttonText1;

    const [activeTab, setActiveTab] = useState(activeBtn);

  const toggleActiveTab = () => activeTab === buttonText1 ?
        setActiveTab(buttonText2) :setActiveTab(buttonText1);
    const onClickHandler = (e) => {
          e.preventDefault();
        toggleActiveTab();
    };

  

    return (
         <div className="dashboard">
         <ScreenTabs buttonText1={buttonText1} buttonText2={buttonText2} tabLink1={tabLink1} tabLink2={tabLink2} activeTab={activeTab} onClickHandler={onClickHandler} />
         <Route path="/clientdashboard" component={ClientDashboardScreen} ></Route>
         <Route path="/dashboard" component={LocalDashboardScreen} exact></Route>   
         </div>

    )
}
