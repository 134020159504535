export const getStoredAPIdata = async (url) => {
    let cache_url_key = url
    const cache = await caches.open(`${window.location.hostname}-data-cache-v1`);
    const cachedResponse = await cache.match(cache_url_key);
    if(cachedResponse){
      const expirationDate = Number(cachedResponse.headers.get('cache-expiration'));
      if (expirationDate && Date.now() > expirationDate) {
          // Cached response is expired, delete it
          await cache.delete(cache_url_key);
          return null
      }
      return await cachedResponse.json()
    }
    return null
  
  }