import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '../../components/EnhancedTable';
import LoadingBox from '../../components/LoadingBox';
import Alerts from '../../components/mainapp/Alerts';
import { DELETE_SMS_ERROR, UPDATE_MESSAGE_STATUS } from '../../constants/bulksmsConstants';
import SearchField from "react-search-field";
import { v4 as uuidv4 } from 'uuid';
import ReactExport from 'react-data-export';
import axios from 'axios';
import useEffectAsync from '../../components/useEffectAsync';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;;

export default function DashboardDetailsScreen(props) {

  const [errorArray, setErrorArray] = useState([]);
  const [messageStatus, setMessageStatus] = useState([]);
  const [messageStatusOG, setMessageStatusOG] = useState([])
  const [search, setSearch] = useState('');

  const { bulksms, error, loading } = useSelector(state => state.bulksms);
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  const mountedRef = useRef(true)

  const dispatch = useDispatch();


  useEffect(() => {
    // dispatch(quickrequest(props.match.params.id, 1));
    return () => {
      mountedRef.current = false
    }
  }, []);



  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });

      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
      }, 5000)
    }
  }, [error]);

  useEffectAsync(async () => {
    if (search) {

      setMessageStatus(messageStatusOG.filter(x => {
        for (const [key, value] of Object.entries(x)) {

          if (key !== "statusnumber") {
            if (key !== "referencekey") {

              if (value.toLowerCase().includes(search.toLowerCase())) {
                return true
              }
            }
          }
        };
        return false
      })
      )
    } else {
      if (messageStatusOG.length) {
        setMessageStatus(bulksms.filter(x => x.randomAlpha === props.match.params.id).messageStatus)
        return
      }
      try {
        let res = await axios.post(`/smsgateway/api/frontend/status/${props.match.params.id}`, { profile_id: userInfo.profile_id }, {
          headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        if (res.data.error) {
          let id = uuidv4()
          res.data.error.id = id;
          setErrorArray((prev) => {
            return [...prev, res.data.error];
          });
          setTimeout(() => {
            if (!mountedRef?.current) return null
            setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
            });
          }, 5000)
        } else {
          setMessageStatus(res.data)
          setMessageStatusOG(res.data)
          dispatch({ type: UPDATE_MESSAGE_STATUS, payload: { randomAlpha: props.match.params.id, updatedBulksms: { ...bulksms.filter(x => x.randomAlpha === props.match.params.id)[0], messageStatus: res.data } } });
        }
      } catch (error) {
        let id = uuidv4()
        error.id = id;
        setErrorArray((prev) => {
          return [...prev, error];
        });

        setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });
        }, 5000)
      }

      /*
      if (bulksms.length > 0) {
        setMessageStatus(prev => {
          let x = []
          let b = bulksms.filter(x => x.randomAlpha === props.match.params.id)
          if (b.length > 0) {
            for (let i = 0; i < b.length; i++) {
              x.push(...b[i].messageStatus)
            }
          }
          return [...prev, ...x]
        })
      }
      */
    }

  }, [search]);

  let sent = 0;
  let failed = 0;
  let pending = 0;
  let totalSMS = 0;
  if (messageStatus.length > 0) {

    messageStatus.forEach(x => {
      if (x.status === "SENT") {
        sent++;
      } else if (x.status === "FAILED") {
        failed++;
      } else {
        pending++;
      }
      totalSMS = pending + sent + failed;

    });
  }


  useEffect(() => {
    setErrorArray([]);
    return () => {

      dispatch({ type: DELETE_SMS_ERROR });
    };

  }, []);

  const onChangeHandler = (val) => {
    setSearch(val);
  };

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: '',
    },
    {
      id: 'mobilenumber',
      numeric: true,
      disablePadding: false,
      label: 'Mobile number',
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
  ];


  const goBack = () => {
    window.history.back();
  };
  const headerTitle = "Message Status";

  let DataSet;

  if (messageStatus.length > 0) {

    DataSet = [
      {
        columns: Object.keys(messageStatus[0]).map(function (key, index) {
          return { title: key }
        }),
        data: messageStatus.map((data) => Object.keys(data).map(function (key, index) {
          return { value: data[key] }
        })
        )
      }
    ]
  }

  return (
    <>
      <div className="dashboard-details-screen">
        <div className="back">
          <button onClick={goBack}>Back</button>
        </div>

        {loading && <LoadingBox></LoadingBox>}
        <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_SMS_ERROR }]} />

        {messageStatus.length !== 0 ? (
          <div className="excel-report">
            <ExcelFile
              filename="message-status-report"
              element={<button type="button" className="btn btn-success float-right m-3">Export status report to excel</button>}>
              <ExcelSheet dataSet={DataSet} name="SMS Status Report" />
            </ExcelFile>
          </div>
        ) : <></>}
        <div className="dashboard-details">
          <div className="totalSubmitted" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }} >Total Message(s) Submitted : <span style={{ color: 'black', fontWeight: 'bold', fontSize: '14px' }}>{totalSMS}</span></div>
          <div className="totalSmsSent" style={{ display: 'flex', justifyContent: 'space-between' }} >Number of Sent Message(s): <span style={{ color: 'green', fontWeight: 'bold', fontSize: '14px' }}>{sent}</span></div>
          <div className="totalSmsPending" style={{ display: 'flex', justifyContent: 'space-between' }} >Number of Pending Message(s): <span style={{ color: 'gold', fontWeight: 'bold', fontSize: '14px' }}>{pending}</span></div>
          <div className="totalSmsFailed" style={{ display: 'flex', justifyContent: 'space-between' }} >Number of Failed Message(s): <span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>{failed}</span></div>
        </div>
        <div className="search">
          <SearchField
            placeholder="Search..."
            onChange={onChangeHandler}
            searchText=""
            classNames="test-class"
          />
        </div>
        {messageStatus.length > 0 &&
          <div className="table">
            <EnhancedTable headCells={headCells} rows={messageStatus} headerTitle={headerTitle} />
          </div>
        }
      </div>
    </>
  )
}

