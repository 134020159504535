import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState } from 'react';


export default function NormalPrice({ data, currency, deleteDataPrice, saveDataPriceToDB, type}) {
    const [price, setPrice] = useState(data.price);
    const [rate, setRate] = useState(data.rate);
    const [expiry, setExpiry] = useState(data.expiry);
    const { id } = data;
    const saveDataPrice = () => {    
        if (price === "" || rate === "" || rate === 0 || isNaN(rate) || isNaN(price)) {
            return; 
        }
        const data = {
            id,
            price,
            rate,
            expiry
        }
        saveDataPriceToDB(data, type);
    }

    const smscredits = () =>{
        if(isNaN(price) || isNaN(rate)){
            return 0;
        }
        if (rate === 0) {
          return 0;
        }
        return Math.ceil(price / rate)
    }
    return (
        <div className="normalprice-container" >
            <div className="normalprice-wrap" onMouseLeave={()=> saveDataPrice()} >
                <div className="price">
                    <div>Price: ({currency})</div> <input type="text" defaultValue={price} onChange={e => setPrice(e.target.value)} placeholder="Add price in GHS eg, 10" />
                </div>
                <div className="rate">
                    <div>Rate:</div> <input type="text"  defaultValue={rate} onChange={e => setRate(e.target.value)} placeholder="Add SMS rate per SMS, eg 0.024" />
                </div>
                <div className="sms-credits">
                    <div>SMS Credits:</div> <input type="text" value={smscredits()} readOnly />
                </div>
                <div className="expiry">
                    {type === "PREMIUM"?
                    (
                    <>
                    <div>Expiry:</div> <input type="text" defaultValue={expiry} onChange={e => setExpiry(e.target.value)} placeholder="Add expiry in years, eg 1" />
                    </>
                    ):(
                    <>
                    <div>Expiry:</div> <input style={{ boxShadow: 'none'}} type="text" defaultValue={expiry} readOnly />
                    </>
                    )
                    }
                    


                </div>

            </div>
            <div className="button-containers">
                <div className="delete-btn-price" title="Remove">
                <IconButton onClick={()=> deleteDataPrice(id, type)} >
                    <RemoveIcon />
                </IconButton>
                </div>
            </div> 
        </div>
    )
}
