export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';


export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAIL = 'USER_FETCH_FAIL';


export const USER_CREDIT_UPDATE_REQUEST = 'USER_CREDIT_UPDATE_REQUEST';
export const USER_CREDIT_UPDATE_SUCCESS = 'USER_CREDIT_UPDATE_SUCCESS';
export const USER_CREDIT_UPDATE_FAIL = 'USER_CREDIT_UPDATE_FAIL';



export const USER_FETCH_ALL_REQUEST = 'USER_FETCH_ALL_REQUEST';
export const USER_FETCH_ALL_SUCCESS = 'USER_FETCH_ALL_SUCCESS';
export const USER_FETCH_ALL_FAIL = 'USER_FETCH_ALL_FAIL';


export const USER_REQUESTING_SENDERID_REQUEST = 'USER_REQUESTING_SENDERID_REQUEST';
export const USER_REQUESTING_SENDERID_SUCCESS = 'USER_REQUESTING_SENDERID_SUCCESS';
export const USER_REQUESTING_SENDERID_FAIL = 'USER_REQUESTING_SENDERID_FAIL';


export const USER_APPROVING_SENDERID_REQUEST = 'USER_APPROVING_SENDERID_REQUEST';
export const USER_APPROVING_SENDERID_SUCCESS = 'USER_APPROVING_SENDERID_SUCCESS';
export const USER_APPROVING_SENDERID_FAIL = 'USER_APPROVING_SENDERID_FAIL';


export const USER_REJECTING_SENDERID_REQUEST = 'USER_REJECTING_SENDERID_REQUEST';
export const USER_REJECTING_SENDERID_SUCCESS = 'USER_REJECTING_SENDERID_SUCCESS';
export const USER_REJECTING_SENDERID_FAIL = 'USER_REJECTING_SENDERID_FAIL';

export const USER_ACCOUNTTYPE_REQUEST ="USER_ACCOUNTTYPE_REQUEST";
export const USER_ACCOUNTTYPE_SUCCESS="USER_ACCOUNTTYPE_SUCCESS";
export const USER_ACCOUNTTYPE_FAIL = "USER_ACCOUNTTYPE_FAIL";

export const ADMIN_ACCOUNTTYPE_REQUEST ="ADMIN_ACCOUNTTYPE_REQUEST";
export const ADMIN_ACCOUNTTYPE_SUCCESS="ADMIN_ACCOUNTTYPE_SUCCESS";
export const ADMIN_ACCOUNTTYPE_FAIL = "ADMIN_ACCOUNTTYPE_FAIL";
export const ALLUSER_CREDIT_UPDATE_REQUEST="ALLUSER_CREDIT_UPDATE_REQUEST";
export const ALLUSER_CREDIT_UPDATE_SUCCESS="ALLUSER_CREDIT_UPDATE_SUCCESS";
export const ALLUSER_CREDIT_UPDATE_FAIL="ALLUSER_CREDIT_UPDATE_FAIL";

export const ALL_STATUS = "ALL_STATUS";

export const CLEAN_ALLUSER_SUCCESS = "CLEAN_ALLUSER_SUCCESS";

export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const RESELLER_PRICE_REQUEST = 'RESELLER_PRICE_REQUEST';