import axios from "axios";
import { UPDATE_GROUP_REQUEST,UPDATE_GROUP_SUCCESS,UPDATE_GROUP_FAIL,CREATE_GROUP_REQUEST,CREATE_GROUP_FAIL,CREATE_GROUP_SUCCESS, DELETE_CONTACT_REQUEST,DELETE_CONTACT_SUCCESS, DELETE_CONTACT_FAIL,CREATE_CONTACT_REQUEST,CREATE_CONTACT_FAIL,UPDATE_CONTACT_REQUEST,UPDATE_CONTACT_SUCCESS,UPDATE_CONTACT_FAIL,
DELETE_GROUP_REQUEST,DELETE_GROUP_SUCCESS,DELETE_GROUP_FAIL,FETCH_GROUPS_REQUEST, FETCH_GROUPS_SUCCESS, FETCH_GROUPS_FAIL, FETCH_ALL_GROUPS_REQUEST ,FETCH_ALL_GROUPS_SUCCESS , FETCH_ALL_GROUPS_FAIL, CREATE_CONTACT_SUCCESS } from "../constants/groupsConstants";
 
 
/*

//ALL for icsms
export const ALLgroupsrequesticsms = () => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type:FETCH_ALL_GROUPS_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/ALLgroupsrequesticsms', {profile_id: userInfo.profile_id},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
        dispatch({ type:FETCH_ALL_GROUPS_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: FETCH_ALL_GROUPS_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};
*/

// fetch ALL groups
export const allgroupsx = ( search,pageNum) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type:FETCH_ALL_GROUPS_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/allgroups ', {profile_id: userInfo.profile_id,search, pageNum},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
        });
       
        dispatch({ type:FETCH_ALL_GROUPS_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: FETCH_ALL_GROUPS_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};


export const groupsrequest = () => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type: FETCH_GROUPS_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/groupsrequest', {profile_id: userInfo.profile_id},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
        dispatch({ type:FETCH_GROUPS_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: FETCH_GROUPS_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};

//create groups

export const creategroup = ( groupname) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type:CREATE_GROUP_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/creategroup', {profile_id: userInfo.profile_id,groupname},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
         //console.log(data);
        dispatch({ type:CREATE_GROUP_SUCCESS, payload: data });

      
    } catch (error) {
        dispatch({
            type: CREATE_GROUP_FAIL,
            payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};



export const updategroup = (id, groupname) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type:UPDATE_GROUP_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/createorupdategroup', {profile_id: userInfo.profile_id,id,groupname},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
        dispatch({ type:UPDATE_GROUP_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: UPDATE_GROUP_FAIL,
           payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};


//delete group

export const deletegroup = (arrayofgroups) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type: DELETE_GROUP_REQUEST });
    dispatch({ type:DELETE_GROUP_SUCCESS, payload: arrayofgroups});

    try {
       await axios.post('/smsgateway/api/frontend/deletegroup', {profile_id: userInfo.profile_id,arrayofgroups},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
    } catch (error) {
        dispatch({
            type: DELETE_GROUP_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};

//create contact


export const createcontactexel= (groupname, excelData) => async (dispatch,getState) => {
    const { user: { userInfo }} = getState();
  
  dispatch({ type:CREATE_CONTACT_REQUEST  });
  try {
      const { data } = await axios.post('/smsgateway/api/frontend/createcontactexel', {profile_id: userInfo.profile_id,groupname, excelData},{ 
          headers:{Authorization: `Bearer ${userInfo.token}`}
      });
      dispatch({ type:CREATE_CONTACT_SUCCESS, payload: data });
    
  } catch (error) {
      dispatch({
          type: CREATE_CONTACT_FAIL,
           payload: {
              message: error.response && error.response.data.message ? error.response.data.message : error.message,
              status:"failed"
          }
      });
    }
}

export const createcontact = (groupname, firstname, surname, email,phonenumber,birthday,middlename) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type:CREATE_CONTACT_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/createcontact', {profile_id: userInfo.profile_id,groupname, firstname, surname, email,phonenumber,birthday,middlename},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
        });
 
        dispatch({ type:CREATE_CONTACT_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: CREATE_CONTACT_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};



export const updatecontact = (groupid,id,firstname, surname,phonenumber,birthday,middlename, email) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type:UPDATE_CONTACT_REQUEST  });
    try {
        const { data } = await axios.post('/smsgateway/api/frontend/updatecontact', {profile_id: userInfo.profile_id,groupid,id,firstname, surname,phonenumber,birthday,middlename,email},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
         });
        dispatch({ type:UPDATE_CONTACT_SUCCESS, payload: data });
      
    } catch (error) {
        dispatch({
            type: UPDATE_CONTACT_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};





//delete contact

export const deletecontact = (groupid, arrayofContacts) => async (dispatch,getState) => {
      const { user: { userInfo }} = getState();
    
    dispatch({ type: DELETE_CONTACT_REQUEST });
   dispatch({ type: DELETE_CONTACT_SUCCESS, payload: {groupid, arrayofContacts}});
 
    try {  
       await axios.post('/smsgateway/api/frontend/deletecontact', {profile_id: userInfo.profile_id,arrayofContacts},{ 
            headers:{Authorization: `Bearer ${userInfo.token}`}
       });
        
    } catch (error) {
        dispatch({
            type: DELETE_CONTACT_FAIL,
             payload: {
                message: error.response && error.response.data.message ? error.response.data.message : error.message,
                status:"failed"
            }
        });
}

};
