import React, { useState, useRef } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deletescheduled, updatescheduled } from '../actions/bulksmsAction';

export default function ScheduledSMS({ sc, setErrorArray, personal = true}) {
  const user = useSelector(state => state.user);
  const { userInfo } = user;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const shortMessage = sc.shortMessage;
  const messageType = sc.messageType;



  return (
    <div className="scheduled">
      <div className="scheduled-flex">
        <div className="scheduled-details">
          {userInfo.isAdmin ? (
            <div className="scheduled-description">{sc.messageDescription} by {sc.username}</div>

          ) : (
            <div className="scheduled-description">{sc.messageDescription}</div>
          )}
          <div className="scheduled-senderId">{sc.sourceAddress}</div>
          <div className="scheduled-messageType">{sc.messageType}</div>
        </div>
        {personal &&
          <div className="scheduled-icon">
            <ScheduledEditModal sc={sc} setErrorArray={setErrorArray} />
            <ScheduledDeleteModal sc={sc} />


          </div>
        }
        <div className="scheduled-details">
          <div className="scheduled-messageRegTime">{new Date(sc.messageRegistrationTime).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</div>
          <div className="scheduled-unicodeSupport">{sc.specialUnicodeSupport ? "Message was encoded" : "Message was not encoded"}</div>

        </div>
      </div>
      <div className="scheduled-message">
        {shortMessage}
        { /*shortMessage.length > shortMessage.substring(0,200).length ? shortMessage.substring(0,200)+"..." : shortMessage*/}
      </div>
      {messageType === "scheduled sms" ? (
        <div className="scheduled-flex">

          <div className="scheduled-details">
            <div className="scheduled-deliveryTime">Expected delivery time {new Date(sc.expectedDeliveryTime).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</div>
          </div>
        </div>
      ) : (
        <div className="scheduled-flex">

          <div className="scheduled-details">

            <div className="scheduled-deliveryTime">Expected delivery time: Birthday of each recipients</div>

          </div>

        </div>
      )}
    </div>
  )
}


export const ScheduledEditModal = (props) => {
  const sc = props.sc

  const [messageDescription, setMessageDescription] = useState(sc.messageDescription);
  const [shortMessage, setShortMessage] = useState(sc.shortMessage);
  const [messageId, setMessageId] = useState(sc.message_id);
  const [expectedDeliveryTime, setExpectedDeliveryTime] = useState(new Date(sc.expectedDeliveryTime));

  const [sourceAddress, setSourceAddress] = useState(sc.sourceAddress);

  const [open, setOpen] = useState(false);
  const mountedRef = useRef(true);
  const [disablebutton, setDisablebutton] = useState(false);
  
  const { userInfo } = useSelector(state => state.user);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    if (expectedDeliveryTime.toString() === "Invalid Date") {
      let id = uuidv4()
      props.setErrorArray((prev) => {
        return [...prev, { id: id, message: "Please enter a valid date", status: "failed" }];
      });
      setDisablebutton(false)
      setTimeout(() => {
        if (!mountedRef?.current) return null
        props.setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });


      }, 5000)
      return;
    }
    dispatch(updatescheduled(messageId, shortMessage, sourceAddress, messageDescription, expectedDeliveryTime));
    handleClose();
  };

  return (
    < >
      <Edit onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal" >

          <form >
            <h2> Edit scheduled message</h2>

            <div style={{ color: '#003366', marginTop: '5px' }}>Message Description / Campaign Name (optional)</div>
            <input type="text" autoComplete="off" value={messageDescription} placeholder="Enter campaign name (optional)" onChange={e => setMessageDescription(e.target.value)} />

            <div style={{ color: '#003366', marginTop: '5px' }}>Sender ID </div>
            {/*<input type="text" required  autoComplete="off" value={sourceAddress} placeholder="Enter Sender ID"  onChange={e => setSourceAddress(e.target.value)} />*/}
            <select
              value={sourceAddress}
              onChange={e => setSourceAddress(e.target.value)}>
              {["-- select Sender ID --", ...userInfo.senderidapproved.split(",")].map((appsid, i) => (
                <option key={i} value={appsid}>{appsid}</option>
              ))}
            </select>

            <div style={{ color: '#003366', marginTop: '5px' }}>Edit message </div>
            <textarea onChange={(e) => setShortMessage(e.target.value)} required rows="4" placeholder="Edit messge" value={shortMessage} />



            <div style={{ color: '#003366', marginTop: '5px' }}>Set Delivery Time in GMT </div>
            <div className="datetimepicker">
              <DatePicker
                selected={expectedDeliveryTime}
                onChange={(val) => setExpectedDeliveryTime(val)}
                showIcon
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
            <Button  disabled={disablebutton} onClick={onSubmitHandler} variant="contained">Save changes</Button>
          </form>
        </div>


      </Modal>
    </>
  );
}


export const ScheduledDeleteModal = ({ sc }) => {


  const [open, setOpen] = useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const dispatch = useDispatch();


  const deleteHander = (e) => {
    e.preventDefault();
    dispatch(deletescheduled(sc.message_id));

  };

  return (
    < >
      <Delete onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal" >

          <form >
            <h2> Do you want to delete/stop schedule message?</h2>
            <div className="delete">
              <div>
                <Button onClick={deleteHander} variant="contained">Yes</Button>
              </div>
              <div>
                <Button onClick={handleClose} variant="contained">No</Button>
              </div>
            </div>
          </form>
        </div>


      </Modal>
    </>
  );
}