import './AppScreen.css';

import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import SendSMSScreen from '../sendsms/SendSMSScreen';
import DashboardScreen from '../dashboard/DashboardScreen';

import { Route, Switch } from 'react-router-dom';
import Sidebar from '../../components/mainapp/Sidebar';
import Header from '../../components/mainapp/Header';

import RecurringScreen from '../recurring/RecurringScreen';
import ScheduledScreen from '../scheduled/ScheduledScreen';
import PhoneBookScreen from '../phonebook/PhoneBookScreen';
import ProfileScreen from '../profile/ProfileScreen';
import ClientManagementScreen from '../clientmanagement/ClientManagementScreen';
import PurchaseCreditScreen from '../purchasecredit/PurchaseCreditScreen';
import PurchaseCreditHistoryScreen from '../purchasecredithistory/PurchaseCreditHistoryScreen';
import { CLEAN_ALLUSER_SUCCESS, DELETE_USER_ERROR } from '../../constants/userConstants';
import { allusers_funct, fetchUser } from '../../actions/userAction';
import { Alert } from '@mui/material';


import PhoneBookDetailsScreen from '../PhoneBookDetailsScreen/PhoneBookDetailsScreen';
import DashboardDetailsScreen from '../DashboardDetailsScreen/DashboardDetailsScreen';
import ClientDashboardDetailsScreen from '../DashboardDetailsScreen/ClientDashboardDetailsScreen';
import useEffectAsync from '../../components/useEffectAsync';



export default function AppScreen(props) {
  const bulksms = useSelector(state => state.bulksms);
  const { domainDetails } = useSelector(state => state.domainDetails);
  const user = useSelector(state => state.user);
  const { userInfo, error } = user;
  const [enablereferral, setEnablereferral] = useState(false);

  const [errorArray, setErrorArray] = useState([]);
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const mountedRef = useRef(true)

  const s = {
    'maxWidth': '200px',
    'padding': '0 10px',
    'margin': '0px 20px auto 10px',
    'boxShadow': '0 0 10px hsl(210 14% 90%)',
    'width': '25%',
    'display': 'flex',
    'flexDirection': 'column',
    'borderRadius': '10px',
  }
  const [style, setStyle] = useState(s);
  
  const dispatch = useDispatch();

  
  let welcomeMessge;
  if (userInfo && userInfo.content === "local" && enablereferral) {
    welcomeMessge = { message: `Welcome ${userInfo.username} to ${domainDetails.companyname} bulksms platform. Your reference code is ${userInfo.referralcode}. Share this code with friends and colleagues and have them register an account using this reference code and get ${domainDetails.referralpercentage*100}% on every sms credit they buy.`, status: "info" };
  }

  useEffect(() => { 
      if(Object.keys(domainDetails).length){
          if (userInfo.profile_id === domainDetails.profile_id) {
              setEnablereferral(domainDetails.supenablereferral);
          }else{
              setEnablereferral(domainDetails.enablereferral);
          }
      }
  } , [domainDetails]);

  useEffectAsync(async () => {
    if (!userInfo) {
      await new Promise(resolve => setTimeout(resolve, 100))
      if(window.location.hostname==="icsms.net"){
        props.history.push("/");
      }else{
        props.history.push("/login");
      }
      
    } 
  }, [userInfo]);
  
  useEffect(() => {

    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });
      
      setTimeout(() => {
          if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });
        
      }, 5000)
      
    }

  }, [error]);

  /*
  useEffect(() => {
    if (bulksms.error) {
      dispatch(fetchUser());
    }
  }, [bulksms.error]);
*/


  useEffect(() => {
    dispatch(fetchUser());
    if (userInfo.isAdmin) {
      dispatch(allusers_funct());
    }
   
    return () => { 
      mountedRef.current = false
    }
  }, []);



  useEffect(() => { 
    if (window.performance) {
      if (performance.navigation.type !== performance.navigation.TYPE_RELOAD) {
        //  if (performance.navigation.type !== performance.navigation.TYPE_NAVIGATE) {
        if (userInfo.content === "local" && enablereferral) {
          let id = uuidv4()
          welcomeMessge.id = id;
          setErrorArray((prev) => {
            return [...prev, welcomeMessge];
          });
          
          setTimeout(() => {
              if (!mountedRef?.current) return null
            setErrorArray(prev => {
              return prev.filter(x => x.id !== id);
            });
          }, 5000)
        }
        // }
      }
    }
    return () => {
      dispatch({ type: CLEAN_ALLUSER_SUCCESS });
      dispatch({ type: DELETE_USER_ERROR });
    };
  }, [enablereferral]);

  const handleChange = (e) => {
    e.stopPropagation()
    setToggleSidebar(prev => !prev)
    setStyle(prev => {
      return { ...prev, 'position': 'absolute', 'height': '85vh', 'zIndex': '2000', 'background': '#ddd', 'width': '50%' }
    })
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width < 1000) {
      setToggleSidebar(false)
    } else {
      setToggleSidebar(true)
    }

  }, [windowDimensions]);

  const handleClick = (e) => {
    e.stopPropagation()
    if (windowDimensions.width < 1000) {
      setToggleSidebar(false)
    }
  }

  const removeErrorMessage = (el, index) => {
    setErrorArray(prev => {
      return prev.filter((e, i) => i !== index);
    });
    dispatch({ type: DELETE_USER_ERROR });
  };

  
  window.onbeforeunload = function() {
    try{
      localStorage.removeItem('draftData');
      }catch(e){
        console.log(e)
    }
  }
 
 
  return (
    <>
      <div className="app" >

        <Header windowDimensions={windowDimensions} handleChange={handleChange} />

        <div className="section">
          {toggleSidebar && (
            <div style={style} onClick={handleClick}>
              <Sidebar activepath={props.location.pathname} windowDimensions={windowDimensions} />
            </div>
          )}
          <div className="section-main">
            <div className="message-app">

              {errorArray.length > 0 && errorArray.map((error, index) =>
                <Alert key={uuidv4()} onClose={() => { removeErrorMessage(error, index) }} variant="filled" severity={error.status === "failed" ? "warning" : error.status === "successful" ? "success" : error.status === "error" ? "error" : "info"}>{error.message}</Alert>
              )}
            </div>
            <Switch>
              <Route path="/clientdashboard/:id" component={ClientDashboardDetailsScreen} exact ></Route>
              <Route path="/clientdashboard" component={DashboardScreen} exact ></Route>
              <Route path="/clientscheduledsms" component={ScheduledScreen} exact></Route>
              <Route path="/clientrecurringsms" component={RecurringScreen} exact></Route>
              <Route path="/clientcredithistory" component={PurchaseCreditHistoryScreen} exact></Route>
              <Route path="/credithistory" component={PurchaseCreditHistoryScreen} exact></Route>
              <Route path="/purchasesms" component={PurchaseCreditScreen} exact></Route>
              <Route path="/clientmanagement" component={ClientManagementScreen} exact></Route>
              <Route path="/profile" component={ProfileScreen} exact></Route>
              <Route path="/phonebook/:id" component={PhoneBookDetailsScreen}></Route>
              <Route path="/phonebook" component={PhoneBookScreen} exact ></Route>
              <Route path="/scheduledsms" component={ScheduledScreen} exact></Route>
              <Route path="/recurringsms" component={RecurringScreen} exact></Route>
              <Route path="/clientphonebook/:id" component={PhoneBookDetailsScreen} exact></Route>
              <Route path="/clientphonebook" component={PhoneBookScreen} exact ></Route>
              <Route path="/dashboard/:id" component={DashboardDetailsScreen} exact ></Route>
              <Route path="/dashboard" component={DashboardScreen} exact></Route>
              <Route path="/" component={SendSMSScreen} exact></Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  )
}
