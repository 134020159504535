import React, { useState} from 'react';
import ScreenTabs from '../../components/ScreenTabs';
import { Route } from 'react-router-dom';

import LocalPurchaseCreditHistoryScreen from '../localpurchasecredithistory/LocalPurchaseCreditHistoryScreen';
import ClientPurchaseCreditHistoryScreen from '../clientpurchasecredithistory/ClientPurchaseCreditHistoryScreen';


export default function PurchaseCreditHistoryScreen(props) {

 const buttonText1 = "P";
    const tabLink1 = "/credithistory";
    const buttonText2 = "C";
    const tabLink2 = "/clientcredithistory";

    const activeBtn  = props.location.pathname === "/clientcredithistory" ? buttonText2 : buttonText1;

    const [activeTab, setActiveTab] = useState(activeBtn );

  const toggleActiveTab = () => activeTab === buttonText1 ?
        setActiveTab(buttonText2) :setActiveTab(buttonText1);
    const onClickHandler = (e) => {
          e.preventDefault();
        toggleActiveTab();
    };

 
    return (
      
            <div className="purchasesmshistory">
              <ScreenTabs buttonText1={buttonText1} buttonText2={buttonText2} tabLink1={tabLink1} tabLink2={tabLink2} activeTab={activeTab} onClickHandler={onClickHandler} />
              <Route path="/clientcredithistory" component={ClientPurchaseCreditHistoryScreen} exact></Route>  
              <Route path="/credithistory"  component={LocalPurchaseCreditHistoryScreen} exact></Route>       
            </div>
       
    )
}
