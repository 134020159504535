import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


export default function ClientPaymentHistory(props) {
    const { payment } = props;
    const { domainDetails } = useSelector(state => state.domainDetails);
    const { allusers } = useSelector(state => state.user);
    const [supname, setSupname] = React.useState("icsms");
    
    useEffect(() => {
        let sup = allusers.filter((item) => item.profile_id === payment.supid);
        if(sup.length){
            setSupname(sup[0].username);
        }
    }, [allusers]);
   
    return (
       
        <div className="payment_wrap_client">
            {domainDetails.domainprofile_id === 6 &&
            <div>
                <span>{supname}'s- Client</span>
            </div>
            }
            <div>
                Username: <span>{payment.username}</span>
            </div>
            <div >
                Email: <span>{payment.email}</span>
            </div>
            <div>
                Mobile number: <span>{payment.telnumber}</span>
            </div>
            <div>
                Mode of payment/assisted by: <span>{payment.paymentassist}</span>
            </div>
            <div className="payment-amount">
                Amount: <span>{payment.amount}</span>
            </div>
            <div className="payment-currency">
                Currency: <span>{payment.currency}</span>
            </div>
            <div className="payment-currency">
                Time of payment: <span>{new Date(payment.date).toLocaleString("en-US", { timeZone: "Africa/Accra" })}</span>
            </div>
            <div className="payment-before">
                Balance before: <span>{payment.previousbalance}</span>
            </div>
                <div className="payment-currency">
                Credit added: <span>{payment.balanceadded}</span>
            </div>
                <div className="payment-currency">
                Balance after: <span>{payment.newbalance}</span>
            </div>
        </div>
    )
}
