import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Alerts from '../../components/mainapp/Alerts';
import { DELETE_GROUP_ERROR } from '../../constants/groupsConstants';
import { createcontact, createcontactexel, groupsrequest } from '../../actions/groupsAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingBox from '../../components/LoadingBox';
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from 'uuid';
import SearchField from "react-search-field";
import ContactCard from '../../components/ContactCard';



export default function PhoneBookDetailsScreen(props) {
  const [errorArray, setErrorArray] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState('');
  //const [group, setGroup] = useState([]);
  const [showEdit, setShowEdit] = useState(true);
  const mountedRef = useRef(true)

  const { groups, error, loading } = useSelector(state => state.groups);
  const group = groups.filter(x => x.id === Number(props.match.params.id));

  if (group.length > 0) {
    group[0].contacts.sort((a, b) => b.id - a.id);

    for (var i = 0; i < group[0].contacts.length; i++) {
      group[0].contacts[i].birthday = group[0].contacts[i].birthday ? group[0].contacts[i].birthday.split(" ")[0] : "";
    }
  } 

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      let id = uuidv4()
      error.id = id;
      setErrorArray((prev) => {
        return [...prev, error];
      });

      setTimeout(() => {
        if (!mountedRef?.current) return null
        setErrorArray(prev => {
          return prev.filter(x => x.id !== id);
        });

      }, 5000)

    }
  }, [error]);

  useEffect(() => {
    if(group){
    dispatch(groupsrequest());
    }
  }, []);

  useEffect(() => {
    setErrorArray([]);
    return () => {

      dispatch({ type: DELETE_GROUP_ERROR });
      mountedRef.current = false;
    };

  }, []);


  useEffect(() => {
    if (group.length) {
      setContacts(group[0].contacts);
    }
    if (search) {
      let newContacts = group[0].contacts.filter(x => {
        for (const [key, value] of Object.entries(x)) {
          if (key !== "id") {
            if (key !== "referencekey") {
              if (value.toString().toLowerCase().includes(search.toLowerCase())) {
                return true
              }
            }
          }
        };
        return false
      })
      setContacts(newContacts);
    }
  }, [search, groups]);


  const onChangeHandler = (val) => {
    setSearch(val);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
    
          <div className="back">
            <button onClick={goBack}>Back</button>
          </div>
          {loading && <LoadingBox></LoadingBox>}
          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />
          <div className="search">
            <SearchField
              placeholder="Search..."
              onChange={onChangeHandler}
              searchText=""
              classNames="test-class"
            />
          </div>
          {group.length > 0 &&
        <>
          {group.length > 0 &&
            <div className="createpb">
              <ManuallyAddContactsModal groupname={group[0].groupname} />
              <ExcelAddContactsModal groupname={group[0].groupname} />
            </div>
          }
          <div className="phonebook-contacts">
            <div className="phonebook-contacts-headtitle">Contacts of {group[0].groupname} </div>
            <div className="contacts-container">
              {contacts.map(contact => (
                <ContactCard key={contact.id} groupid={group[0].id} contact={contact} showEdit={showEdit} />
              ))}
            </div>
          </div>
        </>}
    </>

  )
}

export const ManuallyAddContactsModal = ({ groupname }) => {
  const [open, setOpen] = useState(false);

  const [firstname, setFirstname] = useState("")
  const [surname, setSurname] = useState("")
  const [phonenumber, setPhonenumber] = useState("")
  const [birthday, setBirthday] = useState(null)
  const [middlename, setMiddlename] = useState("")

  const [email, setEmail] = useState("")
  const [errorArray, setErrorArray] = useState("");
  const mountedRef = useRef(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      mountedRef.current = false;

    };
  }, []);


  const handleSubmit = () => {

    if (email) {

      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        dispatch(createcontact(groupname, firstname, surname, email, phonenumber, birthday, middlename));
      } else {
        let id = uuidv4()
        setErrorArray((prev) => {
          return [...prev, { id: id, message: "Please enter a valid email address", status: "warning" }];
        });

        setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)

        return;
      }
    } else {

      dispatch(createcontact(groupname, firstname, surname, email, phonenumber, birthday, middlename));
    }

    handleClose();
    setFirstname("");
    setSurname("");
    setPhonenumber("");
    setBirthday(null);
    setMiddlename("");
    setEmail("");
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>Add contact manually</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="modal" onClick={(e) => e.stopPropagation()}>

          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />
          <form>
            <h2>Add Contact details</h2>
            <input type="text" autoComplete="off" value={firstname} placeholder="Enter first name (leave empty of no data)" onChange={e => setFirstname(e.target.value)} />
            <input type="text" autoComplete="off" value={middlename} placeholder="Enter middle name (leave empty of no data)" onChange={e => setMiddlename(e.target.value)} />
            <input type="text" autoComplete="off" value={surname} placeholder="Enter surname (leave empty of no data)" onChange={e => setSurname(e.target.value)} />
            <input type="text" autoComplete="off" value={email} placeholder="Enter email address (leave empty of no data)" onChange={e => setEmail(e.target.value)} />
            <input type="text" autoComplete="off" value={phonenumber} placeholder="Enter mobile number (leave empty of no data)" onChange={e => setPhonenumber(e.target.value)} />
            <div style={{ color: '#003366', marginTop: '5px' }}>Enter birth date (leave empty of no data) </div>
            <div className="datetimepicker">
              <DatePicker showIcon selected={birthday} onChange={(val) => setBirthday(val)} />
            </div>
            <Button variant="contained" onClick={handleSubmit}>Add Contact</Button>
          </form>
        </div>
      </Modal>
    </>
  );
}


export const ExcelAddContactsModal = ({ groupname }) => {
  const [open, setOpen] = useState(false);
  const [errorArray, setErrorArray] = useState("");
  const [files, setFiles] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleReadExcel = (files) => {
    if (files.length < 1) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an excel file", status: "warning" }];
      });
      return
    }
    const file = files[0];

    const filetype = ['xlsx', 'xlsm', 'xls', 'xml']

    if (!filetype.includes(file.name.split(".")[1])) {
      let id = uuidv4()
      setErrorArray((prev) => {
        return [...prev, { id: id, message: "Kindly select an excel file", status: "warning" }];
      });
      return
    }

    let reader = new FileReader();

    reader.onload = function (e) {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: 'array' });
      // find the name of your sheet in the workbook first
      let worksheet = workbook.Sheets['Sheet1'];
      if (!worksheet) {
        let id = uuidv4()
        setErrorArray((prev) => {

          return [...prev, { id: id, message: "Kindly change the name of the sheet containing the data you are trying to load to Sheet1", status: "failed" }];
        });

        setTimeout(() => {
          if (!mountedRef?.current) return null
          setErrorArray(prev => {
            return prev.filter(x => x.id !== id);
          });


        }, 5000)
      }
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      dispatch(createcontactexel(groupname, excelData))
    }
    reader.readAsArrayBuffer(file);
    handleClose();
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>Load contacts from Excel</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div id="excel-phone" onClick={(e) => e.stopPropagation()}>
          <Alerts errorArray={errorArray} setErrorArray={setErrorArray} dispatch={dispatch} dispatchtype={[{ type: DELETE_GROUP_ERROR }]} />
          <form>
            <h2>Load contacts from excel</h2>
            <div className="phonebook1"><img src="../assets/images/contacts.jpg" alt="contacts" /><span>Excel sheet should be designed as per above image</span></div>
            <input type="file" onChange={(e) => setFiles(e.target.files)} />
            <Button variant="contained" onClick={(e) => { e.preventDefault(); handleReadExcel(files); }}>Load contact(s)</Button>
          </form>
        </div>
      </Modal>
    </>
  );
}