import { Alert } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { RemoveErrorMessage } from '../RemoveErroMessage';

export default function Alerts({errorArray, setErrorArray, dispatch, dispatchtype}) {
    return (
        <>
            <div className="message-app">
                {errorArray.length > 0 && errorArray.map((error, index) =>
                    <Alert key={uuidv4()} 
                           onClose={() => { RemoveErrorMessage(error, index, setErrorArray, dispatch, dispatchtype)}} variant="filled" severity={error.status === "failed" ? "warning" : error.status === "successful" ? "success" : error.status === "error" ? "error" : "info"}
                           >
                            {error.message}
                    </Alert> 
                )}
            </div>
        </>
    )
}